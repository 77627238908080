import React from 'react';
import HeaderComponent from "../../components/header/header";
import AdminUsersComponent from "../../components/admin/user/adminUsers";
import AdminUserCreateComponent from "../../components/admin/user/adminUserCreate";


class AdminUsersPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    document.title = "Users - SCode Admin";
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30}}>
          <AdminUserCreateComponent/>
        </div>
        <div style={{padding:30, minHeight:900}}>
          <AdminUsersComponent/>
        </div>
      </div>
    )
  }
}
export default AdminUsersPage;

