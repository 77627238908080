import React from 'react';
import {message, Table, Skeleton} from 'antd';
import { VideoCameraOutlined, FireOutlined, SearchOutlined } from '@ant-design/icons';
import Input from "antd/lib/input";
import axios from "axios";

import { QuestionOutlined, CheckOutlined } from '@ant-design/icons';

import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';

import '../../css/problems.css';
import '../../css/loading.css';
import TagSelectorComponent from "./tagSelector";

export function fuzzyMatch(str, key){
  str = str.toLowerCase();
  key = key.toLowerCase();
  if(key === "") return true;
  let index = 0;
  for(let i = 0, arr = key.split(" "); i < arr.length; i++ ){
    index = str.indexOf(arr[i], index);
    if(index < 0) return false;
  }
  return true;
}

function processProblem(problem) {
  let difficultyColor = '#DEEFFF', textColor = '#4593F9', difficultyText = "easy";
  if(problem.difficulty === 1) {difficultyColor = '#FAF0E6'; textColor = '#E19350'; difficultyText = 'medium'}
  if(problem.difficulty === 2) {difficultyColor = '#FEE4E4'; textColor = '#DF4F81'; difficultyText = 'hard'}
  if(problem.finished === 1) problem.status = <p style={{color:'#5CB85C', margin:0}}><CheckOutlined/></p>;
  else if(problem.finished === 0) problem.status = <p style={{color:'#F0AD4E', margin:0}}><QuestionOutlined/></p>;
  problem.difficultyLabel =
    <p className={'difficulty-label-square'} style={{background:difficultyColor, color: textColor}}>
    {difficultyText}
    </p>;
  const tags = [];
  for(let i = 0; i < problem.tags.length; i ++) {
    tags.push(<p className={'tag-label-square'}>{problem.tags[i]}</p>)
  }
  problem.tagsLabel = <div style={{maxWidth:'100%', marginTop:5}}>{tags}</div>
  problem.titleLabel =
    <div className={'title-label'}
         onClick={() => window.open(getFrontendUrl(
           frontendPath.problem + problem.shortTitle,
           "_blank"
         ))}
    >
      {problem.title}{!problem.video ? <></>: <>&nbsp;&nbsp;<VideoCameraOutlined style={{color:'#df4f81'}}/></>}
    </div>;
  problem.titleLabelWithTags = tags.length === 0 ? problem.titleLabel :<>{problem.titleLabel}{problem.tagsLabel}</>
  const frequencyLabel = [];
  for(let i = 0; i < Math.max(1, problem.frequency); i += 2) {
    let opacity = 1;
    if(i + 1 >= Math.max(1, problem.frequency)) opacity = 0.5;
    frequencyLabel.push(<span style={{fontSize: 11, fontWeight:900, color:'red', marginLeft:1, opacity: opacity}}>
      <FireOutlined />
    </span>)
  }
  problem.frequencyLabel = frequencyLabel
  return problem;
}

export let problemsComponent = React.Component;

class ProblemsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      showTags: this.props.showTags && localStorage.getItem("show-tags"),
      selectedTags: [],
      selectedDifficulty: [],
      selectedStatus: [],
      selectedCompanies: [],
      problems: [],
      loading: true,
      showProblemIds: undefined
    };
    problemsComponent = this;
    const current = this;
    axios.get(
      getBackendUrl(backendPath.problem.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({problems: response.data.result, loading: false})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  changeShowTags(visable) {
    this.setState({showTags: this.props.showTags && visable})
  }

  changeSelectedTags(tags) {
    this.setState({selectedTags: tags})
  }

  render() {
    const data = this.state.problems;
    const showTags = this.state.showTags, selectedTags = this.state.selectedTags;
    let showProblemIds = this.state.showProblemIds;
    const processedData = [];
    if((typeof showProblemIds) !== "object") {
      showProblemIds = [];
      for(let i = 0; i < data.length; i ++) {
        showProblemIds.push(data[i].problemId);
      }
    }
    for(let j = 0; j < showProblemIds.length; j ++) {
      for(let i = 0; i < data.length; i ++) {
        if(data[i].problemId !== showProblemIds[j]) continue;
        if(this.state.selectedDifficulty.length > 0 &&
          this.state.selectedDifficulty[0] !== data[i].difficulty.toString()) {
          continue;
        }
        if(this.state.selectedStatus.length > 0 &&
          this.state.selectedStatus[0] !== data[i].finished.toString()) {
          continue;
        }
        if(this.props.onlyCollected && !data[i].collected) continue;
        if(data[i].problemId.toString() !== this.state.searchKey
          && !fuzzyMatch(data[i].title, this.state.searchKey)) continue ;
        if(selectedTags.length > 0 && data[i].tags.filter(function (tag) {
          return selectedTags.includes(tag);
        }).length === 0) {
          continue;
        }
        processedData.push(processProblem(data[i]));
      }
    }

    const columns = [
      {
        title: '',
        dataIndex: 'status',
        width:'5%'
      },
      {
        title: <span className={'ant-table-title'}>#</span>,
        dataIndex: 'problemId',
        width:'5%'
      },
      {
        title: [
          <span className={'ant-table-title'}>Problems</span>,
        ],
        dataIndex: showTags ? 'titleLabelWithTags': 'titleLabel',
        width:'40%',
      },
      {
        title: <span className={'ant-table-title'}>Level</span>,
        dataIndex: 'difficultyLabel',
        width:'10%',
        sorter: (a, b) => a.difficulty - b.difficulty,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: <span className={'ant-table-title'}>Frequency</span>,
        dataIndex: 'frequencyLabel',
        width:'10%',
        sorter: (a, b) => a.frequency - b.frequency,
        sortDirections: ['descend', 'ascend'],
      }
    ];

    if(this.state.loading) {
      return (
        <Skeleton active paragraph={{ rows: 13 }} />
      )
    }

    const current = this;

    const difficultyMenu = <Menu onClick={(e) => {
      const selectedDifficulty = current.state.selectedDifficulty;
      if(selectedDifficulty.includes(e.key)) {
        current.setState({selectedDifficulty: []})
      } else {
        current.setState({selectedDifficulty: [e.key]})
      }
    }}
      selectedKeys={this.state.selectedDifficulty}>
        <Menu.Item key={0}>Easy</Menu.Item>
        <Menu.Item key={1}>Medium</Menu.Item>
        <Menu.Item key={2}>Hard</Menu.Item>
      </Menu>;
    const statusMenu = <Menu onClick={(e) => {
      const selectedStatus = current.state.selectedStatus;
      if(selectedStatus.includes(e.key)) {
        current.setState({selectedStatus: []})
      } else {
        current.setState({selectedStatus: [e.key]})
      }
    }}
      selectedKeys={this.state.selectedStatus}>
        <Menu.Item key={1}>Finished</Menu.Item>
        <Menu.Item key={0}>Attempted</Menu.Item>
        <Menu.Item key={-1}>Todo</Menu.Item>
      </Menu>;

    return (
      <>
        <div id={'problems-list-header'} style={{display:this.props.hiddenHeader ? 'none': 'flex', background:'#FFFFFf'}}>
          <Dropdown trigger={['click']} overlay={difficultyMenu} placement="bottomLeft">
            <div className={'drop-box-button'}>
              <Space>Difficulty <DownOutlined /></Space>
            </div>
          </Dropdown>
          <Dropdown trigger={['click']} placement="bottomLeft" overlay={statusMenu} >
            <div className={'drop-box-button'}>
              <Space>Status <DownOutlined /></Space>
            </div>
          </Dropdown>
          <Dropdown trigger={['click']} placement="bottomLeft" overlay={<TagSelectorComponent/>} >
            <div className={'drop-box-button'}>
              <Space>Tags <DownOutlined /></Space>
            </div>
          </Dropdown>
          <Input onChange={(e) => this.setState({searchKey: e.target.value})}
                 placeholder={"search by problem title or id ..."}
                 style={{width:286}}
                 prefix={<SearchOutlined style={{opacity:0.5, fontWeight:600}}/>}
                 className={'title-selector'}/>
        </div>
        <Table columns={columns} dataSource={processedData} size={'small'}
               rowClassName={(record, index) => {return  index % 2 === 0 ? "ant-table-odd-row" : "ant-table-even-row"}}
               pagination={{
                 size:"small",
                 defaultPageSize: this.props.defaultPageSize ? this.props.defaultPageSize : 50,
                 hideOnSinglePage: true,
                 showSizeChanger: true,
                 pageSizeOptions:[10, 30, 50, 100],
                 onChange: (e) => document.querySelector('#problems-list-header').scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                    inline: "nearest"
                                  })
               }}
        />
      </>

    )
  }
}

export default ProblemsComponent;

