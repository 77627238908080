import React from 'react';
import HeaderComponent from "../../components/header/header";
import {headerHeight} from "../../config";
import TeacherCheckQuizComponent from "../../components/teacher/check/quizCheck";


class TeacherQuizCheckPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    document.title = "Exam Check - SCode Teacher";
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30, height:document.body.offsetHeight - headerHeight}}>
          <TeacherCheckQuizComponent quizId={this.props.match.params.quiz_id}/>
        </div>
      </div>
    )
  }
}
export default TeacherQuizCheckPage;
