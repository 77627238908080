import React from 'react';
import "../../css/ladders.css"
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";
import {Col, message, Row} from "antd";
import { Pagination } from 'antd';
import { LockOutlined } from '@ant-design/icons';


class LaddersComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ladders: null,
      page: 1,
      pageSize: 12
    };
    const current = this;
    axios.get(getBackendUrl(backendPath.ladder.list.path))
        .then(function (response) {
        if(response.data.status === 1) {
          current.setState({ladders: response.data.result});
        } else {
          message.warning(response.data.message)
        }
      }).catch(function (error) {
        console.log(error);
      });
    this.changePage = this.changePage.bind(this);
  }
  changePage(page, pageSize) {
    this.setState({page: page, pageSize: pageSize})
  }
  render() {
    let ladders = this.state.ladders, page = this.state.page, pageSize = this.state.pageSize;
    if(ladders === null) {
      return (
        <div className={'loading'} style={{marginBottom:"10em"}}></div>
      )
    }
    let laddersShow = [];
    for(let i = 0; i < pageSize; i ++) {
      if((page - 1) * pageSize + i >= ladders.length) break;
      const ladder = ladders[(page - 1) * pageSize + i];
      const hasPermission = ladder.requiredPermissions.length <= 0;
      const permissionsRequired = "Practice after acquiring permission(s) [" + ladder.requiredPermissions.join("、") + "] ."
      laddersShow.push(
        <Col className="gutter-row" span={6}>
          <div className={"ladder-item-container"}
               onClick={(e) => {
                 if(hasPermission) window.open(getFrontendUrl(frontendPath.ladder + ladder.ladderId), "_self");
                 else message.warn(permissionsRequired);
               }}
          >
            <img width={80} height={80} style={{borderRadius:15}} src={ladder.coverUrl}  alt={ladder.title} />
            <div style={{paddingLeft:10, position:'relative'}}>
              <p className={'ladder-item-title'}>{ladder.title}</p>
              <p className={'ladder-item-problems'}>problems {ladder.problems}</p>
              {
                hasPermission ? <></>: <LockOutlined
                  style={{fontSize:20, opacity:0.6, fontWeight:900, position:'absolute', bottom:-10, right:-10}}/>
              }
            </div>

          </div>
        </Col>
      )
    }
    return(
      <>
        <Row gutter={[0, 30]}>
          {laddersShow}
        </Row>
        <div style={{textAlign:'right', position:'absolute', width:1120, bottom:20, right:40}}>
          <Pagination
          size={'small'}
          current={this.state.page}
          onChange={this.changePage}
          total={ladders.length}
          pageSizeOptions={[12, 24, 36]}
          hideOnSinglePage={false}
          showSizeChanger={true}
          defaultPageSize={12}
        />
        </div>
      </>
    )
  }
}
export default LaddersComponent;
