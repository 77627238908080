import React from 'react';

import {Input, Button, message} from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import {getJwtPayload} from "../../../config/cookie";

class ResetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logging: false,
      pwd: "",
      newPwd1: "",
      newPwd2: ""
    }
  }
  handleReset() {
    if(this.state.logging) {return ;}
    const old = this.state.pwd, new1 = this.state.newPwd1, new2 = this.state.newPwd2;
    if(new1 === old) {
      message.warning('新密码与旧密码一致');
      return ;
    }
    if(new1 !== new2) {
      message.warning('两次输入密码不一致');
      return ;
    }
    this.setState({logging: true});
    let xmlhttp=new XMLHttpRequest(), fd = new FormData(), cur = this;
    fd.append("email", getJwtPayload('email'));
    fd.append("code", old);
    fd.append("password", new1);
    xmlhttp.open("PUT", "https://learn.shanganonline.com/api/pwd-reset/1/", true);
    xmlhttp.withCredentials = true;
    xmlhttp.send(fd);

    xmlhttp.onreadystatechange = function(){
      if(xmlhttp.readyState === 4) {
        if(xmlhttp.status === 200 ) {
          const response = JSON.parse(xmlhttp.responseText);
          message.success(response['msg']);
        } else {
          message.warning('密码修改失败');
        }
        cur.setState({logging: false});
      }
    };

  }
  render() {
    const width = this.props.width, height = this.props.height;
    return(
      <div style={{
        width:width, height:height, borderRadius:8, padding:"60px 26px 0px 26px", background:'white',
        textAlign:'center', margin:"0 auto"
      }}>

        <Input.Password placeholder="Current Password" style={{marginBottom:20, height:40}}
              iconRender={visible => (visible ? <span/> : <span/>)}
              onChange={(e) => this.setState({pwd: e.target.value})}
        />
        <Input.Password placeholder="New Password" style={{marginBottom:20, height:40}}
              iconRender={visible => (visible ? <span/> : <span/>)}
              onChange={(e) => this.setState({newPwd1: e.target.value})}
        />
        <Input.Password placeholder="Confirm New Password" style={{marginBottom:20, height:40}}
              iconRender={visible => (visible ? <span/> : <span/>)}
              onChange={(e) => this.setState({newPwd2: e.target.value})}
        />
        <Button type="primary"
                style={{width:"100%", background:'#5168E7', borderColor:'#5168E7', color:'white',
                  borderRadius:4, fontFamily:'PingFangSC-Medium', height: 40}}
                onClick={() => this.handleReset()} >
          {!this.state.logging ? <span>Reset</span> : <LoadingOutlined />}
        </Button>
        <div style={{marginTop:40, fontSize:13, fontFamily:'PingFangSC-Medium', color:'#B6B6B6', lineHeight:'18px'}}>
          This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
        </div>
      </div>
    )
  }
}
export default ResetPasswordComponent;

