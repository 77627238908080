import React from 'react';
import HeaderComponent from "../../components/header/header";
import AdminProblemsComponent from "../../components/admin/problem/adminProblems";
import AdminProblemCreateComponent from "../../components/admin/problem/adminProblemCreate";


class AdminProblemsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      problemIdCreate: {general: 1, special: 10001}
    }
  }
  render() {
    document.title = "Problems - SCode Admin";
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30, height:320}}>
          <AdminProblemCreateComponent parent={this}/>
        </div>
        <div style={{padding:30, minHeight:900}}>
          <AdminProblemsComponent parent={this}/>
        </div>
      </div>
    )
  }
}
export default AdminProblemsPage;
