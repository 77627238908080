import React from 'react';
import HeaderComponent from "../../components/header/header";
import {headerHeight} from "../../config";
import TeacherScoreQuizComponent from "../../components/teacher/quiz/quizScore";


class TeacherQuizScorePage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    document.title = "Exam " + this.props.match.params.pwd + " - SCode Teacher";
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30, height:document.body.offsetHeight - headerHeight}}>
          <TeacherScoreQuizComponent pwd={this.props.match.params.pwd}/>
        </div>
      </div>
    )
  }
}
export default TeacherQuizScorePage;
