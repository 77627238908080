import React from 'react';
import {message, Table, Input} from 'antd';
import axios from "axios";
import { HeartOutlined } from '@ant-design/icons';

import {backendPath, getBackendUrl} from "../../../config/url";

class TeacherUsersComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users: null,
      searchKey: ""
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.teacher.follow.listAllUsers.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({users: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleFollow(user) {
    const current = this;
    axios.post(
      getBackendUrl(backendPath.teacher.follow.follow.path),
      {'id': user.id}
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success((user.follow ? "unfollow" : "follow") + " successfully...");
        user.follow = !user.follow;
        current.setState({users: current.state.users});
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  render() {
    const users = this.state.users, data = [];
    if(users === null) {
      return(
        <div className={'loading'} style={{marginBottom:"10em"}}></div>
      )
    }
    for(let i = 0; i < users.length; i ++) {
      let user = JSON.parse(JSON.stringify(users[i]));
      user.followLabel = <span style={{color:user.follow ? "red": "black"}}>
          <HeartOutlined onClick={() => this.handleFollow(users[i])}/>
        </span>;
      if(!user.email.toString().startsWith(this.state.searchKey)) continue;
      data.push(user);
    }

    const columns = [
      {title: "follow", dataIndex: 'followLabel', width:'20%',
        sorter: (a, b) => a.follow === b.follow ?  0 : (a.follow ? -1 : 1)},
      {title: [
          <span>email</span>,
          <Input onChange={(e) => this.setState({searchKey: e.target.value})}
                 placeholder={"search by email ..."} style={{height: 30, width: '80%'}}
                 className={'title-selector'}/>
        ], dataIndex: 'email', width:'40%'},
      {title: 'nick name', dataIndex: 'nickName', width:'40%',},
    ];

    return (
     <Table columns={columns} dataSource={data} size={'small'} pagination={{
               size:"small",
               defaultPageSize: 20,
               hideOnSinglePage: true,
               showSizeChanger: true,
               pageSizeOptions:[20, 50, 100],
             }}/>
    )
  }
}

export default TeacherUsersComponent;

