import React from 'react';

import {Input, Button, message} from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import {getJwtPayload} from "../../../config/cookie";

class ResetInfoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logging: false,
      nickname: "",
      school: "",
      company: ""
    }
    const xmlhttp=new XMLHttpRequest(), current = this;
    xmlhttp.open("GET", "https://learn.shanganonline.com/api/user/1/", true);
    xmlhttp.withCredentials = true;
    xmlhttp.send();

    xmlhttp.onreadystatechange = function(){
      if(xmlhttp.readyState === 4) {
        if(xmlhttp.status === 200 ) {
          const response = JSON.parse(xmlhttp.responseText);
          if(response['avatar'] != null) {
            current.setState({
              nickname: response['nick_name'],
              school: response['school'],
              company: response['company']});
          }
        }
      }
    };
  }
  handleReset() {
    if(this.state.logging) {return ;}
    this.setState({logging: true});
    let xmlhttp=new XMLHttpRequest(), current = this;
    let fd = new FormData();
    fd.append("nick_name", this.state.nickname);
    fd.append("school", this.state.school);
    fd.append("company", this.state.company);
    xmlhttp.open("PUT", "https://learn.shanganonline.com/api/user/1/", true);
    xmlhttp.withCredentials = true;
    xmlhttp.send(fd);
    xmlhttp.onreadystatechange = function(){
      if(xmlhttp.readyState === 4) {
        current.setState({logging: false});
        if(xmlhttp.status === 200 ) {
          message.success("信息更新完成")
        } else {
          message.warning("信息更新失败")
        }
      }
    };

  }
  render() {
    const width = this.props.width, height = this.props.height;
    return(
      <div style={{
        width:width, height:height, borderRadius:8, padding:"60px 26px 0px 26px", background:'white',
        textAlign:'center', margin:"0 auto"
      }}>
        <div style={{display:'flex', width:'100%'}}>
          <p style={{width:"30%", color:'#282828', fontWeight:400, fontFamily:'PingFangSC-Regular', paddingTop:7}}>
            Nickname:
          </p>
          <Input placeholder="nickname" style={{marginBottom:20, height:40}}
                value={this.state.nickname}
                onChange={(e) => this.setState({nickname: e.target.value})}
          />
        </div>
        <div style={{display:'flex', width:'100%'}}>
          <p style={{width:"30%", color:'#282828', fontWeight:400, fontFamily:'PingFangSC-Regular', paddingTop:7}}>
            School:
          </p>
          <Input placeholder="school" style={{marginBottom:20, height:40}}
                value={this.state.school}
                onChange={(e) => this.setState({school: e.target.value})}
          />
        </div>
        <div style={{display:'flex', width:'100%'}}>
          <p style={{width:"30%", color:'#282828', fontWeight:400, fontFamily:'PingFangSC-Regular', paddingTop:7}}>
            Company:
          </p>
          <Input placeholder="company" style={{marginBottom:40, height:40}}
                value={this.state.company}
                onChange={(e) => this.setState({company: e.target.value})}
          />
        </div>
        <Button type="primary"
                style={{width:"100%", background:'#5168E7', borderColor:'#5168E7', color:'white',
                  borderRadius:4, fontFamily:'PingFangSC-Medium', height: 40}}
                onClick={() => this.handleReset()} >
          {!this.state.logging ? <span>Confirm</span> : <LoadingOutlined />}
        </Button>
      </div>
    )
  }
}
export default ResetInfoComponent;

