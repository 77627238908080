import React from 'react';

function calculate(currentTime, endTime) {
  let remain = Math.max(new Date(endTime).getTime() - currentTime, 0);
  return{
    d: Math.floor(remain/(1000*60*60*24)),
    h: Math.floor(remain/(1000*60*60)%24),
    m: Math.floor(remain/(1000*60)%60),
    s: Math.floor(remain/1000%60),
    currentTime: currentTime
  }
}

class CountDownComponent extends React.Component {

  constructor(props) {
    super(props);
    let currentTime = this.props.startTime ? this.props.startTime : new Date().getTime();
    let delta = currentTime - new Date().getTime();
    if(Math.abs(delta) < 10000) {
      delta = 0;
    }
    this.state = calculate(new Date().getTime() + delta, this.props.endTime);
    const current = this;
    window.setInterval(function () {
        const currentTime = current.state.currentTime;
        current.setState(calculate(new Date().getTime() + delta, current.props.endTime))
    }, 1000)
  }

  render() {
    const d = this.state.d, h = this.state.h, m = this.state.m, s = this.state.s;
    const reload = this.props.reload;
    let countdown = "";
    if(d > 0) countdown += d.toString() + " d ";
    if(h > 0 || countdown) countdown += h.toString() + " h ";
    if(m > 0 || countdown) countdown += m.toString() + " m ";
    if(s > 0 || countdown) countdown += s.toString() + " s";
    if(!countdown && reload) {
      window.location.reload();
    }
    return (
     <>{countdown}</>
    )
  }
}

export default CountDownComponent;
