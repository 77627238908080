import React from 'react';
import HeaderComponent from "../../components/header/header";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../config/url";
import {message} from "antd";
import 'react-markdown-editor-lite/lib/index.css';
import AdminProblemDescriptionComponent from "../../components/admin/problem/adminProblemDescription";
import AdminLanguageEditComponent from "../../components/admin/problem/adminLanguageEdit";
import AdminTestCaseEditComponent from "../../components/admin/problem/adminTestCaseEdit";
import AdminResourcePermissionComponent from "../../components/admin/permission/adminResoursePermission";

class AdminProblemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      problem: {
        id: "",
        shortTitle: this.props.match.params.problem_short_title,
        desc: "",
        problemId: 1,
        difficulty: 0,
        isDeleted: "0",
        title: "",
        video: "",
        tags: []
      }
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.problem.retrieve.path + this.props.match.params.problem_short_title)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({problem: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleChange(key, value) {
    let problem = this.state.problem;
    problem[key] = value;
    this.setState({problem: problem});
  }

  render() {
    if(this.state.problem.title) {
      document.title = this.state.problem.title + " - SCode Admin";
    }

    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:"25px 80px 25px 80px", minHeight:600}}>
          <AdminProblemDescriptionComponent father={this}/>
        </div>
        {
          this.state.problem.id ?
            <div style={{padding:"25px 80px 25px 80px"}}>
              <AdminResourcePermissionComponent id={this.state.problem.id}/>
            </div>
            :<></>
        }
        <div style={{padding:"25px 80px 25px 80px", minHeight:900}}>
          <AdminLanguageEditComponent shortTitle={this.state.problem.shortTitle}/>
        </div>
        <div style={{padding:"25px 80px 25px 80px", minHeight:600}}>
          <AdminTestCaseEditComponent shortTitle={this.state.problem.shortTitle}/>
        </div>
      </div>
    )
  }
}


export default AdminProblemPage;
