import React from 'react';
import { Menu, Button } from 'antd';
import { AppstoreOutlined, FileTextOutlined, UserOutlined, TeamOutlined, DeploymentUnitOutlined, SnippetsOutlined } from '@ant-design/icons';
import '../css/header.css';
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../config/url";
import {
  clearCookie,
  getJwtPayload,
  isLoggedIn,
  setCookie,
  getCookie,
  getUserAttribute,
  setUserAttribute
} from "../../config/cookie";
import {checkRoles} from "../../config/roles";
import {Role} from "../../config/roles";
import axios from "axios";
import message from 'antd/lib/message'
import 'antd/lib/message/style'
import avatar from "../img/avatar.jpg"

const { SubMenu } = Menu;


class HeaderComponent extends React.Component {

  constructor(props){
    super(props);
    const initRole = getUserAttribute('role');
    this.state = {
      userInfo : {
        email: "Default Profile",
        role: initRole ? Number(initRole) : Role.user
      },
      avatar: avatar,
    };
    const current = this;
    if(isLoggedIn()) {
      const xmlhttp=new XMLHttpRequest();
      xmlhttp.open("GET", "https://learn.shanganonline.com/api/user/1/", true);
      xmlhttp.withCredentials = true;
      xmlhttp.send();

      xmlhttp.onreadystatechange = function(){
        if(xmlhttp.readyState === 4) {
          if(xmlhttp.status === 200 ) {
            const response = JSON.parse(xmlhttp.responseText);
            if(response['avatar'] != null) {
              current.setState({avatar: "https://shangan-user.oss-cn-beijing.aliyuncs.com/" + response['avatar']});
            }
          }
        }
      };
      axios.get(
        getBackendUrl(backendPath.user.info.path)
      ).then(
        function (response) {
          if(response.data.status === 2) {
            if(getFrontendUrl(frontendPath.ban) !== window.location.href) {
              window.open(getFrontendUrl(frontendPath.ban), "_self");
            }
          } else if(response.data.status === 1) {
            current.setState({userInfo: response.data.result});
            setUserAttribute('role', response.data.result.role);
          }
        }
      ).catch(
        (error) => console.log(error)
      );
    } else {
      if(this.props.anonymousAllowed) {

      } else {
        message.warning("Please sign in to your OJ account.");
        window.open(getFrontendUrl(frontendPath.login) + "?next=" + window.location.href, "_self");
      }

    }
  }

  handleClick = e => {
    if(e.key === 'problems') window.open(getFrontendUrl(frontendPath.problems), "_self");
    if(e.key === 'ladders') window.open(getFrontendUrl(frontendPath.ladders), "_self");
    if(e.key === 'quizzes') window.open(getFrontendUrl(frontendPath.quizzes), "_self");
    if(e.key === 'admin-problems') window.open(getFrontendUrl(frontendPath.admin.problems), "_self");
    if(e.key === 'admin-users') window.open(getFrontendUrl(frontendPath.admin.users), "_self");
    if(e.key === 'admin-ladders') window.open(getFrontendUrl(frontendPath.admin.ladders), "_self");
    if(e.key === 'admin-permission') window.open(getFrontendUrl(frontendPath.admin.permission), "_self");
    if(e.key === 'teacher-check') window.open(getFrontendUrl(frontendPath.teacher.check), "_self");
    if(e.key === 'teacher-students') window.open(getFrontendUrl(frontendPath.teacher.students), "_self");
    if(e.key === 'teacher-quizzes-config') window.open(getFrontendUrl(frontendPath.teacher.quizzesConfig), "_self");
    if(e.key === 'submissions') window.open(getFrontendUrl(frontendPath.submissions), "_self");
    if(e.key === 'account') window.open(getFrontendUrl(frontendPath.account), "_self");
    if(e.key === 'log') {
      clearCookie();
      window.open(getFrontendUrl(frontendPath.login), "_self");
    }
  };

  handleLog() {
    window.open(getFrontendUrl(frontendPath.login), "_self");
  }

  render() {
    const info = this.state.userInfo;
    return (
      <div id={"header-menu"}>
          <Menu onClick={this.handleClick} mode="horizontal" style={{minWidth:1200, padding:"0 120px"}}>
            <li onClick={() => window.open(getFrontendUrl(frontendPath.home), "_self")}
                style={{display: 'inline-block', marginRight:30, height:80, paddingTop:20, cursor:'pointer'}}
            >
              <span style={{fontSize:26, lineHeight:'30px', fontFamily:'ArialRoundedMTBold', color:'#282828', fontWeight:800}}>
                SCode
              </span>
              <span style={{color:'#5168E7', fontSize:14, fontWeight:900, fontFamily:'ArialRoundedMTBold'}}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {/*上岸客*/}
              </span>
            </li>
            <Menu.Item key="problems" icon={<AppstoreOutlined />}>
              Problems
            </Menu.Item>
            <Menu.Item key="ladders" icon={<FileTextOutlined />}>
              Algorithm Ladders
            </Menu.Item>
            <Menu.Item key="quizzes" icon={<SnippetsOutlined />}>
              Exams
            </Menu.Item>
            {
               checkRoles(info.role, [Role.admin]) ?
                <SubMenu key={"admin"} icon={<DeploymentUnitOutlined />}
                         title={<span>Administrator</span>}
                >
                  <Menu.Item key="admin-problems">Problems</Menu.Item>
                  <Menu.Item key="admin-users">Users</Menu.Item>
                  <Menu.Item key="admin-ladders">Ladders</Menu.Item>
                  <Menu.Item key="admin-permission">Permission</Menu.Item>
                </SubMenu> :
                <></>
            }
            {
               checkRoles(info.role, [Role.teacher]) ?
                <SubMenu key="teacher" icon={<TeamOutlined />}
                         title={<span>Teachers</span>}
                 >
                  <Menu.Item key="teacher-check">Check</Menu.Item>
                  <Menu.Item key="teacher-students">Students</Menu.Item>
                  {
                    checkRoles(info.role, [Role.teachingDirector]) ?
                      <Menu.Item key="teacher-quizzes-config">Exam Config</Menu.Item>: <></>
                  }
                </SubMenu> :
                <></>
            }
            {
              !isLoggedIn() ?
                <div style={{
                    position:'absolute', right:120, top:18, marginTop:0, fontSize:17, width:180, fontWeight:600,
                    padding: 0, borderRadius:25, border:"0.05vw solid black", textAlign:'center', zIndex:1
                  }}
                  >
                    <span className={'home-user-button'}
                          onClick={() => window.open(getFrontendUrl(frontendPath.login), "_self")}>Sign In</span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span className={'home-user-button'}
                          onClick={() => window.open(getFrontendUrl(frontendPath.register), "_self")}>Sign Up</span>
                </div> :
                <SubMenu key="log" title={
                  <div style={{height:40, background:'#EFF3F9', borderRadius:20, padding:"5px 30px 5px 5px",
                    display:'flex', textAlign:'center', marginTop:-10}}>
                    <img src={this.state.avatar} style={{width:30, height:30, borderRadius:30}} alt=""/>
                    &nbsp;&nbsp;
                    <p style={{color:'#282828', fontSize:13, fontWeight:400, margin:"6px 0"}}>{getJwtPayload('email').split("@")[0]}</p>
                  </div>
                }
                         className={"profile-sub-menu"}
                >
                  <Menu.Item key="account">Account</Menu.Item>
                  {/*<Menu.Item key="submissions">Submissions</Menu.Item>*/}
                  <Menu.Item key="log">Sign Out</Menu.Item>
                </SubMenu>
            }
          </Menu>
      </div>
    );
  }
}



export default HeaderComponent;
