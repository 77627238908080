import React from 'react';
import {message, Skeleton, Menu} from 'antd';
import axios from "axios";
import { UserOutlined } from '@ant-design/icons';
import {backendPath, getBackendUrl} from "../../../config/url";
import "../../css/teacherCheck.css";
import QuizTeacherScoreSubmissionComponent from "./submission";

const { SubMenu } = Menu;


class TeacherScoreQuizComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      participants: null, submissions: [], quiz: null
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.teacher.quiz.listParticipants.path + this.props.pwd)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({participants: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleClick(e) {
    const id = e.key, current = this, pwd = this.props.pwd;
    if(this.state.participants === null) {
      return ;
    }
    if(this.state.submissions === null) return;
    this.setState({submissions: null});
    axios.get(
      getBackendUrl(backendPath.teacher.quiz.listParticipantSubmissionsForScore.path + pwd + "/" + id)
    ).then(function (response) {
        if(response.data.status === 1) {
          let submissions = response.data.result;
          submissions.forEach(function (submission) {
            submission.problemTitle = "Exam Problem " + submission.quizProblemNumber;
          });
          const problems = [];
          submissions.forEach(function (submission) {
            if(problems.includes(submission.quizProblemNumber)) {
              submission.isLastSubmission = false;
            } else {
              submission.isLastSubmission = true;
              problems.push(submission.quizProblemNumber)
            }
          });
          submissions.sort(function (sub1, sub2) {
            if(sub1.isLastSubmission && sub2.isLastSubmission) {
              return sub1.quizProblemNumber - sub2.quizProblemNumber;
            }
            if(sub1.isLastSubmission) return -1;
            if(sub2.isLastSubmission) return 1;
            return sub2.createdTime - sub1.createdTime;
          });
          current.setState({submissions: submissions});
        } else {
          message.warning(response.data.message)
        }
      }).catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const participants = [], submissions = this.state.submissions;
    for(let i = 0; this.state.participants !== null && i < this.state.participants.length; i ++) {
      const participant = this.state.participants[i];
      participants.push(
        <Menu.Item key={participant.id}>
          <p style={{textAlign:'left', paddingLeft:20, margin:0}}>
            {
              participant.nickName === participant.email ?
                participant.nickName : participant.nickName + " " + participant.email
            }
          </p>
        </Menu.Item>
      )
    }
    return (
     <div style={{height:"100%", position:'relative'}}>
       <Menu mode="inline" openKeys={["followers"]} onClick={(e) => this.handleClick(e)} style={{ width: "25%", height: "100%" }}>
          <SubMenu key="followers" icon={<UserOutlined />} title="Participants">
            {
              this.state.participants === null?
              <Skeleton active paragraph={{ rows: 10 }} /> :participants
            }
          </SubMenu>
        </Menu>
       <div className={'submission-container'}>
         {
           submissions !== null ? <QuizTeacherScoreSubmissionComponent submissions={submissions}/>:
             <Skeleton active paragraph={{ rows: 6 }} />
         }
       </div>
     </div>
    )
  }
}

export default TeacherScoreQuizComponent;

