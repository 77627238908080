import React from 'react';
import HeaderComponent from "../components/header/header";
import axios from "axios";
import {backendPath, getBackendUrl} from "../config/url";
import {message} from "antd";
import {headerHeight} from "../config";
import {checkRoles, Role} from "../config/roles";
import {watermark} from "../watermark";
import ProblemConsoleComponent from "../components/user/problem/console";
import IDEComponent from "../components/user/problem/IDE";


class ProblemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      problem: {
        shortTitle: this.props.match.params.problem_short_title,
        desc: "",
        problemId: -1,
        title: "",
        video: "",
        userRole: Role.general
      },
      dragState: 0,
      clientHeight: Math.max(document.documentElement.clientHeight, 600),
      clientWidth: Math.max(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, 1100),
      descWidthRate: 0.45
    };
    const current = this, watermark_settings = {
      watermark_element: document.getElementById('root'),
      watermark_txt:["上 岸 算 法", "shanganonline.com"],
      watermark_cord: [1, 3],
      watermark_id: ["title", "website"],
    };
    axios.get(
      getBackendUrl(backendPath.problem.retrieve.path + this.props.match.params.problem_short_title)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({problem: response.data.result});
        if(checkRoles(response.data.result.userRole, [Role.teacher])) {
          watermark(watermark_settings)
        }
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
    window.onresize = function () {
      current.setState({
        clientHeight: Math.max(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight, 300),
        clientWidth: Math.max(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, 1100)
      });
      if(checkRoles(current.state.problem.userRole, [Role.teacher])) {
        watermark(watermark_settings)
      }
    };
    window.onmousemove = (e) => {
      if(current.state.dragState === 2) {
        let rate = e.clientX / current.state.clientWidth - 0.005;
        let realRate = rate;
        if(rate > 0.6) realRate = 0.6;
        else if(rate < 0.1) realRate = 0.0;
        else if(rate < 0.25) realRate = 0.25;
        current.setState({descWidthRate:realRate})
      }
    };
    window.onmouseup = () => {
      current.setState({dragState:0})
    }
  }
  render() {
    if(this.state.problem.title !== "") {
      document.title = this.state.problem.title + " - SCode";
    }
    const problem = this.state.problem;
    const height = this.state.clientHeight;
    const current = this;
    const descW = this.state.clientWidth * this.state.descWidthRate;
    const dragBarW = 20;
    const ideW = this.state.clientWidth - descW - dragBarW;
    return(
      <div style={{width:'100%', background:'#eee'}} id={'problem-container'}>
        <HeaderComponent anonymousAllowed={true}/>
        <div style={{padding:0, height:height - headerHeight, display:'flex'}}>
          {
            descW <= 50 ?
              <></>:
              <div style={{padding:25, width: descW, background:'#fff', borderRadius:5, overflowY:'scroll'}}>
                <ProblemConsoleComponent problem={problem} height={height - headerHeight}/>
              </div>
          }
          <div style={{width:dragBarW, background:'#eef1f7'}}>
            <div id={'code-ide-drag-bar'}
                 onMouseDown={function () {current.setState({dragState: 2})}}
                 onMouseEnter={function () {
                   if(current.state.dragState !== 2) {
                     current.setState({dragState: 1})
                   }
                 }}
                 onMouseLeave={function () {
                   if(current.state.dragState !== 2) {
                     current.setState({dragState: 0})
                   }
                 }}
                 style={{
                   width: '60%', marginLeft:'20%', height: '100%', cursor:'col-resize',
                   background:this.state.dragState !== 0 ? 'rgb(147, 166, 187, 0.5)': '#eef1f7',
                   color: this.state.dragState !== 0 ? '#F7F7F7': '#000000', fontWeight:800,
                   fontSize: 25, lineHeight: '12px', paddingLeft:'10%', paddingTop: height / 2 - 100
                 }}
            >
              . . . .
            </div>
          </div>
          <div style={{padding:'20px 0 25px 0', width: ideW,
            background:'#eef1f7', borderRadius:5}}>
            <IDEComponent problem={problem} width={ideW} shortTitle={problem.shortTitle} height={height - headerHeight - 45}/>
          </div>
        </div>
      </div>
    )
  }
}
export default ProblemPage;
