import React from 'react';
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";
import {Table, Button} from 'antd';


class AdminLaddersComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ladders: []
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.ladder.list.path)
    ).then(function (response) {
      current.setState({ladders: response.data.result})
    }).catch(
      (error) => console.log(error)
    );
  }

  render() {
    const columns = [
      {title: "#", dataIndex: 'ladderId', width:'10%'},
      {title: "Show", dataIndex: 'show', width:'15%'},
      {title: "Title", dataIndex: 'title', width:'60%'},
      {title: 'Problems', dataIndex: 'problems', width:'15%',}
    ];
    let ladders = this.state.ladders;
    ladders.forEach((o) => {
      o.show = (o.isDeleted === "0" ? "Show": "Hidden");
      o.title = <span style={{cursor: 'pointer', fontWeight: 600}}
                   onClick={() => window.open(getFrontendUrl(frontendPath.admin.ladder) + o.ladderId)}
      >{o.title}</span>
    })
    return(
      <div style={{minHeight: '100%'}}>
        <Table columns={columns} dataSource={this.state.ladders} size={'small'} pagination={{
               size:"small",
               defaultPageSize: 12,
               hideOnSinglePage: true,
               showSizeChanger: true
             }}/>
        <Button style={{marginTop:20, float:'right'}}
                onClick={()=>window.open(getFrontendUrl(frontendPath.admin.ladder + "new"))}>
          ADD adder
        </Button>
      </div>
    )
  }
}
export default AdminLaddersComponent;
