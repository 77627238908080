import React from 'react';
import {message, Table, Tag, Alert } from 'antd';
import axios from "axios";
import { ClockCircleOutlined } from '@ant-design/icons';
import {backendPath, frontendPath, frontendUrlBase, getBackendUrl, getFrontendUrl} from "../../../config/url";
import CountDownComponent from "./countdown";
import QuizSubmissionComponent from "./submission";


class QuizComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      quiz: null
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.quiz.retrieve.path + this.props.quizPwd)
    ).then(function (response) {
      if(response.data.status === 1) {
        const quiz = response.data.result, problems = quiz.problems, submissions = quiz.submissions;
        document.title = "Exam - " + quiz.title;
        submissions.forEach(function (submission) {
          for(let i = 0; i < problems.length; i ++) {
            if(submission.quizProblem === problems[i].quizProblemId) {
              submission.problemTitle = "Exam Problem " + problems[i].number;
              submission.problemNumber = problems[i].number;
            }
          }
        });
        problems.forEach(function (problem) {
          for(let i = 0; i < submissions.length; i ++) {
            if(submissions[i].quizProblem === problem.quizProblemId) {
              problem.lastSubmitAt = submissions[i].createdTime;
              submissions[i].isLastSubmission = true;
              break;
            }
          }
        });
        quiz.submissions.sort(function (sub1, sub2) {
            if(sub1.isLastSubmission && sub2.isLastSubmission) {
              return sub1.problemNumber - sub2.problemNumber;
            }
            if(sub1.isLastSubmission) return -1;
            if(sub2.isLastSubmission) return 1;
            return sub2.createdTime - sub1.createdTime;
          });
        current.setState({quiz: quiz, responseTime: response.data.responseTime})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  render() {
    if(this.state.quiz === null) {
      return(
        <div className={'loading'} style={{marginBottom:"10em"}}></div>
      )
    }
    const quiz = this.state.quiz, problems = quiz.problems, submissions = quiz.submissions;
    document.title = quiz.title + " - SCode";
    const problemTableData = [];
    const problemTableColumns = [
      {title: 'Problem', dataIndex: 'title'},
      {title: 'Last Submission', dataIndex: 'lastSubmitAt'}
    ];
    let currentTime = this.state.responseTime ? this.state.responseTime : new Date().getTime();
    for(let i = 0; i < problems.length; i ++) {
      let problem = JSON.parse(JSON.stringify(problems[i]));
      problem.title = <a target={'_blank'} href={getFrontendUrl(frontendPath.quizProblem + problem.quizProblemId)}>Exam Problem {problem.number}</a>;
      if(quiz.startTime > new Date(currentTime)) {
        problem.title = <span>Exam Problem {problem.number}</span>
      }
      if(problem.lastSubmitAt) {
        const date = new Date(problem.lastSubmitAt);
        let time = date.toLocaleTimeString() + " ";
        if(date.toLocaleDateString() !== new Date().toLocaleDateString()) {
          time += date.toLocaleDateString();
        }
        problem.lastSubmitAt = time;
      } else {
        problem.lastSubmitAt = "None";
      }
      problemTableData.push(problem);
    }
    return (
     <>
       <h1>{quiz.title}</h1>
       {
         currentTime < new Date(quiz.endTime) ?
           <p>
             Starts at {new Date(quiz.startTime).toString()} and lasts {Math.floor((quiz.endTime - quiz.startTime) / 60000)} minute(s).
           </p>: <></>
       }
       {
         (currentTime < new Date(quiz.startTime)) ?
           <Alert message={<>
             <ClockCircleOutlined/> The exam will start in <CountDownComponent endTime={quiz.startTime} reload={true}/>.
           </>}
                  type="warning" style={{padding: 20, fontSize: 20}}/>:
           (currentTime > new Date(quiz.endTime) ?
               <Alert message="The exam has ended." type="error" style={{padding: 20, fontSize: 20}}/> :
               <Alert message={<>
                 <ClockCircleOutlined/> The exam has started. ( <CountDownComponent startTime={currentTime} endTime={quiz.endTime} reload={true}/> left )
               </>}
                      type="success" style={{padding: 20, fontSize: 20}}/>)
       }
       <h2 style={{marginTop: 50}}>Problem&nbsp;&nbsp;List</h2>
       <Table columns={problemTableColumns} dataSource={problemTableData}
              size={'small'} pagination={false}/>
       <h2 style={{marginTop: 50}}>Submission&nbsp;&nbsp;List</h2>
       <QuizSubmissionComponent submissions={submissions}/>
     </>
    )
  }
}

export default QuizComponent;
