import React from 'react';
import HeaderComponent from "../components/header/header";
import {headerHeight} from "../config";
import LaddersComponent from "../components/user/ladder/ladders";


class LaddersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientHeight: Math.max(document.documentElement.clientHeight, 600)
    };
    const current = this;
    window.onresize = function () {
      current.setState({clientHeight: Math.max(document.documentElement.clientHeight, 600)})
    }
  }
  render() {
    document.title = "Algorithm Ladders - SCode";
    const height = this.state.clientHeight;
    return(
      <div style={{width:'100%'}}>
        <HeaderComponent/>
        <div style={{position: 'relative', width: 1130, background:'white', margin: '30px auto 0',
            padding:'40px 10px 65px 40px', minHeight:height - headerHeight - 60}}>
          <div style={{width:1080}}>
            <LaddersComponent/>
          </div>
        </div>
        <div style={{height:30}}></div>
      </div>
    )
  }
}
export default LaddersPage;
