import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import { LoadingOutlined } from '@ant-design/icons';
import {message, Select, Button} from "antd";
import Input from "antd/lib/input";
import '../../css/testCaseAdmin.css'

const {Option} = Select;
const {TextArea} = Input;


class AdminTestCaseEditComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      testCases: [],
      id: null,
      input: "",
      output: "",
      isDeleted: "0",
      loading: true
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.testCase.list.path + current.props.shortTitle)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({testCases: response.data.result, loading: false})
      } else {
        message.warning(response.data.message)
      }
    });
  }

  handleCreateOrUpdate() {
    if(this.state.loading === true) {
      message.warn("正在进行中");
      return ;
    }
    if(this.state.input.length === 0 && this.state.output.length === 0) {
      message.warn("不可输入输出都为空");
      return;
    }
    this.setState({loading: true});
    if(this.state.id === null) {
      this.handleCreate();
    } else {
      this.handleUpdate();
    }
  }

  handleSelect(id) {
    if(this.state.loading) {
      message.warn("正在进行中...");
    }
    if(id === null) {
      this.setState({id: null, input: "", output: "", isDeleted: "0",});
      return ;
    }
    this.setState({loading: true});
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.testCase.retrieve.path) + id
    ).then(function (response) {
      if(response.data.status === 1) {
        const result = response.data.result;
        current.setState({loading: false, id: result.id, input: result.input, output: result.output, isDeleted: "0"})
      } else {
        message.warning(response.data.message)
      }
    })
  }

  handleUpdate() {
    const id = this.state.id;
    const current = this;
    axios.post(
      getBackendUrl(backendPath.admin.testCase.update.path),
      {
        id: id,
        input: current.state.input,
        output: current.state.output,
        isDeleted: current.state.isDeleted
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("保存成功");
        current.setState({loading: false})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  handleCreate() {
    const current = this;
    axios.post(
      getBackendUrl(backendPath.admin.testCase.create.path),
      {
        problemShortTitle: current.props.shortTitle,
        input: current.state.input,
        output: current.state.output
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("创建成功");
        current.setState({loading: false, testCases: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  render() {
    const testCases = this.state.testCases;
    const testCaseSelection = [];
    for(let i = -1; i < testCases.length; i ++) {
      let label = i === -1 ? "创建新样例": "Case " + testCases[i].testCaseId;
      if(i >= 0 && testCases[i].isDeleted === "1") {
        label += " 暂不生效";
      }
      const id = i === -1 ? null: testCases[i].id;
      const className = this.state.id === id ? 'test-case-option-selected' : 'test-case-option';
      testCaseSelection.push(
        <p className={className} onClick={this.handleSelect.bind(this, id)}>
          {label}
        </p>
      )
    }
    return(
      <div style={{padding:"15px 15px 25px 15px", background:'#fff', borderRadius:5}}>
        <h3 style={{marginBottom:30, fontWeight:600, fontSize: 16}}>
          测试样例配置&nbsp;&nbsp;&nbsp;
        </h3>
        <p style={{color:'red', fontSize:15, fontWeight:900}}>
          记住修改条件捕获corner case。比如把正确程序>=改成>，捕获至少五条corner case。
        </p>
        <div style={{height:400}}>
          <div style={{width: "16%", float:'left'}}>
            <div style={{height: 370, background:"rgb(238 238 238)", padding:3, borderRadius:4, overflow:'scroll'}}>
              {testCaseSelection}
            </div>
          </div>
          <div style={{width: "42%", float:'left', padding:5}}>
            <b>样例输入：</b>
            <TextArea rows={15} value={this.state.loading ? "加载中..." : this.state.input}
                      onChange={(e) => this.setState({input: e.target.value})} />
          </div>
          <div style={{width: "42%", float:'left', padding:5}}>
            <b>样例输出：</b>
            <TextArea rows={15} value={this.state.loading ? "加载中..." : this.state.output}
                      onChange={(e) => this.setState({output: e.target.value})} />
          </div>
        </div>
        <div style={{height: 50}}>
          <div style={{float:'right'}}>
            <Button disabled={this.state.loading === true ? "disable": null}
                    onClick={this.handleCreateOrUpdate.bind(this)}>
              {this.state.loading === true ? <LoadingOutlined /> : ""}
              {this.state.id === null ? "创建": "更新"}
            </Button>
          </div>
          <div style={{float:'right', marginRight:20}}>
            <span>是否生效&nbsp;&nbsp;&nbsp;</span>
            <Select value={this.state.isDeleted} style={{width:120}}
                    onChange={(value) => this.setState({isDeleted: value})}>
              <Option key={"0"} value={"0"}>生效</Option>
              <Option key={"1"} value={"1"}>暂不生效</Option>
            </Select>
          </div>

        </div>

      </div>
    )
  }
}
export default AdminTestCaseEditComponent;

