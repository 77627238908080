import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../../config/url";
import {message} from "antd";
import {Skeleton} from 'antd';
import QuizCodeIdeComponent from "./codeIDE";
import QuizProblemDescriptionComponent from "./description";


class QuizProblemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      problem: null,
      dragState: 0,
      clientHeight: Math.max(document.documentElement.clientHeight, 600),
      clientWidth: Math.max(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, 1100),
      descWidthRate: 0.45
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.quiz.retrieveProblem.path + this.props.quizProblemId)
    ).then(function (response) {
      if(response.data.status === 1) {
        let problem = response.data.result;
        problem.hasEnded = problem.endTime <= response.data.responseTime;
        current.setState({problem: problem, responseTime: response.data.responseTime})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
    window.onmousemove = (e) => {
      if(current.state.dragState === 2) {
        let rate = e.clientX / current.state.clientWidth - 0.005;
        let realRate = rate;
        if(rate > 0.6) realRate = 0.6;
        else if(rate < 0.1) realRate = 0.0;
        else if(rate < 0.25) realRate = 0.25;
        current.setState({descWidthRate:realRate})
      }
    };
    window.onmouseup = () => {
      current.setState({dragState:0})
    }
  }
  render() {
    const problem = this.state.problem;
    if(problem) {
      document.title = "Problem " + problem.number.toString() + " of " + problem.quizTitle + " - SCode";
    }
    const height = this.props.height, width = this.props.width;
    const current = this;
    const descW = width * this.state.descWidthRate;
    const dragBarW = 20;
    const ideW = width - descW - dragBarW;
    return(
      <div style={{padding:0, height:height, display:'flex'}}>
          {
            descW <= 50 ?
              <></>:
              <div style={{padding:25, width: descW, background:'#fff', borderRadius:5, overflowY:'scroll'}}>
                {
                  !problem ? <Skeleton active paragraph={{ rows: 6 }} />:
                    <QuizProblemDescriptionComponent problem={problem} currentTime={this.state.responseTime} height={height}/>
                }
              </div>
          }
          <div style={{width:dragBarW, background:'#eef1f7'}}>
            <div id={'code-ide-drag-bar'}
                 onMouseDown={function () {current.setState({dragState: 2})}}
                 onMouseEnter={function () {
                   if(current.state.dragState !== 2) {
                     current.setState({dragState: 1})
                   }
                 }}
                 onMouseLeave={function () {
                   if(current.state.dragState !== 2) {
                     current.setState({dragState: 0})
                   }
                 }}
                 style={{
                   width: '60%', marginLeft:'20%', height: '100%', cursor:'col-resize',
                   background:this.state.dragState !== 0 ? 'rgb(147, 166, 187, 0.5)': '#eef1f7',
                   color: this.state.dragState !== 0 ? '#F7F7F7': '#000000', fontWeight:800,
                   fontSize: 25, lineHeight: '12px', paddingLeft:'10%', paddingTop: height / 2 - 100
                 }}
            >
              . . . .
            </div>
          </div>
          <div style={{padding:'20px 0 25px 0', width: ideW,
            background:'#eef1f7', borderRadius:5}}>
            {
              !problem ? <Skeleton active paragraph={{ rows: 6 }} />:
                <QuizCodeIdeComponent width={ideW} problem={problem} height={height - 45}/>
            }
          </div>
        </div>
    )
  }
}
export default QuizProblemComponent;
