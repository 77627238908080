import React from 'react';

import {Input, Button, message} from 'antd'
import axios from "axios";
import { LoadingOutlined } from '@ant-design/icons';
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../config/url";
import {setCookie} from "../../config/cookie";
import {getQueryVariable} from "../../config/url";

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logging: false,
      email: "",
      password: ""
    }
    const mess = getQueryVariable("message");
    if(mess !== null) {
      message.warning(mess);
    }
  }
  handleLogin() {
    if(this.state.logging) {
      return ;
    }
    this.setState({logging: true});
    const current = this;
    axios.post(
      getBackendUrl(backendPath.user.login.path),
      {
        'email': this.state.email,
        'password': this.state.password
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("log in successfully...");
        const result = JSON.parse(response.data.result);
        setCookie("token", result.token);
        setCookie("email", result.email);
        window.setTimeout(function () {
          window.open(getQueryVariable("next") || getFrontendUrl(frontendPath.problems), "_self")
        }, 1000);
      } else {
        message.warning(response.data.message);
      }
      current.setState({logging: false});
    }).catch(function (error) {
      console.log(error);
    }).finally(() => this.setState({logging: false}))
  }
  render() {
    const width = this.props.width, height = this.props.height;
    console.log(this.state);
    return(
      <div style={{
        width:width, height:height, borderRadius:8, padding:"60px 26px 0px 26px",
        border:'1px solid #eaeaea', boxShadow:'0 0 2px #cac6c6', background:'white',
        textAlign:'center'
      }}>
        <p style={{margin:"0px 0px 33px", color:'#5168E7', fontSize:26, fontFamily:'ArialRoundedMTBold',
          fontWeight:600}}>SCode</p>
        <Input placeholder="Username or E-mail"  style={{marginBottom:20, height:40}}
               onChange={(e) => this.setState({email: e.target.value})}
               onPressEnter={() => this.handleLogin() }
        />
        <Input.Password placeholder="Password" style={{marginBottom:20, height:40}}
              iconRender={visible => (visible ? <span/> : <span/>)}
              onChange={(e) => this.setState({password: e.target.value})}
              onPressEnter={() => this.handleLogin() }
        />
        <Button type="primary"
                style={{width:"100%", background:'#5168E7', borderColor:'#5168E7', color:'white',
                  borderRadius:4, fontFamily:'PingFangSC-Medium', height: 40}}
                onClick={() => this.handleLogin()} >
          {!this.state.logging ? <span>Sign In</span> : <LoadingOutlined />}
        </Button>
        <div style={{fontWeight:500, fontFamily:'PingFangSC-Medium', marginTop:20}}>
          <a style={{color:"#7A7A7A"}} href={getFrontendUrl(frontendPath.forgetPassword)} target={"_self"}>
            Forget Password ?
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a style={{color:"#7A7A7A"}} href={getFrontendUrl(frontendPath.register)} target={"_self"}>
            Sign Up
          </a>
        </div>
        <div style={{marginTop:70, fontSize:13, fontFamily:'PingFangSC-Medium', color:'#B6B6B6', lineHeight:'18px'}}>
          This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
        </div>
      </div>
    )
  }
}
export default LoginComponent;

