import React from 'react';
import HeaderComponent from "../../components/header/header";
import TeacherUsersComponent from "../../components/teacher/students/teacherUsers";
import {headerHeight} from "../../config";


class TeacherUsersPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    document.title = "Follow Students - SCode";
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30, height: document.body.offsetHeight - headerHeight}}>
          <TeacherUsersComponent/>
        </div>
      </div>
    )
  }
}
export default TeacherUsersPage;
