import React from 'react';
import {Input, message, Table} from 'antd';
import axios from "axios";
import {DeletedType} from "../../../config/constant";
import {Role} from "../../../config/roles";
import {backendPath, getBackendUrl} from "../../../config/url";
import {roleLabel} from "../../../config/roles";
import AdminUserUpdateComponent from "./adminUserUpdate";

class AdminUsersComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      updateUser: null,
      searchKey: ""
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.user.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({users: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }


  updateUser(user, isUpdatePermission) {
    const current = this;
    if(!isUpdatePermission) {
      current.setState({updateUser: {info: user, permissions: [], isUpdatePermission: false}});
      return ;
    }
    axios.get(
      getBackendUrl(backendPath.admin.user.retrievePermission.path + user.id)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({updateUser: {info: user, permissions: response.data.result, isUpdatePermission: true}});
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }


  render() {
    const users = this.state.users, data = [], current = this;
    for(let i = 0; i < users.length; i ++) {
      let user = JSON.parse(JSON.stringify(users[i]));
      const date = new Date(user.createdTime);
      let time = date.toLocaleTimeString() + " ";
      if(date.toLocaleDateString() !== new Date().toLocaleDateString()) {
        time += date.toLocaleDateString();
      }
      if(user.isDeleted === DeletedType.true) {
        user.isDeleted = <span style={{color:'#D9534E'}}><b>无效</b></span>;
      } else {
        user.isDeleted = <span style={{color:'#5CB85C'}}><b>有效</b></span>;
      }
      user.isDeleted = <span>
        {user.isDeleted}&nbsp;&nbsp;
        <a onClick={() => this.updateUser(users[i], false)}>
          信息更新
        </a>
        &nbsp;&nbsp;
        <a onClick={() => this.updateUser(users[i], true)}>
          权限管理
        </a>
       </span>;
      user.createdTime = time;
      user.role = roleLabel(user.role);
      if(!user.email.toString().startsWith(this.state.searchKey)) continue;
      data.push(user);
    }

    const roleFilters = [];
    for(let role in Role) {
      roleFilters.push({text: roleLabel(Role[role]), value: roleLabel(Role[role])})
    }

    const columns = [
      {title: "#", dataIndex: 'isDeleted', width:'20%'},
      {title: [
          <span>邮箱</span>,
          <Input onChange={(e) => this.setState({searchKey: e.target.value})}
                 placeholder={"search by email ..."} style={{height: 30, width: '80%'}}
                 className={'title-selector'}/>
        ], dataIndex: 'email', width:'25%'},
      {title: '昵称', dataIndex: 'nickName', width:'15%',},
      {title: '角色', dataIndex: 'role', width:'20%', filters: roleFilters,
        onFilter: (value, record) => record.role.includes(value), filterSearch: true},
      {title: '添加时间', dataIndex: 'createdTime', width:'20%',}
    ];

    return (
      <>
        <Table columns={columns} dataSource={data} size={'small'} pagination={{
               size:"small",
               defaultPageSize: 20,
               hideOnSinglePage: true,
               showSizeChanger: true,
               pageSizeOptions:[20, 50, 100],
             }}/>
        <AdminUserUpdateComponent parent={this}/>
      </>
    )
  }
}

export default AdminUsersComponent;

