import React from 'react';
import {Skeleton, Switch} from 'antd';
import {RightOutlined} from '@ant-design/icons';

import 'react-markdown-editor-lite/lib/index.css';

import '../../../css/description.css';
import CountDownComponent from "../countdown";

// Register plugins if required
// MdEditor.use(YOUR_PLUGINS_HERE);

// Initialize a markdown parser
const mdParser = require('markdown-it')().use(require('markdown-it-sub')).use(require('markdown-it-sup'));

export let quizProblemDescComponent;

class QuizProblemDescriptionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consoleContent: null,
      countDownDisplay: true
    };
    quizProblemDescComponent = this;
  }
  render() {
    const current = this;
    const consoleContent = this.state.consoleContent;
    const problem = this.props.problem;
    const titleHtmlContext = [
      <h3 style={{marginBottom:15}}>{problem.quizTitle}&nbsp;-&nbsp;Problem&nbsp;{problem.number}</h3>
    ];
    const countDownHtmlContext = [
      <b style={{color:'#1890FF'}}>
        {
          problem.endTime > new Date() ? <>
              <Switch defaultChecked onChange={(value) => {current.setState({countDownDisplay: value})}}/>&nbsp;&nbsp;
              <span style={{color:'#1890FF'}}>Count Down</span>&nbsp;&nbsp;
              {
                !this.state.countDownDisplay ? <></>:
                  <><CountDownComponent startTime={this.props.currentTime} endTime={problem.endTime} reload={true}/> left</>
              }
            </>:
            <span>The Exam Has Ended.</span>
        }
      </b>,
    ];
    if(consoleContent !== null) {
      return (
        <>
          {titleHtmlContext}<br/><br/>
          <div id={'console-content-container'} style={{width:'100%', height:this.props.height - 110, marginTop: -45, position: 'relative'}}>
            <div className={'self-link'} onClick={() => this.setState({consoleContent: null})}>
              Back&nbsp;<RightOutlined/>
            </div>
            {countDownHtmlContext}
            <br/><br/>{consoleContent}
          </div>
        </>
      );
    }
    if(!problem) {
      return <Skeleton active paragraph={{ rows: 6 }} />;
    }
    let desc = mdParser.render(problem.desc);
    desc = desc.replace(
      new RegExp("(<code>[\\s\\S]*?)Input([\\s\\S]*?</code>)", "g"),
      "$1<strong>Input</strong>$2"
    );
    desc = desc.replace(
      new RegExp("(<code>[\\s\\S]*?)Output([\\s\\S]*?</code>)", "g"),
      "$1<strong>Output</strong>$2"
    );
    desc = desc.replace(
      new RegExp("(<code>[\\s\\S]*?)Explanation([\\s\\S]*?</code>)", "g"),
      "$1<strong>Explanation</strong>$2"
    );
    // desc = desc.replace(
    //   new RegExp("<pre><code>([\\s\\S]*?Input[\\s\\S]*?Output[\\s\\S]*?)</code></pre>", "g"),
    //   "<pre>$1</pre>"
    // );
    desc = desc.replace(new RegExp("<pre><code>", "g"), "<pre>");
    desc = desc.replace(new RegExp("</code></pre>", "g"), "</pre>");
    return(
      <>
        {titleHtmlContext}
        {countDownHtmlContext}
        <div dangerouslySetInnerHTML={{__html:desc}} className={'custom-html-style'}></div>
      </>
    )
  }
}
export default QuizProblemDescriptionComponent;

