import React from 'react';
import HeaderComponent from "../components/header/header";
import LadderComponent from "../components/user/ladder/ladder";
import {headerHeight} from "../config";


class LadderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientHeight: Math.max(document.documentElement.clientHeight, 600)
    };
    const current = this;
    window.onresize = function () {
      current.setState({clientHeight: Math.max(document.documentElement.clientHeight, 600)})
    }
  }
  render() {
    const height = this.state.clientHeight;
    return(
      <div style={{width:'100%'}}>
        <HeaderComponent/>
        <div style={{width: 1200, margin: '0 auto', padding:'30px 0', height:height - headerHeight}}>
          <LadderComponent ladderId={this.props.match.params.ladder_id}/>
        </div>
      </div>
    )
  }
}
export default LadderPage;
