import React from 'react';
import {RightOutlined, EditOutlined} from '@ant-design/icons';

import 'react-markdown-editor-lite/lib/index.css';

import '../../css/description.css';
import { Editor } from '@tinymce/tinymce-react';

import ReactDOMServer from "react-dom/server";
import {ideComponent} from "./IDE";


class SolutionComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
    const current = this;
    window.setInterval(function () {
      current.setState({})
    }, 100)
  }

  render() {
    let sol = this.props.solution;
    const language = this.props.language === 'python3' ? 'python' : this.props.language;
    if(!sol.startsWith("<") || !sol.endsWith(">")) {
      sol = ReactDOMServer.renderToString(<pre className={"language-"+language} ><code>{sol}</code></pre>)
    }
    sol = sol.replace(
      new RegExp("(<pre[\\s\\S]*?><code>)([\\s\\S]*?)(</code></pre>)", "g"),
      "<div class='solution-code-mount' style='height: auto'>$1$2$3</div>"
    );
    let solution = <Editor
      value={sol}
      inline={true}
      disabled={true}
      init={{
        language: 'zh_CN', width: '100%', height: 'auto', menubar: false,
        plugins: 'codesample',
        toolbar: ''
      }}
      onChange={(c, e) => {e.save()}}
      // onInit={(c, e) => {problemDescComponent.setState({consoleContent: problemDescComponent.state.consoleContent})}}
    />;
    const solutionCodeBlocks = document.getElementsByClassName('solution-code-mount');
    const copyCodeButtons = [];
    for(let i = 0; i < solutionCodeBlocks.length; i ++) {
      let element = solutionCodeBlocks[i], left = 0, top = 0;
      let code = element.textContent;
      while(element.id !== 'console-content-container') {
        left += element.offsetLeft;
        top += element.offsetTop;
        element = element.parentElement;
      }
      copyCodeButtons.push(<div style={{position:'absolute', right: 5, top: top, cursor:'pointer'}}
                                onClick={() => ideComponent.setState({code: code})}>
        <EditOutlined /> Edit
      </div>);
    }
    return (
      <div id={'console-content-container'} style={{width:'100%', height:"85%", position: 'relative'}}>
        {copyCodeButtons}
        {solution}
      </div>
    )
  }
}

export default SolutionComponent;

