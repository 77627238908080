import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {generateCode, languagesConfig} from "../../../config/language";
import { LoadingOutlined } from '@ant-design/icons';
import {message, Select, Button, Tabs} from "antd";
import Input from "antd/lib/input";
import AceEditor from "react-ace";
import ReactDOMServer from 'react-dom/server';

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-sqlserver";
import "ace-builds/src-noconflict/theme-solarized_light";
import { Editor } from '@tinymce/tinymce-react'

const { TabPane } = Tabs;

const {Option} = Select;


class AdminLanguageEditComponent extends React.Component {

  constructor(props) {
    super(props);
    const languages = [];
    for(let i = 0; i < languagesConfig.length; i ++) {
      languages.push(
        {
          id: null,
          language: i,
          timeLimit: 1000,
          spaceLimit: 64,
          initCode: "",
          mainCode: "",
          solution: "",
          isDeleted: "0"
        }
      )
    }
    this.state = {
      languages: languages,
      select: 0,
      loading: false,
      interface: "",
      selectTab: "init"
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.language.list.path + current.props.shortTitle)
    ).then(function (response) {
      if(response.data.status === 1) {
        const existLanguages = response.data.result;
        let languages = current.state.languages;
        for(let i = 0; i < existLanguages.length; i ++) {
          languages[existLanguages[i].language] = existLanguages[i];
        }
        current.setState({languages: languages, loading: false})
      } else {
        message.warning(response.data.message)
      }
    });
  }

  handleCreateOrUpdate() {
    if(this.state.loading === true) {
      message.warn("Processing ...");
      return ;
    }
    this.setState({loading: true});
    const updateConfig = this.state.languages[this.state.select];
    const current = this;
    axios.post(
      getBackendUrl(backendPath.admin.language.createOrUpdate.path),
      {
        shortTitle: this.props.shortTitle,
        language: updateConfig.language,
        timeLimit: updateConfig.timeLimit,
        spaceLimit: updateConfig.spaceLimit,
        initCode: updateConfig.initCode,
        mainCode: updateConfig.mainCode,
        solution: updateConfig.solution,
        isDeleted: updateConfig.isDeleted
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success(languagesConfig[updateConfig.language].label + " config successfully.");
      } else {
        message.warning(response.data.message);
      }
      current.setState({loading: false});
    }).catch(function (error) {
      console.log(error);
    })
  }

  handleChange(key, value) {
    let languages = this.state.languages;
    languages[this.state.select][key] = value;
    this.setState({languages: languages});
  }

  handleGenerateCode() {
    const code = generateCode(this.state.interface, languagesConfig[this.state.select].value);
    let languages = this.state.languages;
    languages[this.state.select].initCode = code.initCode;
    languages[this.state.select].mainCode = code.mainCode;
    this.setState({languages: languages});
  }

  render() {
    const languages = this.state.languages;
    const languageOptions = [];
    for(let i = 0; i < languages.length; i ++) {
      languageOptions.push(
        <Option key={i} value={i}>{languagesConfig[i].label}</Option>
      )
    }
    const selectedLanguage = this.state.languages[this.state.select];
    const language = languagesConfig[this.state.select].value === 'python3' ? 'python' : languagesConfig[this.state.select].value;
    const solutionContent = selectedLanguage.solution.startsWith("<") && selectedLanguage.solution.endsWith(">") ?
                      selectedLanguage.solution :
      ReactDOMServer.renderToString(<pre className={"language-"+language} ><code>{selectedLanguage.solution}</code></pre>)
    return(
      <div style={{padding:"15px 15px 25px 15px", background:'#fff', borderRadius:5}}>
        <h3 style={{marginBottom:15, fontWeight:600, fontSize: 16, display:'inline'}}>
          题目语言配置&nbsp;&nbsp;&nbsp;
        </h3>
        <Select onChange={(value) => this.setState({select: value})} style={{width:100}} value={this.state.select}>
          {languageOptions}
        </Select>
        &nbsp;&nbsp;&nbsp;
        {selectedLanguage.id !== null ?
           <span>已加载该语言的后台配置</span>:
           <span style={{color:'red'}}>{this.state.loading ? "正在加载后台配置" : "后台暂无该语言的配置" }</span>
        }
        <br/><br/><hr/><br/>
        <span>时间限制&nbsp;&nbsp;</span>
        <Input style={{display:'inline', width: 100}} value={selectedLanguage.timeLimit}
               onChange={(e) => this.handleChange("timeLimit", Number(e.target.value))}
        />
        <span>&nbsp;&nbsp;空间限制&nbsp;&nbsp;</span>
        <Input style={{display:'inline', width: 100}} value={selectedLanguage.spaceLimit}
               onChange={(e) => this.handleChange("spaceLimit", Number(e.target.value))}
        />&nbsp;&nbsp;
        <Select style={{width:120}} value={selectedLanguage.isDeleted}
                onChange={(value) => this.handleChange("isDeleted", value)}
        >
          <Option key={"1"} value={"1"}>暂不生效</Option>
          <Option key={"0"} value={"0"}>生效</Option>
        </Select>
        <Button isabled={this.state.loading === true ? "disable": null}
                onClick={this.handleCreateOrUpdate.bind(this)} style={{float:'right'}}>
          {this.state.loading === true ? <LoadingOutlined /> : ""}
          保存
        </Button>
        {
          this.state.selectTab === "init" ?
            <>
              <Button style={{float:'right'}} onClick={this.handleGenerateCode.bind(this)}>
                自动生成
              </Button>
              <Input style={{float:'right', width: '30%'}} placeholder={"配置所有语言都写java格式的接口"}
               onChange={(e) => this.setState({interface: e.target.value})}/>
            </> :
            <></>
        }
        <br/><br/>
        <div style={{height:750}}>
          <div style={{width:'55%', height:720, padding:2, float:'left'}}>
            <Tabs defaultActiveKey="init" onChange={(tab) => this.setState({selectTab: tab})}>
              <TabPane tab="Initial Code" key="init">
                <AceEditor
                  mode={languagesConfig[this.state.select].value === 'python3' ? 'python' : languagesConfig[this.state.select].value}
                  theme={'solarized_light'}
                  width={"100%"}
                  height={650}
                  fontSize={14}
                  showPrintMargin={false}
                  enableBasicAutocompletion={true}
                  value={selectedLanguage.initCode}
                  onChange={(code) => this.handleChange("initCode", code)}
                  name={languagesConfig[this.state.select].value + "init-code-edit"}
                  editorProps={{ $blockScrolling: true }}
                />
              </TabPane>
              <TabPane tab="Standard Solution" key="solution">
                <div style={{width:'100%', height:650, overflow:'scroll'}}>
                  <Editor
                value={solutionContent}
                init={{
                     language: 'zh_CN', width: '100%', menubar: false,
                     plugins: 'preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave autoresize formatpainter',
                     toolbar: 'codesample preview | bold italic underline | fontselect fontsizeselect ' +
                       '| bullist numlist  | alignleft aligncenter',
                     fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px'
                    }}
                onEditorChange={(content, editor) => {this.handleChange("solution", content);editor.save();}}
             />
                </div>
              </TabPane>
            </Tabs>

          </div>
          <div style={{width:'45%', height:720, padding:2, float:'left'}}>
            <Tabs defaultActiveKey="main">
              <TabPane tab="Main Code" key="main">
                <AceEditor
                  mode={languagesConfig[this.state.select].value === 'python3' || 'sql' || 'sqlserver' ? 'python' : this.state.language}
                  theme={'solarized_light'}
                  width={"100%"}
                  height={650}
                  fontSize={14}
                  showPrintMargin={false}
                  enableBasicAutocompletion={true}
                  value={selectedLanguage.mainCode}
                  onChange={(code) => this.handleChange("mainCode", code)}
                  name={languagesConfig[this.state.select].value + "main-code-edit"}
                  editorProps={{ $blockScrolling: true }}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>

      </div>
    )
  }
}
export default AdminLanguageEditComponent;
