import React from 'react';
import {message, Table} from 'antd';
import '../../css/problems.css';
import Input from "antd/lib/input";
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";
import {languagesConfig} from "../../../config/language";
import {problemType} from "../../../config/problem";
import {BooleanType} from "../../../config/constant";

function fuzzyMatch(str, key){
  str = str.toLowerCase();
  key = key.toLowerCase();
  if(key === "") return true;
  let index = 0;
  for(let i = 0, arr = key.split(" "); i < arr.length; i++ ){
    index = str.indexOf(arr[i], index);
    if(index < 0) return false;
  }
  return true;
}


class AdminProblemsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      problems: [],
      allTags: []
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.problem.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        let problems = response.data.result, generalProblemIdCreate = 1, specialProblemIdCreate = 10001;
        for(let i = 0; i < problems.length; i ++) {
          if(problems[i].problemId === generalProblemIdCreate) {
            generalProblemIdCreate ++;
          }
          if(problems[i].problemId === specialProblemIdCreate) {
            specialProblemIdCreate ++;
          }
        }
        current.props.parent.setState({problemIdCreate: {general: generalProblemIdCreate, special: specialProblemIdCreate}});
        current.setState({problems: problems.reverse()});
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
    axios.get(
      getBackendUrl(backendPath.admin.tag.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({allTags: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    });
  }


  render() {
    const data = this.state.problems;
    const processedData = [];
    for(let i = 0; i < data.length; i ++) {
      if(!fuzzyMatch(data[i].title, this.state.searchKey)) continue ;
      let problem = data[i];
      let difficultyColor = '#5CB85C', difficultyText = "easy";
      if(problem.difficulty === 1) {difficultyColor = '#F0AD4E'; difficultyText = 'medium'}
      if(problem.difficulty === 2) {difficultyColor = '#D9534E'; difficultyText = 'hard'}
      problem.difficultyLabel =
        <p className={'difficulty-label-square'} style={{background:difficultyColor}}>
        {difficultyText}
        </p>;
      problem.tagsLabel = [];
      for(let i = 0; i < problem.tags.length; i ++) {
        problem.tagsLabel.push(<p className={'tag-label-square'}>{problem.tags[i]}</p>)
      }
      problem.languagesLabel = [];
      problem.languages.sort();
      for(let i = 0; i < problem.languages.length; i ++) {
        problem.languagesLabel.push(<p className={'tag-label-square'}>{languagesConfig[problem.languages[i]].label}</p>)
      }
      problem.show = <p className={'difficulty-label-square'} style={{background:'#5CB85C'}}>YES</p>;
      if(problem.isDeleted === "1") {
        problem.show = <p className={'difficulty-label-square'} style={{background:'#D9534E'}}>NO</p>;
      }
      problem.titleLabel =
        <div className={'title-label'}
             onClick={() => window.open(getFrontendUrl(
               frontendPath.admin.problem + problem.shortTitle,
               "_blank"
             ))}
        >
          {problem.title}
        </div>;
      problem.typeLabel = problemType[problem.problemType].label;
      if(problem.isQuizProblem === BooleanType.true) {
        problem.isQuizProblemLabel = <p className={'difficulty-label-square'} style={{background:'#5CB85C'}}>YES</p>;
      }
      const date = new Date(problem.createdTime);
      let time = date.toLocaleTimeString() + " ";
      if(date.toLocaleDateString() !== new Date().toLocaleDateString()) {
        time += date.toLocaleDateString();
      }
      problem.createdTimeLable = time;
      processedData.push(problem);
    }

    const tagFilters = [];
    for(let i = 0; i < this.state.allTags.length; i ++) {
      tagFilters.push({
        "text": this.state.allTags[i],
        "value": this.state.allTags[i]
      })
    }

    const columns = [
      {
        title: '#',
        dataIndex: 'problemId',
        width:'5%',
        sorter: (a, b) => a.problemId - b.problemId,
      },
      {
        title: 'show',
        dataIndex: 'show',
        width:'5%',
        sorter: (a, b) => a.isDeleted < b.isDeleted ? -1 : 1,
      },
      {
        title: [
          <span>title</span>,
          <Input onChange={(e) => this.setState({searchKey: e.target.value})}
                 placeholder={"search ..."}
                 style={{height:'100%'}}
                 className={'title-selector'}/>
        ],
        dataIndex: 'titleLabel',
        width:'28%',
      },
      {
        title: 'difficulty',
        dataIndex: 'difficultyLabel',
        width:'8%',
        filters: [],
        filterMultiple: false,
        onFilter: (value, record) => record.difficulty === value || value === -1,
        sorter: (a, b) => a.difficulty - b.difficulty,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'languages',
        dataIndex: 'languagesLabel',
        width:'15%'
      },
      {
        title: 'type',
        dataIndex: 'typeLabel',
        width: '10%',
        sorter: (a, b) => a.problemType - b.problemType
      },
      {
        title: 'exam',
        dataIndex: 'isQuizProblemLabel',
        width: '4%',
        sorter: (a, b) => a.isQuizProblm < b.isQuizProblm ? -1 : 1
      },
      // {
      //   title: 'tags',
      //   dataIndex: 'tagsLabel',
      //   width:'35%',
      //   filters: tagFilters,
      //   filterMultiple: true,
      //   onFilter: (value, record) => record.tags.includes(value),
      // },
      {
        title: 'creator',
        dataIndex: 'creator',
        width:'15%'
      },
      {
        title: 'created time',
        dataIndex: 'createdTimeLable',
        width:'20%',
        sorter: (a, b) => b.createdTime - a.createdTime
      },
    ];

    return (
      <Table columns={columns} dataSource={processedData} size={'small'} pagination={{size:"small", defaultPageSize: 50}}/>
    )
  }
}

export default AdminProblemsComponent;

