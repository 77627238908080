import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {message, Select, Button, Input} from "antd";
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

const {Option} = Select;


const mdParser = require('markdown-it')().use(require('markdown-it-sub')).use(require('markdown-it-sup'));


class AdminProblemDescriptionComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allTags: []
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.tag.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({allTags: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    });
  }

  handleImageUpload(file, callback) {
    const reader = new FileReader();
    reader.onload = () => {
      setTimeout(() => {
        callback(reader.result)
      }, 1000)
    };
    reader.readAsDataURL(file)
  }

  render() {
    const father = this.props.father;
    const problem = father.state.problem;
    const allTags = this.state.allTags;
    const tagOptions = [];
    for(let i = 0; i < allTags.length; i ++) {
      tagOptions.push(<Option key={allTags[i]} value={allTags[i]}>{allTags[i]}</Option>)
    }
    return(
      <div style={{padding:"15px 15px 25px 15px", background:'#fff', borderRadius:5}}>
        <h3 style={{marginBottom:15}}>{problem.problemId}.&nbsp;&nbsp;{problem.title}</h3>
        <br/>
        <div>
          <b>&nbsp;&nbsp;&nbsp;&nbsp;难度&nbsp;&nbsp;</b>
          <Select value={problem.difficulty} style={{ width: 100 }}
                  onChange={(value) => father.handleChange("difficulty", value)}
          >
            <Option value={0}>Easy</Option>
            <Option value={1}>Medium</Option>
            <Option value={2}>Hard</Option>
          </Select>
          <b>&nbsp;&nbsp;&nbsp;&nbsp;展示&nbsp;&nbsp;</b>
          <Select value={problem.isDeleted} style={{ width: 100 }}
                  onChange={(value) => father.handleChange("isDeleted", value)}
          >
            <Option value={"0"}>展示</Option>
            <Option value={"1"}>不展示</Option>
          </Select>
          <b>&nbsp;&nbsp;&nbsp;&nbsp;标签&nbsp;&nbsp;</b>
          <Select mode="multiple" allowClear style={{ width: 350 }}
                  value={problem.tags} onChange={(value) => father.handleChange("tags", value)}
          >
            {tagOptions}
          </Select>
          <b>&nbsp;&nbsp;&nbsp;&nbsp;视频&nbsp;&nbsp;</b>
          <Input style={{ width: 350 }}
                  value={problem.video} onChange={(e) => father.handleChange("video", e.target.value)}
          />
          <Button style={{float:'right'}}
                  onClick={function () {
                    saveProblem(problem);
                    saveTags(problem);
                  }}
          >
            保存
          </Button>
        </div>
        <br/>
        <p style={{color:'red', fontSize:15, fontWeight:900}}>
          记住上标下标的处理：上标 10^5^；下标 nums~i~.
        </p>
        <MdEditor
          value={problem.desc}
          style={{ height: "500px" }}
          renderHTML={function (text) {
            return mdParser.render(text);
          }}
          onImageUpload={this.handleImageUpload}
          onChange={(value) => father.handleChange("desc", value.text)}
        />
      </div>
    )
  }
}

function saveProblem(problem) {
  const exp = /!\[.*]\((.*http.*)\)/g;
  const res = exp.exec(problem.desc);

  if(res !== null) {
    message.warning("请从本地上传图片（可右击图片后复制，粘贴覆盖原网络图片）");
    return ;
  }
  axios.post(
    getBackendUrl(backendPath.admin.problem.update.path),
    {
      'id': problem.id,
      'title': problem.title,
      'difficulty': problem.difficulty,
      'desc': problem.desc,
      'isDeleted': problem.isDeleted,
      'video': problem.video
    }
  ).then(function (response) {
    if(response.data.status === 1) {
      message.success("题目保存成功");
    } else {
      message.warning(response.data.message)
    }
  }).catch(function (error) {
    console.log(error);
  });
}

function saveTags(problem) {
  axios.post(
    getBackendUrl(backendPath.admin.tag.tagProblem.path),
    {
      'id': problem.id,
      'tags': problem.tags
    }
  ).then(function (response) {
    if(response.data.status === 1) {
      message.success("标签保存成功");
    } else {
      message.warning(response.data.message)
    }
  }).catch(function (error) {
    console.log(error);
  });
}


export default AdminProblemDescriptionComponent;

