import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {message, Input, Button, Select} from "antd";
import {problemType} from "../../../config/problem";
import {BooleanType} from "../../../config/constant";
const { Option } = Select;



class AdminProblemCreateComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      shortTitle: "",
      problemType: 0,
      isQuizProblem: "0"
    };
  }

  handleCreate() {
    let problemIdCreate = this.props.parent.state.problemIdCreate.general;
    if(this.state.isQuizProblem === BooleanType.true) {
      problemIdCreate = this.props.parent.state.problemIdCreate.special;
    }
    axios.post(
      getBackendUrl(backendPath.admin.problem.create.path),
      {
        'problemId': problemIdCreate,
        'title': this.state.title,
        'shortTitle': this.state.shortTitle,
        'problemType': this.state.problemType,
        'isQuizProblem': this.state.isQuizProblem
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("题目创建成功，请前往编辑");
        window.setTimeout(function () {window.location.reload();}, 2000);
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  render() {
    const typeOptions = [], parent = this.props.parent;
    for(let i = 0; i < problemType.length; i ++) {
      typeOptions.push(
        <Option key={problemType[i].type} value={problemType[i].type}>{problemType[i].label}</Option>
      )
    }
    let problemIdCreate = this.props.parent.state.problemIdCreate.general;
    if(this.state.isQuizProblem === BooleanType.true) {
      problemIdCreate = this.props.parent.state.problemIdCreate.special;
    }
    return(
      <div style={{padding:"15px 15px 25px 15px", background:'#fff', borderRadius:5, height:300}}>
        <h3 style={{marginBottom:15}}>创建题目</h3>
        <span>题号(默认题号，填坑用，尽量不要修改)&nbsp;&nbsp;&nbsp;</span>
        <Input value={problemIdCreate} onChange={(value) => parent.setState({problemIdCreate: Number(value.target.value)})}/><br/>
        <span>标题&nbsp;&nbsp;&nbsp;</span>
        <Input onChange={(value) => this.setState({title: value.target.value})}/><br/>
        <span>URL短标题&nbsp;&nbsp;&nbsp;</span>
        <Input onChange={(value) => this.setState({shortTitle: value.target.value})}/><br/><br/>
        <span>题目类型&nbsp;&nbsp;&nbsp;</span>
        <Select style={{width:120}} value={this.state.problemType}
                onChange={(value) => this.setState({problemType: value})}
        >
          {typeOptions}
        </Select>
        <span>&nbsp;&nbsp;&nbsp;是否是Exam题目&nbsp;&nbsp;&nbsp;</span>
        <Select style={{width:120}} value={this.state.isQuizProblem}
                onChange={(value) => this.setState({isQuizProblem: value})}
        >
          <Option key={"0"} value={"0"}>否</Option>
          <Option key={"1"} value={"1"}>是</Option>
        </Select>
        <Button style={{float:'right'}} onClick={this.handleCreate.bind(this)}>创建</Button>
      </div>
    )
  }
}
export default AdminProblemCreateComponent;
