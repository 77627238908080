import React from 'react';
import HeaderComponent from "../components/header/header";
import ProblemsComponent from "../components/user/problems/problems";
import { Row, Col } from 'antd';
import TagSelectorComponent from "../components/user/problems/tagSelector";
import CompanySelectorComponent from "../components/user/problems/companySelector";
import AchievementStatisticComponent from "../components/user/problems/achievementStatistic";


class ProblemsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientHeight: Math.max(document.documentElement.clientHeight, 600)
    };
    const current = this;
    window.onresize = function () {
      current.setState({clientHeight: Math.max(document.documentElement.clientHeight, 600)})
    }
  }
  render() {
    document.title = "Problems - SCode";
    return(
      <div style={{width:'100%', background:'#eff3f9'}}>
        <HeaderComponent anonymousAllowed={true}/>
        <div style={{padding:20, background:'white', borderRadius:8,
          minHeight: this.state.clientWidth, margin:'30px auto', width: 1200}}>
          <Row>
            <Col span={18}>
              <div style={{borderRadius:8, overflow:'hidden'}}>
                <ProblemsComponent showTags={true}/>
              </div>
            </Col>
            <Col span={6}>
              <AchievementStatisticComponent/>
              <br/>
              <CompanySelectorComponent/>
            </Col>
          </Row>

        </div>
      </div>
    )
  }
}
export default ProblemsPage;
