import React from 'react';
import {message, Skeleton} from 'antd';

import 'react-markdown-editor-lite/lib/index.css';

import '../../css/description.css';
import { Editor } from '@tinymce/tinymce-react';

import { Menu } from 'antd';
import { HistoryOutlined, VideoCameraOutlined, SolutionOutlined, FileTextOutlined } from '@ant-design/icons';
import DescriptionComponent from "./desc";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {languageIndex} from "../../../config/language";
import {ideComponent} from "./IDE";
import SubmissionsComponent from "./submissions";
import VideoComponent from "./video";
import SolutionComponent from "./solution";

export let problemConsoleComponent;

class ProblemConsoleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: 'desc',
      loading: false,
      data: null
    };
    problemConsoleComponent = this;
  }

  handleClick(e) {
    this.setState({selectedKey: e.key, data: null});
    if(e.key === 'solution' || e.key === 'submission') {
      this.setState({loading: true});
      const current = this;
      if(e.key === 'solution') {
        const language = ideComponent.state.language;
        axios.get(
          getBackendUrl(
            backendPath.language.retrieveSolution.path +
                    this.props.problem.shortTitle + "/" + languageIndex(language).toString())
        ).then(function (response) {
          if(response.data.status === 1) {
            current.setState({data: <SolutionComponent solution={response.data.result} language={language}/>})
          } else {
            message.warning(response.data.message)
          }
          current.setState({loading: false});
        }).catch(function (error) {
          console.log(error);
        })
      }
      if(e.key === 'submission') {
        axios.get(getBackendUrl(backendPath.submission.listOneProblem.path+ this.props.problem.shortTitle))
          .then(function (response) {
          if(response.data.status === 1) {
            console.log(response.data)
            current.setState({
              data: <SubmissionsComponent submissions={response.data.result} hiddenTitle={true}/>
            });
          } else {
            message.warning(response.data.message)
          }
          current.setState({loading: false});
        }).catch(function (error) {
          console.log(error);
        })
      }
    }
  }

  render() {
    const problem = this.props.problem;
    if(problem.problemId < 0) {
      return <Skeleton active paragraph={{ rows: 6 }} />;
    }
    let content = <DescriptionComponent problem={problem}/>;
    if(this.state.selectedKey === 'solution' || this.state.selectedKey === 'submission') {
      content = this.state.data;
    }
    if(this.state.selectedKey === 'video') {
      content = <VideoComponent video={problem.video}/>
    }
    if(this.state.loading) {
      content = <Skeleton active paragraph={{ rows: 6 }} />;
    }
    return (
      <>
        <Menu mode="horizontal" selectedKeys={[this.state.selectedKey]} style={{marginBottom:20}}
              onClick={(e) => this.handleClick(e)}>
          <Menu.Item key="desc" icon={<FileTextOutlined />} disabled={this.state.loading}>
            Description
          </Menu.Item>
          <Menu.Item key="solution" icon={<SolutionOutlined />} disabled={this.state.loading}>
            Solution
          </Menu.Item>
          <Menu.Item key="submission" icon={<HistoryOutlined />} disabled={this.state.loading}>
            Submissions
          </Menu.Item>
          {
            problem.video ? <Menu.Item key="video" icon={<VideoCameraOutlined />} disabled={this.state.loading}>
                              Video
                            </Menu.Item> : <></>
          }
        </Menu>
        {content}
      </>
    )
  }
}
export default ProblemConsoleComponent;

