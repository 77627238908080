import React from 'react';
import {message, Modal, Table} from 'antd';
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";
import {judgeResultContext} from "../../../config/judger";
import {languagesConfig} from "../../../config/language";
import AceEditor from "react-ace";
import {IdeLanguageKey} from "./IDE";
import {ideComponent} from "./IDE";

class SubmissionsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {submissionDetail: null};
  }

  handleLoadCode(submission) {
    const submissionId = submission.id, current = this;
    if(this.state.submissionDetail !== null) {
      message.warn("loading ...");
      return ;
    }
    current.setState({submissionDetail: {}});
    axios.get(
      getBackendUrl(backendPath.submission.retrieveCode.path + submissionId)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({submissionDetail: {
            code: response.data.result,
            problemTitle: submission.problemTitle,
            problemShortTitle: submission.problemShortTitle,
            language: submission.language
        }});
      } else {
        current.setState({submissionDetail: null});
        message.warning(response.data.message)
      }
    })
  }

  render() {
    const submissionsContent = [], submissions = this.props.submissions, submissionDetail = this.state.submissionDetail;
    const current = this;
    let submissionColumns = [];
    if(!this.props.hiddenTitle) {
      submissionColumns.push({title: 'Problem', dataIndex: 'problemTitle'})
    }
    submissionColumns = submissionColumns.concat([
      {title: 'Time', dataIndex: 'createdTime'},
      {title: 'Result', dataIndex: 'result'},
      {title: 'Runtime', dataIndex: 'timeSpent'},
      {title: 'Memory', dataIndex: 'spaceSpent'},
      {title: 'Language', dataIndex: 'language'},
    ]);
    for (let i = 0; i < submissions.length; i ++) {
      let submission = submissions[i];
      if(submission.result === -2) continue ;
      const date = new Date(submission.createdTime);
      let time = date.toLocaleTimeString() + " ";
      if(date.toLocaleDateString() !== new Date().toLocaleDateString()) {
        time += date.toLocaleDateString();
      }
      const resultContext = judgeResultContext(submission.result);
      submissionsContent.push({
        createdTime: time,
        result: <h3 style={{color:resultContext.color, margin:0, cursor:'pointer'}} onClick={() => this.handleLoadCode(submission)}>
          {resultContext.text}
        </h3>,
        timeSpent: submission.timeSpent === -1 ? "N/A" : submission.timeSpent.toString() + "ms",
        spaceSpent: submission.spaceSpent === -1 ? "N/A" : Math.ceil((submission.spaceSpent) / (1024 * 1024)).toString() + "MB",
        language: languagesConfig[submission.language].label,
        problemTitle:
          <div className={'title-label'}
               onClick={() => window.open(
                 getFrontendUrl(frontendPath.problem + submission.problemShortTitle, "_blank")
               )}
          >
          {submission.problemTitle}
        </div>
      })
    }
    return (
     <>
       <Table columns={submissionColumns} dataSource={submissionsContent} size={'small'}
              rowClassName={(record, index) => {return  index % 2 === 0 ? "ant-table-odd-row" : "ant-table-even-row"}}
              pagination={{size:"small", defaultPageSize: 10, pageSizeOptions:[10, 20, 30, 50, 100], showSizeChanger: true}}
       />
       <Modal title={"Submission Code - " + (!submissionDetail ? "" : submissionDetail.problemTitle)}
              width={900} visible={submissionDetail !== null}
              footer={<div style={{height:40}}>
                <button className={'ide-submit-button'}
                        onClick={() => {
                          localStorage.setItem(IdeLanguageKey, languagesConfig[submissionDetail.language].value);
                          localStorage.setItem(
                            submissionDetail.problemShortTitle + "#" + languagesConfig[submissionDetail.language].value,
                            submissionDetail.code
                            );
                          const nextUrl = getFrontendUrl(frontendPath.problem + submissionDetail.problemShortTitle);
                          if(window.location.href.startsWith(nextUrl)) {
                            ideComponent.handleResetLanguage(languagesConfig[submissionDetail.language].value);
                            current.setState({submissionDetail: null});
                          } else {
                            window.open(nextUrl, "_blank")
                          }
                        }}>
                  Edit in IDE
                </button>
              </div>}
              onCancel={() => this.setState({submissionDetail: null})}
        >
         {
           submissionDetail && submissionDetail.hasOwnProperty('code') ?
             <AceEditor
                mode={
                  languagesConfig[submissionDetail.language].value === 'python3' ?
                  'python' : languagesConfig[submissionDetail.language].value
                }
                theme={"solarized_light"} width={"100%"} height={"500px"} fontSize={14}
                showPrintMargin={false} enableBasicAutocompletion={true}
                value={submissionDetail.code} name="submission-code" editorProps={{ $blockScrolling: true }}
              /> :
                 <div className={'loading'} style={{marginBottom:"10em"}}></div>
         }
        </Modal>

     </>
    )
  }
}

export default SubmissionsComponent;

