import axios from 'axios';

export const env = "prod";

export const settings = {
  dev: {
    backendUrlBase: "http://local.shanganonline.com:8000",
    frontendUrlBase: "http://local.shanganonline.com:3000",
    domain: ".shanganonline.com",
  },
  prod: {
    backendUrlBase: "https://oj.shanganonline.com/api",
    frontendUrlBase: "https://oj.shanganonline.com",
    domain: ".shanganonline.com",
  }
};

axios.defaults.withCredentials = true;

export let headerHeight = 80;
