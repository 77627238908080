import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {message, Input, Modal, Select, Table, Button} from "antd";
import {DeletedType} from "../../../config/constant";
import {getRoles, Role} from "../../../config/roles";
const {Option} = Select;


class AdminUserUpdateComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      updateUser: null,
      addPermission: -1,
      addPermissionValidDays: 0
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.permission.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({permissions: response.data.result});
      }
    });
    this.handleAddPermission = this.handleAddPermission.bind(this);
  }

  handleDeletePermission(userPermissionID) {
    const parent = this.props.parent, updateUser = parent.state.updateUser;
    if(!updateUser) return;
    axios.post(
      getBackendUrl(backendPath.admin.user.updatePermission.path),
      {
        userPermissionId: userPermissionID, isDeleted: DeletedType.true
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("权限删除成功。");
        parent.updateUser(updateUser.info, updateUser.isUpdatePermission)
      } else {
        message.warning(response.data.message)
      }
    })
  }

  handleAddPermission() {
    const parent = this.props.parent, updateUser = parent.state.updateUser, current = this;
    if(!updateUser) return;
    const userId = updateUser.info.id, addPermission = this.state.addPermission, addPermissionValidDays = this.state.addPermissionValidDays;
    if(addPermission < 0 || !addPermissionValidDays ||  addPermissionValidDays <= 0) {
      message.warn("无效权限或有效天数小于等于0");
      return;
    }
    axios.post(
      getBackendUrl(backendPath.admin.user.addPermission.path),
      {
        userId: userId, permission: addPermission, validDays: addPermissionValidDays
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("权限新增成功。");
        current.setState({addPermission: -1, addPermissionValidDays: 0})
        parent.updateUser(updateUser.info, updateUser.isUpdatePermission)
      } else {
        message.warning(response.data.message)
      }
    })
  }

  handleUpdate(user) {
    axios.post(
      getBackendUrl(backendPath.admin.user.update.path),
      {
        id: user.id, nickName: user.nickName, isDeleted: user.isDeleted, roles: user.roles
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("用户更新成功。");
        window.setTimeout(function () {window.location.reload();}, 1500);
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  render() {
    const parent = this.props.parent, current = this;
    if(!parent.state.updateUser) {
      return(<></>);
    }
    const user = JSON.parse(JSON.stringify(parent.state.updateUser.info));
    const userPermissions = parent.state.updateUser.permissions;
    const isUpdatePermission = parent.state.updateUser.isUpdatePermission;
    const permissions = this.state.permissions;
    const permissionId2Title = {}, permissionOptions = [];
    permissions.forEach(function (p) {
      permissionId2Title[p.id] = p.title;
      permissionOptions.push(<Option value={p.id}>{p.title}</Option>)
    });
    const userPermissionsData = [];
    const permissionsTableColumns = [
      {title: '权限', dataIndex: 'title'},
      {title: '失效时间', dataIndex: 'end_time'},
      {title: '权限状态', dataIndex: 'status'}
    ];
    userPermissions.forEach(function (up) {
      if(permissionId2Title.hasOwnProperty(up.permission)) {
        const date = new Date(up.endTime);
        let endTime = date.toLocaleTimeString() + " ";
        let status = <>
          <span style={{color:'#5CB85C'}}><b>有效</b></span>&nbsp;&nbsp;&nbsp;
          <a onClick={() => current.handleDeletePermission(up.id)}>置为无效</a>
        </>;
        if(date.toLocaleDateString() !== new Date().toLocaleDateString()) {
          endTime += date.toLocaleDateString();
        }
        if(up.isDeleted === DeletedType.true) {
          status = <span style={{color:'#D9534E'}}><b>已置为无效</b></span>
        }
        if(date <= new Date()) {
          status = <span style={{color:'#D9534E'}}><b>已过期</b></span>;
        }
        userPermissionsData.push({
          'title': permissionId2Title[up.permission],
          'end_time': endTime,
          'status': status
        })
      }
    });
    user.roles = getRoles(user.role);
    if(isUpdatePermission) {
      return(
        <Modal title={"权限管理  --  " + user.email} width={700} visible={true} footer={null} onCancel={() => parent.setState({updateUser: null})}>
          <div>
            <Table columns={permissionsTableColumns} dataSource={userPermissionsData} size={'small'} pagination={false}/>
            <br/><br/>
            <div style={{width:'100%', height: 50}}>
              <h3 style={{float:'left'}}>权限&nbsp;&nbsp;</h3>
              <Select style={{width:'40%', float:'left'}} value={this.state.addPermission}  onChange={(v) => this.setState({addPermission: v})}>
              {permissionOptions}
              </Select>
              <Input style={{width:'15%', float:'left', marginLeft:'5%'}}  placeholder={"有效天数"}
                     onChange={(e) => this.setState({addPermissionValidDays: Number(e.target.value)})}/>
              <Button style={{width:'15%', float:'left', marginLeft:'10%'}} onClick={this.handleAddPermission}>新增权限</Button>
            </div>
          </div>
        </Modal>
      )
    }
    return(
      <Modal title={"信息更新  --  " + user.email} visible={true} okText={"更新"}
             onOk={() => this.handleUpdate(user)}
             onCancel={() => parent.setState({updateUser: null})}
      >
        <div>
          <span>昵称（姓名、微信名等学生称呼）</span>
          <Input defaultValue={user.nickName} onChange={(value) => user.nickName = value.target.value}/>
        </div><br/>
        <div>
          <span>用户角色</span><br/>
          <Select mode="multiple" allowClear style={{ width: 400 }}
                  defaultValue={user.roles} onChange={(value) => user.roles = value}
          >
            <Option key={Role.user} value={Role.user}>用户</Option>
            <Option key={Role.teacher} value={Role.teacher}>教师</Option>
            <Option key={Role.teachingDirector} value={Role.teachingDirector}>教学总监</Option>
            <Option key={Role.admin} value={Role.admin}>管理员</Option>
          </Select>
        </div><br/>
        <div>
          <span>是否有效&nbsp;&nbsp;&nbsp;</span>
          <Select style={{width:120}} defaultValue={user.isDeleted}
                  onChange={(value) => user.isDeleted = value}
          >
            <Option key={DeletedType.true} value={DeletedType.true}>无效</Option>
            <Option key={DeletedType.false} value={DeletedType.false}>生效</Option>
          </Select>
        </div>
      </Modal>
    )
  }
}
export default AdminUserUpdateComponent;
