import React from 'react';
import HeaderComponent from "../components/header/header";
import axios from "axios";
import {backendPath, getBackendUrl} from "../config/url";
import {message, Row, Col, Menu, Skeleton} from "antd";
import SubmissionsComponent from "../components/user/problem/submissions";
import {headerHeight} from "../config";
import { HistoryOutlined, CameraOutlined, SolutionOutlined, FileTextOutlined, UserOutlined } from '@ant-design/icons';
import AchievementStatisticComponent from "../components/user/problems/achievementStatistic";
import ProblemsComponent from "../components/user/problems/problems";
import avatar from "../components/img/avatar.jpg"
import ResetPasswordComponent from "../components/user/account/password";
import ResetInfoComponent from "../components/user/account/info";



class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submissions: null,
      selectedTab: 'submission',
      avatar: avatar,
      avatarOpacity: 0,
      clientHeight: Math.max(document.documentElement.clientHeight, 600)
    };
    this.loadSubmissions = this.loadSubmissions.bind(this);
    const current = this;
    const xmlhttp=new XMLHttpRequest();
      xmlhttp.open("GET", "https://learn.shanganonline.com/api/user/1/", true);
      xmlhttp.withCredentials = true;
      xmlhttp.send();

      xmlhttp.onreadystatechange = function(){
        if(xmlhttp.readyState === 4) {
          if(xmlhttp.status === 200 ) {
            const response = JSON.parse(xmlhttp.responseText);
            if(response['avatar'] != null) {
              current.setState({avatar: "https://shangan-user.oss-cn-beijing.aliyuncs.com/" + response['avatar']});
            }
          }
        }
      };
    current.loadSubmissions();
    window.onresize = function () {
      current.setState({clientHeight: Math.max(document.documentElement.clientHeight, 600)})
    }
  }

  loadSubmissions() {
    const current = this;
    axios
      .get(getBackendUrl(backendPath.submission.list.path))
      .then(function (response) {
        if(response.data.status === 1) {
          current.setState({submissions: response.data.result});
        } else {
          message.warning(response.data.message)
        }
      }).catch(function (error) {
        console.log(error);
      })
  }

  handleUploadAvatar() {
    const current = this;
    const ava = document.querySelector('#avatar-upload').files[0];
    const isPict = (ava.type === 'image/jpeg' || ava.type === 'image/png');
    if (!isPict) {
      message.warning('请上传JPG/PNG图片');
      return ;
    }
    const isLt1M = ava.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.warning('上传头像应小于1M');
      return ;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
        const data = e.target.result;
        const image = new Image();
        image.onload=function(){
          if(image.width / image.height > 0.8 && image.width / image.height < 1.2) {
            current.setState({avatar: ava});
            let xmlhttp=new XMLHttpRequest();
            let fd = new FormData();
            fd.append("avatar", ava)
            xmlhttp.open("PUT", "https://learn.shanganonline.com/api/user/1/", true);
            xmlhttp.withCredentials = true;
            xmlhttp.send(fd);
            xmlhttp.onreadystatechange = function(){
              if(xmlhttp.readyState === 4) {
                if(xmlhttp.status === 200 ) {
                  message.success("头像更新完成")
                  window.setInterval(function () {
                    window.location.reload();
                  }, 2000);
                } else {
                  message.warning("头像上传失败")
                }
              }
            };
          } else {
            message.warning("请上传像素比接近 1 的图片");
          }
        };
        image.src= data;
    };
    reader.readAsDataURL(ava);
  }

  render() {
    document.title = "Account - SCode";
    let content = <Skeleton active paragraph={{ rows: 6 }} />;
    if(this.state.selectedTab === 'submission' && this.state.submissions) {
      content = <SubmissionsComponent submissions={this.state.submissions}/>;
    }
    if(this.state.selectedTab === 'collection') {
      content = <ProblemsComponent hiddenHeader={true} defaultPageSize={10} onlyCollected={true}/>;
    }
    if(this.state.selectedTab === 'reset-pwd') {
      content = <ResetPasswordComponent width={400} height={520}/>
    }
    if(this.state.selectedTab === 'reset-info') {
      content = <ResetInfoComponent width={500} height={520}/>
    }
    return(
      <div style={{width:'100%', background:'#eff3f9'}}>
        <HeaderComponent/>
        <div style={{height:this.state.clientHeight - headerHeight - 60, margin:"30px auto", width:1150}}>
          <Row>
            <Col span={6}>
              <div style={{padding:10, width:'100%', borderRadius:8, background:"#FFFFFF",
                height:this.state.clientHeight - headerHeight - 60, marginLeft:-10}}>
                <div style={{width:100, height:100, borderRadius:100, margin:"20px auto",
                  backgroundImage:`url(${this.state.avatar})`, backgroundSize:'100% 100%', cursor:'pointer'}}>
                  <div style={{
                    width:100, height:100, background:'gray', borderRadius:100, opacity:this.state.avatarOpacity, cursor:'pointer'}}
                       onMouseEnter={() => this.setState({avatarOpacity:0.8})}
                       onMouseLeave={() => this.setState({avatarOpacity:0})} >
                    <input type={'file'} id={'avatar-upload'} hidden={true}
                           onChange={() => {this.handleUploadAvatar()}}/>
                    <CameraOutlined style={{fontSize:40, marginTop:30, marginLeft:30}}
                           onClick={() => {document.querySelector('#avatar-upload').click()}}/>
                  </div>
                </div>
                <div style={{marginLeft:-20}}><AchievementStatisticComponent /></div>
              </div>
            </Col>
            <Col span={18}>
              <div style={{padding:20, width:'100%', borderRadius:8, background:"#FFFFFF",
                height:this.state.clientHeight - headerHeight - 60, marginLeft:10, overflow:'scroll'}}>
                <Menu mode="horizontal" selectedKeys={[this.state.selectedTab]} style={{marginBottom:10}}
                    onClick={(e) => {this.setState({selectedTab: e.key})}}>
                  <Menu.Item key="submission" icon={<FileTextOutlined />}>
                    近期做题
                  </Menu.Item>
                  <Menu.Item key="collection" icon={<SolutionOutlined />}>
                    我的收藏
                  </Menu.Item>
                  <Menu.Item key="reset-info" icon={<UserOutlined />}>
                    个人信息
                  </Menu.Item>
                  <Menu.Item key="reset-pwd" icon={<HistoryOutlined />}>
                    修改密码
                  </Menu.Item>
                </Menu>
                {content}
              </div>
            </Col>
          </Row>
        </div>

      </div>
    )
  }
}
export default AccountPage;
