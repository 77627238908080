import React from 'react';
import ProblemsComponent from "../problems/problems";
import {buildLadder} from "../../../config/ladder";
import "../../css/ladder.css"
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {message, Skeleton} from "antd";


class LadderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ladderComponent: null,
      title: 'SCode - Algorithm Ladder'
    };
    const current = this;
    axios.get(getBackendUrl(backendPath.ladder.retrieve.path + this.props.ladderId))
        .then(function (response) {
        if(response.data.status === 1) {
          try {
            current.setState({
              ladderComponent: buildLadder(JSON.parse(response.data.result.info)),
              title: response.data.result.title
            })
          } catch (e) {

          }
        } else {
          message.warning(response.data.message)
        }
      }).catch(function (error) {
        console.log(error);
      })
  }
  render() {
    document.title = this.state.title + " - SCode";
    return(
      <div style={{height:"100%", position:'relative'}}>
        <div className={'ladder-list-container'}>
          {
            this.state.ladderComponent ? this.state.ladderComponent:
              <div style={{height: '100%', width:'100%', paddingRight:6}}>
                <Skeleton active paragraph={{ rows: 13 }} />
              </div>
          }
        </div>
        <div className={'ladder-problem-list-container'}>
          <div className={'ladder-problem-list'}>
            <ProblemsComponent showTags={false} hiddenHeader={true}/>
          </div>
        </div>

     </div>
    )
  }
}
export default LadderComponent;
