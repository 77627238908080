import React from 'react';
import HeaderComponent from "../components/header/header";
import {headerHeight} from "../config";
import QuizProblemComponent from "../components/user/quizzes/problem/problem";


class QuizProblemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientHeight: Math.max(document.documentElement.clientHeight, 600),
      clientWidth: Math.max(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, 1100)
    };
    const current = this;
    window.onresize = function () {
      current.setState({
        clientHeight: Math.max(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight, 300),
        clientWidth: Math.max(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth, 1100)
      })
    };
  }
  render() {
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <QuizProblemComponent quizProblemId={this.props.match.params.quiz_problem_id}
          height={this.state.clientHeight - headerHeight} width={this.state.clientWidth} />
      </div>
    )
  }
}
export default QuizProblemPage;
