import React from 'react';
import HeaderComponent from "../../components/header/header";
import AdminLaddersComponent from "../../components/admin/ladder/adminLadders";


class AdminLaddersPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    document.title = "Admin Ladders - SCode";
    return(
      <div style={{width:'100%'}}>
        <HeaderComponent/>
        <div style={{margin:'30px auto', width: 1000}}>
          <AdminLaddersComponent/>
        </div>
      </div>
    )
  }
}
export default AdminLaddersPage;
