import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {message, Input, Button, Select} from "antd";
import {problemType} from "../../../config/problem";
const { Option } = Select;



class AdminPermissionCreateComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      detail: ""
    };
  }

  handleCreate() {
    axios.post(
      getBackendUrl(backendPath.admin.permission.create.path),
      {
        'title': this.state.title,
        'detail': this.state.detail
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("权限创建成功");
        window.setTimeout(function () {window.location.reload();}, 2000);
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  render() {
    const typeOptions = [], parent = this.props.parent;
    for(let i = 0; i < problemType.length; i ++) {
      typeOptions.push(
        <Option key={problemType[i].type} value={problemType[i].type}>{problemType[i].label}</Option>
      )
    }
    return(
      <div style={{padding:"15px 15px 25px 15px", background:'#fff', borderRadius:5, height:250}}>
        <h3 style={{marginBottom:15}}>创建权限</h3>
        <span>权限标题&nbsp;&nbsp;&nbsp;</span>
        <Input onChange={(value) => this.setState({title: value.target.value})}/><br/>
        <span>权限描述&nbsp;&nbsp;&nbsp;</span>
        <Input onChange={(value) => this.setState({detail: value.target.value})}/><br/><br/>
        <Button style={{float:'right'}} onClick={this.handleCreate.bind(this)}>创建</Button>
      </div>
    )
  }
}
export default AdminPermissionCreateComponent;
