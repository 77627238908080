import React from 'react';
import {message, Skeleton} from 'antd';
import {StarOutlined} from '@ant-design/icons';

import 'react-markdown-editor-lite/lib/index.css';

import '../../css/description.css';
import { Editor } from '@tinymce/tinymce-react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";

const mdParser = require('markdown-it')().use(require('markdown-it-sub')).use(require('markdown-it-sup'));


class DescriptionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      problem: this.props.problem
    }
  }

  handleCollect() {
    const current = this;
    let problem = current.state.problem;
    axios.get(
        getBackendUrl(backendPath.problem.collect.path + this.props.problem.id)
      ).then(function (response) {
        if(response.data.status === 1) {
          problem.collected = !problem.collected;
          current.setState({problem: problem});
        } else {
          message.warning(response.data.message)
        }
      })
  }

  render() {
    const problem = this.state.problem;
    let desc = mdParser.render(problem.desc);
    desc = desc.replace(
      new RegExp("(<code>[\\s\\S]*?)Input([\\s\\S]*?</code>)", "g"),
      "$1<strong>Input</strong>$2"
    );
    desc = desc.replace(
      new RegExp("(<code>[\\s\\S]*?)Output([\\s\\S]*?</code>)", "g"),
      "$1<strong>Output</strong>$2"
    );
    desc = desc.replace(
      new RegExp("(<code>[\\s\\S]*?)Explanation([\\s\\S]*?</code>)", "g"),
      "$1<strong>Explanation</strong>$2"
    );

    desc = desc.replace(new RegExp("<pre><code>", "g"), "<pre>");
    desc = desc.replace(new RegExp("</code></pre>", "g"), "</pre>");
    return(
      <>
        <h3 style={{marginBottom:15}}>
          {problem.problemId}.&nbsp;&nbsp;{problem.title}&nbsp;&nbsp;
          <span style={{cursor:'pointer', color: problem.collected ? 'red': 'black'}}
                onClick={() => this.handleCollect()}>
            <StarOutlined />
          </span>
        </h3>
        <div dangerouslySetInnerHTML={{__html:desc}} className={'custom-html-style'}></div>
        {/*初始化加载Editor静态资源*/}
        <Editor
            value={""}
            inline={true}
            disabled={true}
            init={{language: 'zh_CN', width: '100%', height: 'auto', menubar: false, plugins: 'codesample', toolbar: ''
            }}
        />
      </>
    )
  }
}
export default DescriptionComponent;

