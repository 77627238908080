import React from 'react';
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl, getQueryVariable} from "../../../config/url";
import {message, Select, Button, Input, Tabs} from "antd";
import 'jsoneditor-react/es/editor.min.css';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/mode-json";
import "../../css/adminLadders.css"
import {buildLadder, ladderInfoStringPattern} from "../../../config/ladder";
import ProblemsComponent from "../../user/problems/problems";
import AdminResourcePermissionComponent from "../permission/adminResoursePermission";

const {Option} = Select, { TabPane } = Tabs;

function countProblems(section) {
  let problems = [];
  function helper(section) {
    if(section.hasOwnProperty('problems')) {
      section.problems.forEach((p) => {
        if(!problems.includes(p)) problems.push(p);
      })
    } else if(section.hasOwnProperty('children')){
      section.children.forEach((childSection) => {
        helper(childSection);
      })
    }
  }
  try {helper(section);} catch (e) {console.log(e)}
  return problems.length;
}

class AdminLadderComponent extends React.Component {

  constructor(props) {
    super(props);
    const ladderID = this.props.ladderID === "new" ? 0 : Number(this.props.ladderID), current = this;
    this.state = {
      ladderInfoString: ladderInfoStringPattern,
      ladderId: ladderID,
      id: null,
      title: null
    };
    if(ladderID) {
      axios.get(
        getBackendUrl(backendPath.admin.ladder.retrieve.path + ladderID)
      ).then(function (response) {
        current.setState({
          ladderInfoString: response.data.result.infoAddress,
          id: response.data.result.id,
          title: response.data.result.title
        })
      })
    }
  }

  handleSave() {
    const info = JSON.parse(this.state.ladderInfoString);
    axios.post(
      getBackendUrl(backendPath.admin.ladder.createOrUpdate.path), {
        title: info.title,
        isDeleted: info.isDeleted,
        problems: countProblems(info),
        coverUrl: info.coverUrl,
        ladderId: this.state.ladderId,
        info:this.state.ladderInfoString
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("success ...")
        window.setTimeout(function () {
          window.open(getFrontendUrl(frontendPath.admin.ladder + response.data.result.ladderId), "_self")
        }, 2000)
      }
      else {message.warning(response.data.message)}
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleChange(key, val) {
    let info = JSON.parse(this.state.ladderInfoString);
    try {
      if(key === 'children') val = JSON.parse(val);
      info['problemsCount'] = countProblems(info);
    }
    catch (e) {}
    info[key] = val;
    this.setState({ladderInfoString: JSON.stringify(info, null, '\t')});
  }

  render() {
    if(!this.state.title) {
      document.title = "New Ladder - SCode Admin";
    } else {
      document.title = this.state.title + " - SCode Admin";
    }
    let ladderInfo = {};
    try {
      ladderInfo = JSON.parse(this.state.ladderInfoString);
    }
    catch (e) {}
    let problemsInfoString = ladderInfo.children;
    if(typeof ladderInfo.children !== "string") {
      problemsInfoString = JSON.stringify(ladderInfo.children, null, "\t")
    }
    return(
      <div style={{height:"100%", position:'relative'}}>
        <div style={{float:'left', width:'55%', height:'100%'}}>
          <div style={{height:"100%", position:'relative'}}>
            <div style={{width:'30%', position:'absolute', height:'100%', overflowX: 'scroll'}}>
              {buildLadder(ladderInfo)}
            </div>
            <div style={{width:'68%', position:'absolute', top: 0, left:'32%', maxHeight:'100%', overflowX:'scroll'}}>
              <ProblemsComponent showTags={false}/>
            </div>
         </div>
        </div>
       <div className={'ladder-create'}>
         <Tabs defaultActiveKey={"settings"} style={{height:'100%'}}>
           <TabPane tab={<b>Settings</b>} key="settings">
             <span>标题</span>
             <Input value={ladderInfo.title} onChange={(e) => this.handleChange('title', e.target.value)}/>
             <br/><span>封面</span><br/>
             <Input value={ladderInfo.coverUrl} onChange={(e) => this.handleChange('coverUrl', e.target.value)}/>
             <br/><span>展示</span><br/>
             <Select style={{width:200}} value={ladderInfo.isDeleted}
                       onChange={(value) => this.handleChange("isDeleted", value)}
               >
                <Option key={"0"} value={"0"}>展示</Option>
                <Option key={"1"} value={"1"}>不展示</Option>
              </Select>
             <Button onClick={() => this.handleSave()} style={{float:'right'}}>
               {this.state.ladderId <= 0 ? "创建" : "更新"}
             </Button>
           </TabPane>
           <TabPane tab={<b>Problems List</b>} key="problems-list">
             <AceEditor
                mode={'json'} theme={'solarized_light'} width={"100%"} height={"90%"}
                fontSize={14} editorProps={{ $blockScrolling: true }}
                showPrintMargin={false} enableBasicAutocompletion={true}
                value={problemsInfoString}
                onChange={(v) => this.handleChange("children", v)}
                name="json-edit"
              />
             <Button onClick={() => this.handleSave()} style={{marginTop:5, width:"100%"}}>
               {this.state.ladderId <= 0 ? "创建" : "更新"}
             </Button>
           </TabPane>
           <TabPane tab={<b>Permission Control</b>} key="permission-control">
             {
               this.state.id ?
                 <AdminResourcePermissionComponent id={this.state.id}/>:<div>点击创建后再进行权限管理</div>
             }
           </TabPane>
         </Tabs>
       </div>
     </div>
    )
  }
}
export default AdminLadderComponent;
