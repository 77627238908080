import React from 'react';

import {Select, Button, Tooltip, message, Modal, Skeleton} from 'antd';
import { RedoOutlined, SettingOutlined, DownOutlined, UpOutlined, LoadingOutlined, VideoCameraOutlined, DoubleRightOutlined } from '@ant-design/icons';
import Input from "antd/lib/input";


import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-sqlserver";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-solarized_dark";
import {languageIndex, languagesConfig} from "../../../config/language";
import {backendPath, getBackendUrl} from "../../../config/url";
import axios from "axios";
import '../../css/ide.css'
import '../../css/loading.css'
import {consoleShowResult} from "../../../config/judger";
import {problemConsoleComponent} from "./console";

const { Option } = Select;
const {TextArea} = Input;
export const IdeLanguageKey = "ide-language-key";
const IdeThemeKey = "ide-theme-key";
const IdeFontSizeKey = "ide-font-size-key";
export let ideComponent;


class IDEComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "java",
      code: "",
      theme: localStorage.getItem(IdeThemeKey) == null ? "solarized_light" : localStorage.getItem(IdeThemeKey),
      fontSize: 15,
      languageSelection: [],
      initCode: {},
      codeKey: {},
      ideSettingsVisible: false,
      consoleVisible: false,
      testCaseInput: []
    };
    ideComponent = this;
    const current = this, shortTitle = current.props.shortTitle;
    axios.get(
      getBackendUrl(backendPath.language.ideLanguages.path + shortTitle)
    ).then(function (response) {
      if(response.data.status === 1) {
        const languages = response.data.result;
        const languageSelection = [], initCode = {}, codeKey = {};
        let defaultLanguage = null;
        for(let i = 0; i < languages.length; i ++) {
          const value = languagesConfig[languages[i].language].value;
          const label = languagesConfig[languages[i].language].label;
          languageSelection.push(
            <Option value={value}>{label}</Option>
          );
          initCode[value] = languages[i].initCode;
          codeKey[value] = shortTitle + "#" + value;
          if(value === localStorage.getItem(IdeLanguageKey)) {
            defaultLanguage = value;
          }
        }
        if(defaultLanguage === null && languages.length > 0) {
          defaultLanguage = languagesConfig[languages[0].language].value;
        }
        const localCode = localStorage.getItem(codeKey[defaultLanguage]);
        const localTheme = localStorage.getItem(IdeThemeKey);
        const localFontSize = Number(localStorage.getItem(IdeFontSizeKey));
        current.setState({
          language: defaultLanguage,
          code: localCode === null ? initCode[defaultLanguage]: localCode,
          theme: localTheme === null ? "solarized_light" : localTheme,
          fontSize: localFontSize === 0 ? 15 : localFontSize,
          languageSelection: languageSelection,
          initCode: initCode,
          codeKey: codeKey
        });
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  handleChangeIdeSettings() {
    this.setState({ideSettingsVisible: true})
  }

  handleResetLanguage(language) {
    const localCode = localStorage.getItem(this.state.codeKey[language]);
    this.setState({
      language: language,
      code: localCode === null ? this.state.initCode[language] : localCode
    });
    localStorage.setItem(IdeLanguageKey, language);
  }

  handleResetCode() {
    const code = this.state.initCode[this.state.language];
    this.setState({code: code});
    localStorage.setItem(this.state.codeKey[this.state.language], code);
  }

  handleEditCode(code) {
    this.setState({code: code});
    localStorage.setItem(this.state.codeKey[this.state.language], code);
  }

  handleChangeFontSize(fontSize) {
    this.setState({fontSize: fontSize});
    localStorage.setItem(IdeFontSizeKey, fontSize);
  }

  handleChangeTheme(theme) {
    this.setState({theme: theme});
    localStorage.setItem(IdeThemeKey, theme);
  }

  render() {
    const consoleVisible = this.state.consoleVisible;
    const height = this.props.height;
    return(
      <div style={{height: height}}>
        <ToolBar ide={this} height={35} problem={this.props.problem}/>
        <AceEditor
          mode={this.state.language === 'python3' ? 'python' : this.state.language}
          theme={this.state.theme}
          width={this.props.width}
          height={consoleVisible ? height - 125 - 45 -40 : height - 45 - 40}
          style={{marginTop:10}}
          fontSize={this.state.fontSize}
          showPrintMargin={false}
          enableBasicAutocompletion={true}
          value={this.state.code}
          onChange={(code) => this.handleEditCode(code)}
          name="shangan-online-judge-ide"
          editorProps={{ $blockScrolling: true }}
        />
        <div className={'test-case-input-outer'} style={{display: consoleVisible ? "": "none"}}>
          <div className={'test-case-input-container'}>
            {this.state.testCaseInput}
          </div>
        </div>
        <RunBar ide={this} height={35}/>
        <Modal title={<b style={{fontFamily:'PingFangSC-Semibold', fontSize: 18, color:'#282828'}}>Editor&nbsp;Settings</b>} footer={null}
               width={420}
               visible={this.state.ideSettingsVisible}
               onCancel={() => this.setState({ideSettingsVisible: false})}
        >
          <div className={'editor-settings-item'}>
            <div className={'editor-settings-item-title'}>Theme:</div>
            <div className={'editor-settings-item-selector'}>
              <Select value={this.state.theme} style={{ width: 290 }}
                      onChange={(value) => this.handleChangeTheme(value)}
              >
                <Option value="textmate">Textmate</Option>
                <Option value="monokai">Monokai</Option>
                <Option value="solarized_light">Solarized Light</Option>
                <Option value="solarized_dark">Solarized Dark</Option>
              </Select>
            </div>
          </div>
          <div className={'editor-settings-item'}>
            <div className={'editor-settings-item-title'}>Font Size:</div>
            <div className={'editor-settings-item-selector'}>
              <Select value={this.state.fontSize} style={{ width: 290 }}
                      onChange={(value) => this.handleChangeFontSize(value)}
              >
                <Option value={14}>14px</Option>
                <Option value={15}>15px</Option>
                <Option value={16}>16px</Option>
                <Option value={17}>17px</Option>
                <Option value={18}>18px</Option>
              </Select>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

class RunBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        submissionId: null,
        result: null,
        testCaseInput: "",
        testCaseInputVisible: false
      };
      const current = this, ide = this.props.ide;
      window.setInterval(function () {
        if(current.state.submissionId === null || current.state.submissionId === "") return;
        axios.get(
          getBackendUrl(backendPath.submission.retrieveResult.path + current.state.submissionId)
        ).then(function (response) {
          if(response.data.status === 1) {
            if(response.data.result != null) {
              const result = JSON.parse(response.data.result);
              problemConsoleComponent.setState({
                data: consoleShowResult(result.data, false),
                loading: false
              });
              current.setState({submissionId: null, result: result})
            }
          } else {
            message.warning(response.data.message)
          }
        }).catch(function (error) {
          console.log(error);
        })
      }, 1500);
      axios.get(
        getBackendUrl(backendPath.problem.retrieveTestCaseInput.path + ide.props.shortTitle)
      ).then(function (response) {
        if(response.data.status === 1) {
          current.setState({testCaseInput: response.data.result});
        } else {
          message.warning(response.data.message)
        }
      }).catch(function (error) {
        console.log(error);
      })
    }

    handleRunAndSubmitCode(isSubmission) {
      if(this.state.submissionId !== null) return;
      const ide = this.props.ide, current = this;
      let url = getBackendUrl(backendPath.submission.runCode.path);
      let data = {
        shortTitle: ide.props.shortTitle,
        language: languageIndex(ide.state.language),
        code: ide.state.code
      };
      if(isSubmission) {
        url = getBackendUrl(backendPath.submission.submit.path);
      } else {
        data.input = this.state.testCaseInput;
        this.setState({testCaseInputVisible: true});
        ide.setState({consoleVisible: true});
        ide.setState({
          testCaseInput:
            <TextArea bordered={true} defaultValue={current.state.testCaseInput} style={{height: '100%'}}
                      onChange={(e) => current.setState({testCaseInput: e.target.value})} />})
        if(this.state.testCaseInput.length > 1000) {
          message.warning("Your test case is too large.")
          return ;
        }
      }

      current.setState({submissionId: ""});
      problemConsoleComponent.setState({
          selectedKey: "submission", loading: true
        });
      axios.post(
        url, data
      ).then(function (response) {
        if(response.data.status === 1) {
          current.setState({submissionId: response.data.result});
        } else {
          problemConsoleComponent.setState({
            data: <p style={{color:'#f54336'}}>{response.data.message}</p>, loading: false
          });
          current.setState({submissionId: null})
        }
      }).catch(function (error) {
        console.log(error);
      });
    }

    handleClickTestCaseInput() {
      const ide = this.props.ide, current = this;
      const visible = !(this.state.testCaseInputVisible && ide.state.consoleVisible);
      this.setState({testCaseInputVisible: visible});
      ide.setState({consoleVisible: visible});
      if(visible) {
        ide.setState({
          testCaseInput:
            <TextArea bordered={true} defaultValue={current.state.testCaseInput} style={{height: '100%'}}
                      onChange={(e) => current.setState({testCaseInput: e.target.value})} />})
      }
    }

    render() {
      const ide = this.props.ide, height = this.props.height;
      const testCaseInputVisible = this.state.testCaseInputVisible && ide.state.consoleVisible;
      return(
        <div style={{height:height, marginTop: 10}}>
          <p className={'console-button'} onClick={() => this.handleClickTestCaseInput()}>
            Test Case Console {testCaseInputVisible ? <UpOutlined style={{fontSize:10}}/> : <DownOutlined style={{fontSize:10}}/>}
          </p>
          <button className={'ide-submit-button'} disabled={this.state.submissionId !== null ? "disable" : null}
                  onClick={() => this.handleRunAndSubmitCode(true)}
          >
            Submit
          </button>
          <button className={'ide-run-code-button'} disabled={this.state.submissionId !== null ? "disable" : null}
                  onClick={() => this.handleRunAndSubmitCode(false)}
          >
            Run Code
          </button>
        </div>
      )
    }
}

class ToolBar extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      const ide = this.props.ide, height = this.props.height;
      return(
        <div className={'ide-tool-bar'} style={{height:height}}>
          <Select value={ide.state.language} style={{ width: 110, fontSize:12 }}
                  onChange={(value) => ide.handleResetLanguage(value)}
          >
            {ide.state.languageSelection}
          </Select>
          <Tooltip placement="top" title={"Editor Settings"}>
            <Button className={'ide-tool-button'} style={{float:'right', right: 40, fontSize:18, fontWeight:800}} onClick={() => ide.handleChangeIdeSettings()}><SettingOutlined /></Button>
          </Tooltip>
          <Tooltip placement="top" title={"Reset to default code definition."}>
            <Button className={'ide-tool-button'} style={{float:'right', right:50, fontSize:18, fontWeight:800}} onClick={() => ide.handleResetCode()}><RedoOutlined /></Button>
          </Tooltip>
        </div>
      )
    }
}

export default IDEComponent;
