import React from 'react';
import '../components/css/home.css'
import {headerHeight} from "../config";
import {Col, Row} from "antd";
import homeBanner from '../components/img/home-banner.png'
import backgroundImg from "../components/img/background.png"
import homeProblemsPict from '../components/img/home-problems.png'
import homeOaPict from '../components/img/home-oa.png'
import homeLadderPict from '../components/img/home-ladder.png'
import { RightOutlined } from '@ant-design/icons';
import homeCyclePict1 from '../components/img/home-cycle-1.png'
import homeCyclePict2 from '../components/img/home-cycle-2.png'
import homeBanner1 from "../components/img/home-banner-1.png"
import homeBanner2 from "../components/img/home-banner-2.png"
import icon1 from "../components/img/home-icon-1.png"
import icon2 from "../components/img/home-icon-2.png"
import icon3 from "../components/img/home-icon-3.png"
import homeIconPict from '../components/img/home-icon.png'
import {frontendPath, getFrontendUrl} from "../config/url";
import {LoginModal} from "../components/header/loginModal";
import {RegisterModal} from "../components/header/registerModal";
import {clearCookie, getJwtPayload, isLoggedIn} from "../config/cookie";
import { UserOutlined } from '@ant-design/icons';


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    // clearCookie();
    this.state = {
      clientHeight: Math.max(document.documentElement.clientHeight, 400),
      showModal: isLoggedIn() ? null : "login"
    };
    const current = this;
    window.onresize = function () {
      current.setState({clientHeight: Math.max(document.documentElement.clientHeight, 400)})
    }
    window.onkeypress = function (e) {
      if(e.keyCode === 13 && current.state.showModal) {
        window.document.getElementById(current.state.showModal + "-button").click();
      }
    }
  }
  render() {
    document.title = "SCode - Shangan Online Judge";
    const height = this.state.clientHeight;
    return(
      <div style={{
        overflow:'hidden', width:'100%', height:"auto", color:'white', letterSpacing:0
      }}>
        <div style={{position:'relative', width:'100%',
          backgroundImage:`url(${backgroundImg})`, backgroundSize:"100% 100%", backgroundRepeat:'no-repeat'}}>
          <div style={{minHeight: headerHeight, padding:"24px 135px"}}>
            <div style={{position:'absolute', left: 135, top: 24, fontSize:"26px", lineHeight:'30px',
              fontFamily:'ArialRoundedMTBold', color:'#FFFFFF', fontWeight:800}}>
              SCode
            </div>
              {
                isLoggedIn() ?
                  <div style={{
                    position:'absolute', right:135, top:20, marginTop:"0.5vw", fontSize:"1vw", width:'auto', padding: "0.2vw 0.8vw 0.3vw",
                    borderRadius:'1.3vw', border:"0.05vw solid white", textAlign:'center'
                  }}
                  >
                    <span style={{fontSize:'1.2vw'}}><UserOutlined/></span>&nbsp;&nbsp;
                    {getJwtPayload('email')}
                  </div>
                  :
                  <div style={{
                    position:'absolute', right:135, top:20, marginTop:"0.5vw", fontSize:"1vw", width:'10vw', padding: "0.2vw 0",
                    borderRadius:'1vw', border:"0.05vw solid white", textAlign:'center'
                  }}
                  >
                    <span className={'home-user-button'}
                          onClick={() => window.open(getFrontendUrl(frontendPath.login), "_self")}>Sign In</span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span className={'home-user-button'}
                          onClick={() => window.open(getFrontendUrl(frontendPath.register), "_self")}>Sign Up</span>
                  </div>
              }
          </div>
          <div style={{padding:"30px 150px"}}>
          <Row>
            <Col span={14}>
              <img src={homeBanner1} style={{width:'70%'}}/>
            </Col>
            <Col span={10}>
              <div style={{fontFamily:'SourceHanSansCN-Bold', fontSize:50, fontWeight:700,  marginTop:20}}>
                Code For Dream
              </div>
              <div style={{fontFamily:'PingFangSC-Regular', fontSize:12, lineHeight:"20px", marginTop:30}}>
                SCode is the FREE and BEST platform to help you prepare for coding interviews. You’ll be practicing FAANG interview questions and get instant feedback to land your dream job.
              </div>
              <div onClick={() => {window.open(
                isLoggedIn() ? getFrontendUrl(frontendPath.problems) : getFrontendUrl(frontendPath.register),
                "_self"
                )}}
                style={{fontFamily:'PingFangSC-Regular', fontSize:14, marginTop:30, height:40, cursor:'pointer',
                background:'#51C2E7', borderRadius:25, width:170, padding:"10px 20px", textAlign:'center'}}>
                {
                  isLoggedIn() ? <span>Get Started</span>: <span>Create Account</span>
                }
                &nbsp;&nbsp;<RightOutlined />
              </div>
            </Col>
          </Row>
        </div>
        </div>

        <div style={{background:'#FFFFFF', padding:"70px 150px"}}>
          <Row>
            <Col span={11}>
              <div style={{display:'flex', margin:'auto', paddingTop:40}}>
                <p style={{color:'#3C3C3C', float:'right', fontFamily:'PingFangSC-Medium',
                  fontSize:21, fontWeight:500, margin:"10px", marginLeft:'auto'}}>
                  Start Exploring
                </p>
                <img src={icon1} style={{width:60}}/>
              </div>
              <div style={{marginTop:20, textAlign:'right', color:'#7A7A7A', fontSize:16, lineHeight:"28px", fontFamily:'PingFangSC-Medium'}}>
                Explore is a well-organized tool that helps you get the most out
                of SCode by providing structure to guide your progress towards
                the next step in your programming career.
              </div>
              <div style={{color:'#5168E7', fontSize:16, fontWeight:500, fontFamily:'PingFangSC-Medium',
                cursor:'pointer', marginTop:20, textAlign:'right'}}
                   onClick={() => window.open(getFrontendUrl(frontendPath.problems), "_self")}
              >
                Get Started <RightOutlined/>
              </div>
            </Col>
            <Col span={13}>
              <img src={homeBanner2} style={{width:'70%', marginLeft:'15%'}}/>
            </Col>
          </Row>
        </div>
        <div style={{background:'#f7f9fc', padding:"30px 150px"}}>
          <Row>
            <Col span={12}>
              <div style={{display:'flex', margin:'auto', paddingTop:40}}>
                <img src={icon2} style={{width:60}}/>
                <p style={{color:'#3C3C3C', fontFamily:'PingFangSC-Medium',
                  fontSize:21, fontWeight:500, margin:"10px"}}>
                  Start Exploring
                </p>
              </div>
              <div style={{marginTop:20, color:'#7A7A7A', fontSize:16, lineHeight:"28px", fontFamily:'PingFangSC-Medium'}}>
                Explore is a well-organized tool that helps you get the most out
                of SCode by providing structure to guide your progress towards
                the next step in your programming career.
              </div>
              <div style={{color:'#5168E7', fontSize:16, fontWeight:500, fontFamily:'PingFangSC-Medium',
                cursor:'pointer', marginTop:20}}
                   onClick={() => window.open(getFrontendUrl(frontendPath.problems), "_self")}
              >
                Get Started <RightOutlined/>
              </div>
            </Col>
            <Col span={12}>
              <div style={{display:'flex', margin:'auto', paddingTop:40}}>
                <img src={icon3} style={{width:60}}/>
                <p style={{color:'#3C3C3C', fontFamily:'PingFangSC-Medium',
                  fontSize:21, fontWeight:500, margin:"10px"}}>
                  Start Exploring
                </p>
              </div>
              <div style={{marginTop:20, color:'#7A7A7A', fontSize:16, lineHeight:"28px", fontFamily:'PingFangSC-Medium'}}>
                Explore is a well-organized tool that helps you get the most out
                of SCode by providing structure to guide your progress towards
                the next step in your programming career.
              </div>
              <div style={{color:'#5168E7', fontSize:16, fontWeight:500, fontFamily:'PingFangSC-Medium',
                cursor:'pointer', marginTop:20}}
                   onClick={() => window.open(getFrontendUrl(frontendPath.problems), "_self")}
              >
                Get Started <RightOutlined/>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{background:'#f7f9fc', padding:"80px 150px 40px 150px", fontFamily:'PingFangSC-Medium', fontSize:21,
          color:'#B6B6B6', textAlign:'center', fontWeight:500}}>
          <p>
            If you are passionate about tackling some of the most interesting problems around,
          </p>
          <p>
            we would love to hear from you.
          </p>
          <p style={{textAlign:'left', marginTop: 120}}>
            Copyright © 2022 Scode
          </p>
        </div>
      </div>
    )
  }
}
export default HomePage;
