import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {message, Input, Button} from "antd";


class AdminUserCreateComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      nickName: ""
    };
  }

  handleCreate() {
    const email = this.state.email, nickName = this.state.nickName;
    axios.post(
      getBackendUrl(backendPath.admin.user.add.path),
      {
        email: email, nickName: nickName
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("Add user successfully.");
        window.setTimeout(function () {window.location.reload();}, 2000);
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  render() {
    return(
      <div style={{padding:"15px 15px 15px 15px", background:'#fff', borderRadius:5, height:230}}>
        <h3 style={{marginBottom:15}}>添加用户</h3>
        <span>邮箱（用户在主站或视频站注册后，在此添加他账户的OJ使用权限）</span>
        <Input onChange={(value) => this.setState({email: value.target.value})}/><br/>
        <span>昵称（姓名、微信名等学生称呼）</span>
        <Input onChange={(value) => this.setState({nickName: value.target.value})}/><br/>
        <Button style={{float:'right', marginTop:20}} onClick={this.handleCreate.bind(this)}>ADD</Button>
      </div>
    )
  }
}
export default AdminUserCreateComponent;
