import React from "react";
import {BigPlayButton, ControlBar, PlaybackRateMenuButton, Player, VolumeMenuButton} from "video-react";
import {frontendUrlBase} from "../../../config/url";
import "../../../../node_modules/video-react/dist/video-react.css"


class VideoComponent extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <Player playsInline poster={frontendUrlBase + "/video_cover.png"} style={{width:'100%'}}
                                        src={this.props.video} startTime={2}>
                                  <BigPlayButton position="center" />
                                  <ControlBar>
                                    <PlaybackRateMenuButton rates={[1.0, 1.25, 1.5, 2.0]} />
                                    <VolumeMenuButton vertical/>
                                  </ControlBar>
                                </Player>
      )
    }
}

export default VideoComponent;
