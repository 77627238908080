import React from 'react';

import { DoubleRightOutlined } from '@ant-design/icons';
import Input from "antd/lib/input";

const {TextArea} = Input;


export function judgeResultContext(result) {
  if(result === -2) {
    return {'color': '#5CB85C', 'text': "Submit Successfully"};
  }
  const judgeResult = [
    {'color': '#CE1126', 'text': "Wrong Answer"},
    {'color': '#5CB85C', 'text': "Accepted"},
    {'color': '#CE1126', 'text': "Time Limit Exceeded"},
    {'color': '#CE1126', 'text': "Time Limit Exceeded"},
    {'color': '#CE1126', 'text': "Memory Limit Exceeded"},
    {'color': '#CE1126', 'text': "Runtime Error"},
    {'color': '#CE1126', 'text': "System Error"},
    {'color': '#CE1126', 'text': "Compile Error"},
    {'color': '#5CB85C', 'text': "No Compile Error"}
  ];
  return judgeResult[result + 1];
}


class TestCaseMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unfold: false
    }
  }
  render() {
    let unfold = this.state.unfold;
    const id = this.props.id;
    const title = this.props.title, content = this.props.content;
    // content.find
    let component = <TextArea bordered={true} rows={content.length < 200 ? 3 : 5} value={content}
                              style={{height: '100%', backgroundImage:"linear-gradient(#FDFDFF, #FDFDFF)"}}/>;
    if(this.props.backgroundImage !== undefined) {
      component =
        <div className={"test-case-value-outer"} style={{backgroundImage: this.props.backgroundImage}}>
          <div id={id} className={"test-case-value-container"} onClick={() => this.setState({unfold: !unfold})}>
            {(this.state.unfold || content.length <= 1000) ? content : content.substr(0, 1000) + "  . . .  MORE "}
            {(this.state.unfold || content.length <= 1000) ? <></> : <DoubleRightOutlined />}
          </div>
        </div>;
    }
    return (
      <div>
        <p style={{marginBottom:3}}><b>{title}</b></p>
        {component}
      </div>
    )
  }
}


export function consoleShowResult(data, isSubmitSuccessfullyShown) {
  const content = [];
  const result = data.result;
  let input = data.input || "", output = data.output || "", stdout = data.stdout || "",
    standardOutput = data.standard_output || "", judgeType = data.judge_type;
  if (output !== undefined && output !== null) {
    output = output.replace(new RegExp("/judger/run/[0-9a-z]+/Main.java:", "g"), "line ");
    output = output.replace(new RegExp("File \"/judger/run/[0-9a-z]+/solution.py\",", "g"), "");
  }
  let resultContext = judgeResultContext(result);
  if(judgeType === "run_code") {
    resultContext.text = "Run Code " + resultContext.text;
  } else if(isSubmitSuccessfullyShown) {
    resultContext.text = "Submit Successfully " + (resultContext.color === '#5CB85C' ? 'and ': 'but ') + resultContext.text;
  }
  content.push(
    <div style={{marginBottom: 10, height: 21}}>
      <div style={{width: '97%', float: 'left', fontWeight: 700, color: resultContext.color, fontSize: 16}}>
        {resultContext.text}
      </div>
    </div>
  );
  if (result === 0) {
    content.push(<p>Time Spent: <b style={{fontSize: 17}}>{data.cpu_time}ms</b></p>);
    content.push(<p>Memory Spent: <b style={{fontSize: 17}}>{Math.ceil(data.memory / (1024 * 1024))} MB</b></p>);
    if (standardOutput) {
      content.push(<TestCaseMessage id={"test-case-output-message"} title={"your output"} content={standardOutput}/>);
      content.push(<TestCaseMessage id={"test-case-output-message"} title={"standard output"}
                                    content={standardOutput}/>);
    }
    if (stdout) {
      content.push(<TestCaseMessage id={"test-case-output-message"} title={"stdout"} content={stdout}/>);
    }
  } else if(result === 7) {
    if(input) {
      content.push(<TestCaseMessage id={"test-case-input-message"} title={"input"} content={input}/>);
    }
    if(output) {
      content.push(<TestCaseMessage id={"test-case-output-message"} title={"your output"} content={output}/>);
    }
    if (standardOutput) {
      content.push(<TestCaseMessage id={"test-case-standard-output-message"} title={"standard output"}
                                    content={standardOutput}/>);
    }
    if (stdout) {
      content.push(<TestCaseMessage id={"test-case-stdout-message"} title={"stdout"} content={stdout}/>);
    }
  } else if (result === -1) {
      if(input) {content.push(<TestCaseMessage id={"test-case-input-message"} title={"input"} content={input}/>);}
      if(output) {content.push(<TestCaseMessage id={"test-case-output-message"} title={"your output"} content={output}/>);}
    if (standardOutput && standardOutput !== '#') {
      content.push(<TestCaseMessage id={"test-case-standard-output-message"} title={"standard output"}
                                    content={standardOutput}/>);
    }
    if (stdout) {content.push(<TestCaseMessage id={"test-case-stdout-message"} title={"stdout"} content={stdout}/>);}
  } else if (result === 6) {
    content.push(<TestCaseMessage backgroundImage={"linear-gradient(rgb(252, 235, 241), rgb(254, 243, 246))"}
                                  id={"test-case-output-message"} title={""} content={output}/>);
  } else {
    content.push(<TestCaseMessage id={"test-case-input-message"} title={"input"} content={input}/>);
    if (stdout.toString().length > 0) {
      content.push(<TestCaseMessage id={"test-case-stdout-message"} title={"stdout"} content={stdout}/>);
    }
    content.push(<TestCaseMessage backgroundImage={"linear-gradient(rgb(252, 235, 241), rgb(254, 243, 246))"}
                                  id={"test-case-output-message"} title={""} content={output}/>);
  }
  return content;
}
