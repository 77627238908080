import React from 'react';
import {message, Skeleton, Carousel, Input} from 'antd';
import { LoadingOutlined, RightOutlined, LeftOutlined, SearchOutlined } from '@ant-design/icons';
import axios from "axios";
import { Tag, Switch } from 'antd';

import {backendPath, getBackendUrl} from "../../../config/url";
import {fuzzyMatch, problemsComponent} from "./problems";


class CompanySelectorComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allCompanies: [],
      selectedCompany: null,
      loadingProblems: false,
      searchKey: ""
    };
    this.handleSelect = this.handleSelect.bind(this);
    const current = this;
    axios.get(
      getBackendUrl(backendPath.company.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({allCompanies: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    });
  }

  handleSelect(shortTitle) {
    if(this.state.loadingProblems) return ;
    if(this.state.selectedCompany === shortTitle) {
      this.setState({selectedCompany: null});
      problemsComponent.setState({showProblemIds: undefined});
    } else {
      const current = this;
      let companyId = "1";
      this.state.allCompanies.forEach(function (company, index, companies) {
        if(company.shortTitle === shortTitle) companyId = company.id;
      });
      axios.get(
        getBackendUrl(backendPath.company.retrieve.path + companyId)
      ).then(function (response) {
        if(response.data.status === 1) {
          let problems = response.data.result.problems;
          problems.sort(function (p1, p2) {
            if(p1.period === p2.period) return p2.frequency - p1.frequency;
            return p1.period - p2.period;
          });
          const showProblemIds = [];
          problems.forEach(function (p) {
            showProblemIds.push(p.problemId);
          });
          problemsComponent.setState({showProblemIds: showProblemIds})
        } else {
          message.warning(response.data.message)
        }
        current.setState({loadingProblems: false})
      });
      this.setState({selectedCompany: shortTitle, loadingProblems: true})
    }
  }

  render() {
    const companyCarousel = [], companies = [], pageSize = 30;
    for(let i = 0; i < this.state.allCompanies.length; i ++) {
      if(fuzzyMatch(this.state.allCompanies[i].title, this.state.searchKey)) {
        companies.push(this.state.allCompanies[i]);
      }
    }
    for(let page = 0; page * pageSize < companies.length; page ++) {
      const companyPage = [];
      for(let delta = 0; delta < pageSize && page * pageSize + delta < companies.length; delta ++) {
        const company = companies[page * pageSize + delta];
        companyPage.push(
          <Tag
            style={{
              cursor:'pointer', margin:5, borderRadius:11, background:'#F2F2F4', height:22,
              color:'#595959', fontFamily:'PingFangSC-Medium', fontSize:12, fontWeight:500
            }}
            color={this.state.selectedCompany === company.shortTitle ? 'processing': 'default'}
            key={company.shortTitle}
            id={company.shortTitle}
            onClick={(e) => this.handleSelect(e.target.id)}
          >
            {company.title}&nbsp;
            {
              this.state.loadingProblems && this.state.selectedCompany === company.shortTitle ?
                <><LoadingOutlined/></>:
                <span style={{
                  background:'#FFA115', borderRadius:8, height:17, margin:"2px 0 0 0",
                  color:'white', fontFamily:'PingFangSC-Regular', fontSize:12, fontWeight:400
                }}>
                  &nbsp;&nbsp;{company.total}&nbsp;&nbsp;
                </span>
            }
          </Tag>
        )
      }
      companyCarousel.push(<div>{companyPage}</div>)
    }
    return (
      <div style={{padding:20, marginLeft:20, background:'#F8FAFD', borderRadius:5}}>
        <p style={{marginBottom:10, marginLeft:5, fontSize:16, fontFamily:"PingFangSC-Regular"}}>
          Companies
          <div className={'icon-button'} onClick={() => this.carousel.next()}><RightOutlined /></div>
          <div className={'icon-button'} onClick={() => this.carousel.prev()}><LeftOutlined /></div>
        </p>

        <Input onChange={(e) => this.setState({searchKey: e.target.value})}
                 placeholder={"search companies"}
               style={{margin:"5px 0 15px 0"}}
                 prefix={<SearchOutlined style={{opacity:0.5, fontWeight:600}}/>}
                 className={'title-selector'}/>

        {
          this.state.allCompanies.length === 0 ? <Skeleton active paragraph={{ rows: 4 }} />:
            <Carousel ref={dom => {this.carousel = dom;}} effect="fade" dots={false}>
              {companyCarousel}
            </Carousel>
        }

        {/*{this.state.allCompanies.map(company => (*/}
          {/*<Tag*/}
            {/*style={{cursor:'pointer', margin:5}}*/}
            {/*color={this.state.selectedCompany === company.shortTitle ? 'processing': 'default'}*/}
            {/*key={company.shortTitle}*/}
            {/*id={company.shortTitle}*/}
            {/*onClick={(e) => this.handleSelect(e.target.id)}*/}
          {/*>*/}
            {/*{*/}
              {/*this.state.loadingProblems && this.state.selectedCompany === company.shortTitle ?*/}
                {/*<>{company.title}&nbsp;<LoadingOutlined/></>: company.title*/}
            {/*}*/}
          {/*</Tag>*/}
        {/*))}*/}
      </div>

    )
  }
}

export default CompanySelectorComponent;

