import React from 'react';
import './index.css';
import {BrowserRouter, Switch, Redirect, Route} from "react-router-dom";
import LoginPage from "./pages/login";
import ProblemsPage from "./pages/problems";
import ProblemPage from "./pages/problem";
import AdminProblemsPage from "./pages/admin/problems";
import AdminProblemPage from "./pages/admin/problem";
import AdminUsersPage from "./pages/admin/users";
import TeacherUsersPage from "./pages/teacher/users";
import TeacherCheckPage from "./pages/teacher/check";
import AdminLadderPage from "./pages/admin/ladder";
import LadderPage from "./pages/ladder";
import LaddersPage from "./pages/ladders";
import BanPage from "./pages/ban";
import HomePage from "./pages/home";
import AccountPage from "./pages/account";
import AdminPermissionPage from "./pages/admin/permission";
import {frontendUrlBase} from "./config/url";
import AdminLaddersPage from "./pages/admin/ladders";
import QuizzesPage from "./pages/quizzes";
import QuizPage from "./pages/quiz";
import QuizProblemPage from "./pages/quizProblem";
import TeacherQuizScorePage from "./pages/teacher/quizScore";
import TeacherQuizzesConfigPage from "./pages/teacher/quizzesConfig";
import TeacherQuizCheckPage from "./pages/teacher/quizCheck";
import RegisterPage from "./pages/register";
import ForgetPasswordPage from "./pages/forgetPassword";

class Router extends React.Component {
  constructor(props) {
    super(props);
    const href = window.location.href;
    if(!href.startsWith(frontendUrlBase)) {
      window.open(frontendUrlBase, "_self")
    }
  }
  render() {
    return(
      <BrowserRouter>
        <Switch>
          <Route
            path={"/"}
            render={() => (
              <Switch>
                <Route path={"/home"} component={HomePage}/>
                <Route path={"/login"} component={LoginPage}/>
                <Route path={"/register"} component={RegisterPage}/>
                <Route path={"/forget-password"} component={ForgetPasswordPage}/>
                <Route path={"/ban"} component={BanPage}/>
                <Route path={"/account"} component={AccountPage}/>
                <Route path={"/problems"} component={ProblemsPage}/>
                <Route path={"/problem/:problem_short_title"} component={ProblemPage}/>
                <Route path={"/ladder/:ladder_id"} component={LadderPage}/>
                <Route path={"/ladders"} component={LaddersPage}/>
                <Route path={"/exams"} component={QuizzesPage}/>
                <Route path={"/exam/:quiz_pwd"} component={QuizPage}/>
                <Route path={"/exam-problem/:quiz_problem_id"} component={QuizProblemPage}/>

                <Route path={"/admin/users"} component={AdminUsersPage}/>
                <Route path={"/admin/problems"} component={AdminProblemsPage}/>
                <Route path={"/admin/problem/:problem_short_title"} component={AdminProblemPage}/>
                <Route path={"/admin/ladder/:ladder_id"} component={AdminLadderPage}/>
                <Route path={"/admin/ladders"} component={AdminLaddersPage}/>
                <Route path={"/admin/permission"} component={AdminPermissionPage}/>

                <Route path={"/teacher/check"} component={TeacherCheckPage}/>
                <Route path={"/teacher/students"} component={TeacherUsersPage}/>
                <Route path={"/teacher/exams-config"} component={TeacherQuizzesConfigPage}/>
                <Route path={"/teacher/exam-score/:pwd"} component={TeacherQuizScorePage}/>
                <Route path={"/teacher/exam-check/:quiz_id"} component={TeacherQuizCheckPage}/>

                <Route render={() => <Redirect to={"/home"}/>}/>
              </Switch>
            )}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Router;

