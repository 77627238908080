import React from 'react';
import {message, Table} from 'antd';
import '../../css/problems.css';
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";


class AdminPermissionListComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      permissions: []
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.permission.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({permissions: response.data.result});
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }


  render() {
    const permissions = this.state.permissions;
    const columns = [
      {
        title: '#',
        dataIndex: 'id',
        width:'10%',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'title',
        dataIndex: 'title',
        width:'30%'
      },
      {
        title: 'detail',
        dataIndex: 'detail',
        width:'60%'
      }
    ];

    return (
      <>
        <h3 style={{marginBottom:15, marginLeft: 10}}>权限列表</h3>
        <Table columns={columns} dataSource={permissions} size={'small'} pagination={{size:"small", defaultPageSize: 50}}/>
      </>
    )
  }
}

export default AdminPermissionListComponent;

