import React from 'react';
import {message, Table, Button, Input, Tag } from 'antd';
import axios from "axios";
import {Role} from "../../../config/roles";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";
import {checkRoles} from "../../../config/roles";
import {getUserAttribute} from "../../../config/cookie";


class QuizzesComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      quizzes: null,
      pwd: ""
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.quiz.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({quizzes: response.data.result, responseTime: response.data.responseTime})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleEnter(pwd) {
    if(pwd.length !== 6) {
      message.warn("Input valid password.");
      return;
    }
    axios.post(
      getBackendUrl(backendPath.quiz.participate.path),{
        pwd: pwd
      }
    ).then( function (response) {
      if(response.data.status === 1) {
        window.open(getFrontendUrl(frontendPath.quiz + pwd), "_self")
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const quizzes = this.state.quizzes, data = [];
    if(quizzes === null) {
      return(
        <div className={'loading'} style={{marginBottom:"10em"}}></div>
      )
    }
    for(let i = 0; i < quizzes.length; i ++) {
      let quiz = JSON.parse(JSON.stringify(quizzes[i]));
      quiz.duration = Math.ceil((quiz.endTime - quiz.startTime) / 60000);
      let currentTime = this.state.responseTime ? this.state.responseTime : new Date().getTime();
      if(currentTime < quiz.startTime) {
        quiz.status = <Tag color="gold">Pending</Tag>;
      } else if(currentTime < quiz.endTime) {
          quiz.status = <Tag color="green">In Process</Tag>;
      } else {
        quiz.status = <Tag color="red">Completed</Tag>;
        quiz.teacherCheck = <h3 style={{cursor:'pointer', color:'#5cb85c', fontSize: 18, fontWeight: 600}}
                                      onClick={() => {
                                        window.open(getFrontendUrl(frontendPath.teacher.quizCheck + quiz.id))
                                      }}>Check</h3>
      }
      quiz.startTime = new Date(quiz.startTime).toLocaleString();
      quiz.op = "-";
      if(quiz.pwd) {
        quiz.op = <h3 style={{cursor:'pointer', color:'#5cb85c', fontSize: 18, fontWeight: 600}}
                                      onClick={() => {this.handleEnter(quiz.pwd)}}>Enter</h3>
      }
      data.push(quiz);
    }
    const columns = [
      {title: 'Exam', dataIndex: 'title'},
      {title: 'Start Time', dataIndex: 'startTime'},
      {title: 'Duration (min)', dataIndex: 'duration'},
      {title: 'Status', dataIndex: 'status'},
      {title: 'Enter', dataIndex: 'op'}
    ];
    if(checkRoles(getUserAttribute('role'), [Role.teacher])) {
      columns.push({title: 'Check', dataIndex: 'teacherCheck'})
    }
    const current = this;
    return (
     <>
       <div style={{marginBottom:30}}>
         <h3>Join Exam By Password</h3>
         <Input value={this.state.pwd} onChange={(e) => {current.setState({pwd: e.target.value})}}
                style={{width:200, display:'inline', marginRight: 30}}/>
         <Button onClick={() => this.handleEnter(this.state.pwd)}>Enter</Button>
       </div>
       <h3>Exams</h3>
       <Table columns={columns} dataSource={data} size={'small'} pagination={{size:"small", pageSize: 10}}/>

     </>
    )
  }
}

export default QuizzesComponent;
