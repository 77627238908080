export const languagesConfig = [
  {value: "java", label: "Java"},
  {value: "python3", label: "Python3"},
  {value: "python", label: "Python"},
  {value: "c_cpp", label: "C++"},
  {value: "go", label: "Golang"},
  {value: "js", label: "Javascript"},
  {value: "sql", label: "SQLite"},
  {value: "mysql", label: "MySQL"},
  {value: "oracle", label: "Oracle"},
  {value: "ms_sql_server", label: "MS SQL Server"},
];

export function languageIndex(language) {
  for(let i = 0; i < languagesConfig.length; i ++) {
    if(languagesConfig[i].value === language) return i;
  }
  return -1;
}

const variable = {
  java: {
    'void': ['void', "null", "null"],
    'double': ['double', 'IoDouble.read(in)', 'IoDouble.write(ans)'],
    'int': ['int', 'IoInt.read(in)', 'IoInt.write(ans)'],
    'boolean': ['boolean', 'IoBoolean.read(in)', 'IoBoolean.write(ans)'],
    'TreeNode': ['TreeNode', 'IoTree.read(in)', 'IoTree.write(ans)'],
    'ListNode': ['ListNode', 'IoLinkedList.read(in)', 'IoLinkedList.write(ans)'],
    'String': ['String', 'IoString.read(in)', 'IoString.write(ans)'],
    'boolean[]': ['boolean[]', 'OneDimBooleanArray.read(in)', 'OneDimBooleanArray.write(ans)'],
    'char[]': ['char[]', 'OneDimCharArray.read(in)', 'OneDimCharArray.write(ans)'],
    'double[]': ['double[]', 'OneDimDoubleArray.read(in)', 'OneDimDoubleArray.write(ans)'],
    'int[]': ['int[]', 'OneDimIntArray.read(in)', 'OneDimIntArray.write(ans)'],
    'String[]': ['String[]', 'OneDimStringArray.read(in)', 'OneDimStringArray.write(ans)'],
    'List<Double>': ['List<Double>', 'OneDimDoubleList.read(in)', 'OneDimDoubleList.write(ans)'],
    'List<Integer>': ['List<Integer>', 'OneDimIntegerList.read(in)', 'OneDimIntegerList.write(ans)'],
    'List<String>': ['List<String>', 'OneDimStringList.read(in)', 'OneDimStringList.write(ans)'],
    'char[][]': ['char[][]', 'TwoDimCharArray.read(in)', 'TwoDimCharArray.write(ans)'],
    'double[][]': ['double[][]', 'TwoDimDoubleArray.read(in)', 'TwoDimDoubleArray.write(ans)'],
    'int[][]': ['int[][]', 'TwoDimIntArray.read(in)', 'TwoDimIntArray.write(ans)'],
    'String[][]': ['String[][]', 'TwoDimStringArray.read(in)', 'TwoDimStringArray.write(ans)'],
    'List<List<Integer>>': ['List<List<Integer>>', 'TwoDimIntegerList.read(in)', 'TwoDimIntegerList.write(ans)'],
    'List<List<String>>': ['List<List<String>>', 'TwoDimStringList.read(in)', 'TwoDimStringList.write(ans)']
  },
  python: {
    'void': ['None', "None", "None"],
    'double': ['float', 'IoDouble.read()', 'IoDouble.write(ans)'],
    'int': ['int', 'IoInt.read()', 'IoInt.write(ans)'],
    'boolean': ['bool', 'IoBoolean.read()', 'IoBoolean.write(ans)'],
    'TreeNode': ['TreeNode', 'IoTree.read()', 'IoTree.write(ans)'],
    'ListNode': ['ListNode', 'IoLinkedList.read()', 'IoLinkedList.write(ans)'],
    'String': ['str', 'IoString.read()', 'IoString.write(ans)'],
    'boolean[]': ['List[bool]', 'OneDimBoolean.read()', 'OneDimBoolean.write(ans)'],
    'char[]': ['List[str]', 'OneDimChar.read()', 'OneDimChar.write(ans)'],
    'double[]': ['List[float]', 'OneDimDouble.read()', 'OneDimDouble.write(ans)'],
    'int[]': ['List[int]', 'OneDimInt.read()', 'OneDimInt.write(ans)'],
    'String[]': ['List[str]', 'OneDimString.read()', 'OneDimString.write(ans)'],
    'List<Double>': ['List[float]', 'OneDimDouble.read()', 'OneDimDouble.write(ans)'],
    'List<Integer>': ['List[int]', 'OneDimInt.read()', 'OneDimInt.write(ans)'],
    'List<String>': ['List[str]', 'OneDimString.read()', 'OneDimString.write(ans)'],
    'char[][]': ['List[List[str]]', 'TwoDimChar.read()', 'TwoDimChar.write(ans)'],
    'double[][]': ['List[List[float]]', 'TwoDimDouble.read()', 'TwoDimDouble.write(ans)'],
    'int[][]': ['List[List[int]]', 'TwoDimInt.read()', 'TwoDimInt.write(ans)'],
    'String[][]': ['List[List[str]]', 'TwoDimString.read()', 'TwoDimString.write(ans)'],
    'List<List<Integer>>': ['List[List[int]]', 'TwoDimInt.read()', 'TwoDimInt.write(ans)'],
    'List<List<String>>': ['List[List[str]]', 'TwoDimString.read()', 'TwoDimString.write(ans)']
  }
};

const originalCode = {
  java: {
    defTreeNode: "// defination of TreeNode\n" +
      "// class TreeNode {\n" +
      "//     public int val;\n" +
      "//     public TreeNode left;\n" +
      "//     public TreeNode right;\n" +
      "//     public TreeNode(int v) {\n" +
      "//         val = v; left = null; right = null;\n" +
      "//     }\n" +
      "// }",
    defListNode: "// defination of ListNode\n" +
      "// class ListNode {\n" +
      "//     public int val;\n" +
      "//     public ListNode next;\n" +
      "//     public ListNode(int val) {\n" +
      "//         this.val = val;\n" +
      "//         this.next = null;\n" +
      "//     }\n" +
      "// }",
    initCode: "import java.util.*;\n" +
      "import shangan.utils.*;\n" +
      "DEFINATION\n" +
      "class Solution {\n" +
      "    public RETURN_TYPE FUNCTION_NAME(PARAMS) {\n" +
      "        \n" +
      "    }\n" +
      "}",
    mainCode: "public class Main{\n" +
      "    public static void main(String[] args){\n" +
      "        Scanner in=new Scanner(System.in);\n" +
      "        PARAMS_DECLARATION\n" +
      "        try {\n" +
      "            INPUT\n" +
      "        } catch (Exception e) {\n" +
      "            System.err.println(\"Invalid Test Case Input.\");\n" +
      "            return ;\n" +
      "        }\n" +
      "        RETURN_TYPE ans = new Solution().FUNCTION_NAME(PARAMS);\n" +
      "        try {\n" +
      "            OUTPUT\n" +
      "        } catch (Exception e) {\n" +
      "            System.err.println(\"Invalid Output.\");\n" +
      "        }\n" +
      "        in.close();\n" +
      "    }\n" +
      "}"
  },
  python: {
    defTreeNode: "from shangan import TreeNode\n" +
      "# defination of TreeNode\n" +
      "# class TreeNode:\n" +
      "#     def __init__(self, val=0):\n" +
      "#         self.val = val\n" +
      "#         self.left, self.right = None, None",
    defListNode: "from shangan import ListNode\n" +
      "# defination of ListNode\n" +
      "# class ListNode:\n" +
      "#     def __init__(self, val=0):\n" +
      "#         self.val = val\n" +
      "#         self.next = None",
    initCode: "DEFINATION" +
      "class Solution:\n" +
      "    def FUNCTION_NAME(self, PARAMS) -> RETURN_TYPE:\n" +
      "        ",
    mainCode: "from shangan import IO_HELPER_LIST\n" +
      "import sys\n" +
      "\n" +
      "\n" +
      "try:\n" +
      "    INPUT\n" +
      "except:\n" +
      "    sys.stderr.write(\"Invalid Test Case Input.\")\n" +
      "    exit(0)\n" +
      "ans = Solution().FUNCTION_NAME(PARAMS)\n" +
      "try:\n" +
      "    OUTPUT\n" +
      "except:\n" +
      "    sys.stderr.write(\"Invalid Output.\")\n" +
      "    exit(0)"
  }
};

export function generateCode(interfaceCode, language) {
  interfaceCode = interfaceCode.replace(/^\s+|\s+$/g, '');
  const exp = /([^\s]+)\s+([^\s]+)\s*\((.+)\)/g;
  const res = exp.exec(interfaceCode);
  if(res === null) return null;
  const returnType = res[1], functionName = res[2], paramsString = res[3].split(",");
  const params = [];
  for(let i = 0; i < paramsString.length; i ++) {
    let param = paramsString[i].replace(/^\s+|\s+$/g, '');
    const tmp = /^([^\s]+)\s+([^\s]+)$/g.exec(param);
    if(tmp === null) break;
    params.push({type: tmp[1], name: tmp[2]})
  }
  language = language === 'python3' ? 'python': language;
  return {
    initCode: generateInitCode(returnType, functionName, params, language),
    solution: generateInitCode(returnType, functionName, params, language),
    mainCode: generateMainCode(returnType, functionName, params, language)
  }
}

function generateInitCode(returnType, functionName, params, language) {
  let oriCode = originalCode[language], vari = variable[language];
  let initCode = oriCode.initCode;
  try {
    initCode = initCode.replace("RETURN_TYPE", vari[returnType][0]);
    initCode = initCode.replace("FUNCTION_NAME", functionName);
    let defination = [];
    let hasTreeNode = vari[returnType][0] === 'TreeNode';
    let hasListNode = vari[returnType][0] === 'ListNode';
    for(let i = 0; i < params.length; i ++) {
      if(vari[params[i].type][0] === 'TreeNode') hasTreeNode = true;
      if(vari[params[i].type][0] === 'ListNode') hasListNode = true;
    }
    if(hasListNode) defination.push(oriCode.defListNode);
    if(hasTreeNode) defination.push(oriCode.defTreeNode);
    if(language === 'java') {
      let paramList = [];
      for(let i = 0; i < params.length; i ++) {
        paramList.push(vari[params[i].type][0] + " " + params[i].name);
      }
      initCode = initCode.replace("PARAMS", paramList.join(", "));
    }
    if(language === 'python') {
      let paramList = [], hasList = vari[returnType][0].indexOf('List') !== -1;;
      for(let i = 0; i < params.length; i ++) {
        if(vari[params[i].type][0].indexOf('List[') !== -1) hasList = true;
        paramList.push(params[i].name + ": " + vari[params[i].type][0]);
      }
      if(hasList) defination = ['from typing import List'].concat(defination);
      initCode = initCode.replace("PARAMS", paramList.join(", "));
    }
    initCode = initCode.replace("DEFINATION", defination.length === 0 ? "" : defination.join("\n") + "\n");
  } catch (e) {
    alert("可能变量类别不存在，可以手动输入");
  }
  return initCode;
}

function generateMainCode(returnType, functionName, params, language) {
  let oriCode = originalCode[language], vari = variable[language];
  let mainCode = oriCode.mainCode;
  try {
    if(language === 'java') {
      let paramDeclarationList = [], paramList = [], inputList = []
      for(let i = 0; i < params.length; i ++) {
        paramDeclarationList.push(vari[params[i].type][0] + " " + params[i].name + ";");
        paramList.push(params[i].name);
        inputList.push(params[i].name + " = " + vari[params[i].type][1] + ";");
      }
      mainCode = mainCode.replace("PARAMS_DECLARATION", paramDeclarationList.join("\n\t\t"));
      mainCode = mainCode.replace("INPUT", inputList.join("\n\t\t\t"));
      mainCode = mainCode.replace("RETURN_TYPE", vari[returnType][0]);
      mainCode = mainCode.replace("PARAMS", paramList.join(", "));
      mainCode = mainCode.replace("FUNCTION_NAME", functionName);
      mainCode = mainCode.replace("OUTPUT", vari[returnType][2] + ";");
    }
    if(language === 'python') {
      let paramList = [], inputList = [], IoHelperList = [vari[returnType][2].split('.')[0]];
      for(let i = 0; i < params.length; i ++) {
        paramList.push(params[i].name);
        inputList.push(vari[params[i].type][1]);
        if(IoHelperList.indexOf(vari[params[i].type][1].split('.')[0]) === -1) {
          IoHelperList.push(vari[params[i].type][1].split('.')[0]);
        }
      }
      mainCode = mainCode.replace("INPUT", paramList.join(", ") + " = " + inputList.join(", "));
      mainCode = mainCode.replace("PARAMS", paramList.join(", "));
      mainCode = mainCode.replace("FUNCTION_NAME", functionName);
      mainCode = mainCode.replace("OUTPUT", vari[returnType][2] );
      mainCode = mainCode.replace("IO_HELPER_LIST", IoHelperList.join(", "));
    }
  } catch (e) {
    alert("可能变量类别不存在，可以手动输入");
  }

  return mainCode;
}
