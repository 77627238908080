import React from 'react';
import {message, Skeleton } from 'antd';
import axios from "axios";
import { Tag, Switch } from 'antd';

import {backendPath, getBackendUrl} from "../../../config/url";
import {problemsComponent} from "./problems";


class TagSelectorComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allTags: [],
      selectedTags: [],
      showTags: localStorage.getItem("show-tags")
    };
    this.handleChange = this.handleChange.bind(this);
    const current = this;
    axios.get(
      getBackendUrl(backendPath.tag.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({allTags: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    });
  }

  handleChange(tag) {
    const selectedTags  = this.state.selectedTags;
    const nextSelectedTags = selectedTags.indexOf(tag) > -1 ? selectedTags.filter(t => t !== tag) : [...selectedTags, tag];
    this.setState({ selectedTags: nextSelectedTags });
    try {problemsComponent.changeSelectedTags(nextSelectedTags);} catch (e) {console.log(e)}
  }

  render() {
    const current = this;
    return (
      <div className={'div-box-shadow'} style={{padding:20, background:'#F8FAFD', borderRadius:5, width:600}}>
        <p style={{marginBottom:10, marginLeft:5, fontSize:16, fontFamily:"PingFangSC-Regular"}}>
          Algorithm Tags
          <Switch checkedChildren=" SHOW " unCheckedChildren="HIDDEN" style={{float:'right'}}
                checked={this.state.showTags}
                onChange={(e) => {
                  current.setState({showTags: e});
                  if(e) {
                    localStorage.setItem('show-tags', 'show')
                  } else {
                    localStorage.removeItem('show-tags')
                  }
                  try {problemsComponent.changeShowTags(e);} catch (e) {}
                }}
        />
        </p>
        {
          this.state.allTags.length === 0 ? <Skeleton active paragraph={{ rows: 4 }} />:
            this.state.allTags.map(tag => (
              <Tag
                style={{cursor:'pointer', margin:5}}
                color={this.state.selectedTags.indexOf(tag) > -1 ? 'processing': 'default'}
                key={tag}
                id={tag}
                onClick={(e) => this.handleChange(e.target.id)}
              >
                {tag}
              </Tag>
            ))
        }
      </div>

    )
  }
}

export default TagSelectorComponent;

