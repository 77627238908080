import React from 'react';
import HeaderComponent from "../../components/header/header";
import AdminLadderComponent from "../../components/admin/ladder/adminLadder";


class AdminLadderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientHeight: Math.max(document.documentElement.clientHeight, 600)
    };
    const current = this;
    window.onresize = function () {
      current.setState({clientHeight: Math.max(document.documentElement.clientHeight, 600)})
    }
  }
  render() {
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30, height:this.state.clientHeight - 80}}>
          <AdminLadderComponent ladderID={this.props.match.params.ladder_id}/>
        </div>
      </div>
    )
  }
}
export default AdminLadderPage;
