import React from 'react';

import message from 'antd/lib/message'
import 'antd/lib/message/style'
import { LoadingOutlined } from "@ant-design/icons"
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import {setCookie} from "../../config/cookie";
import JSEncrypt from 'jsencrypt'
import {frontendPath, getFrontendUrl} from "../../config/url";


function encrypt(txt)
{
  const PUBLIC_KEY = "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2zUKMVg975Y1y/ADQm0Q8/P8OejCvIzyFQtvQoIZlFgXM9Kd2h0RI/HattyOBy1dbuesGOqHr7axmyaElozaNGHMh977RGs1jJRUueAeY9Hi/1FydW8vcaj4UoLfpzkf3NwL58V1x9yfysr2lhDWqn1LVZKjFLw/aBaQft4l5Atcp1jXdgxnsChD9CWz5VqD7xKKkNNAZA60gQrbOajNBpGtw+11lvruE2x06Omw6j/SygOWmjRaaBqk35tIvb3RH3LQ9LcDJ+Sm8USsmNeKmJ3+QZgXpAtK5tU6d85+EYFBo5WlOu6VhksljaBRhYJh9t7DdeRyl6yZJJd1ehcbkwIDAQAB\n-----END PUBLIC KEY-----";
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  return encodeURI(encrypt.encrypt(txt));
}

export class RegisterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cooldown: 0,
      processing: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleCancel() {
    this.props.parent.setState({showModal: null});
  }
  handleRegister(usernameElementId, emailElementId, password1ElementId, password2ElementId, verifycodeElementId) {
    if(this.state.processing) {
      message.warning("Processing, wait a moment.");
      return ;
    }
    const email = document.getElementById(emailElementId).value;
    const password1 = document.getElementById(password1ElementId).value;
    const verifycode = document.getElementById(verifycodeElementId).value;
    let cur = this;
    this.setState({processing: true});
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(
      "POST", "https://learn.shanganonline.com/api/user/", true
    );
    let fd = new FormData();
    fd.append("email", email);
    fd.append("password", encrypt(password1));
    fd.append('code', verifycode);
    httpRequest.send(fd);
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          if(httpRequest.status === 200) {
            const obj = JSON.parse(httpRequest.responseText);
            setCookie('token', obj['token']);
            message.success("Sign on successfully.");
            cur.props.parent.setState({showModal: null});
            setInterval(function () {
              window.open(getFrontendUrl(frontendPath.problems), "_self")
            }, 1000);
          } else {
            const obj = JSON.parse(httpRequest.responseText);
            if(obj.hasOwnProperty('email')) {
              message.warning(obj['email']);
            }else if(obj.hasOwnProperty('username')) {
              message.warning(obj['username']);
            }else if(obj.hasOwnProperty('password')) {
              message.warning(obj['password']);
            }else if(obj.hasOwnProperty('code')) {
              message.warning(obj['code']);
            }
          }
          cur.setState({processing: false});
        }
    };
  }
  handleVerifyCodeRequest(emailElementId) {
    if(this.state.cooldown > 0) {
      message.warning("Processing, wait a moment.");
      return ;
    }
    const email = document.getElementById(emailElementId).value;
    let cur = this, timer = null;
    let httpRequest = new XMLHttpRequest();
    httpRequest.open("POST", "https://learn.shanganonline.com/api/register-verifycode/", true);
    let fd = new FormData();
    fd.append("email", email);
    fd.append('username', email);
    httpRequest.send(fd);
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          const obj = JSON.parse(httpRequest.responseText);
          if(httpRequest.status === 200) {
            message.success(obj['msg']);
          } else {
            if(obj.hasOwnProperty('msg')) {
               message.warning(obj['msg']);
            } else {
              if(obj.hasOwnProperty('email')) {
                message.warning(obj['email']);
              } else if(obj.hasOwnProperty('username')) {
                message.warning(obj['username']);
              } else {
                message.warning('Fail to send your verify code. [Error]');
              }
            }
            cur.setState({cooldown: 0});
            window.clearInterval(timer);
          }
        }
    };
    this.setState({cooldown: 60});
    timer = window.setInterval(function () {
      if(cur.state.cooldown === 0) {
        window.clearInterval(timer);
      } else if(cur.state.cooldown > 0) {
        cur.setState({cooldown: cur.state.cooldown - 1});
      }
    }, 1000);
  }
  render() {
    const modalWidth = 520;
    let visible = false;
    if(this.props.parent.state.showModal === 'register') {
      visible = true;
    }
    return(
        <Modal visible={visible} footer={null} width={modalWidth}
               onCancel={this.handleCancel}>
          <div style={{textAlign:'center', color:'black', width:472, height:352, padding:"26px 21px 26px 21px"}}>
            {/*title*/}
            <p className={'home-modal-title'}><b>Sign Up</b></p>
            <p className={'home-modal-slogan'}><b>Welcome to scode!</b></p>
            {/*Email*/}
            <div className={'home-modal-input-container'}>
              <input autoComplete={'off'} className={'home-modal-input'} placeholder={"Email"}
                     id={'register-email'} type={'text'} style={{width:430}}/>
            </div>
            {/*Password*/}
            <div className={'home-modal-input-container'}>
              <input autoComplete={'off'} className={'home-modal-input'} placeholder={"Password"}
                     id={'register-password-1'} type={'password'} style={{width:430}}/>
            </div>
            {/*Verification Code*/}
            <div className={'home-modal-input-container'} style={{float:'left'}}>
              <input autoComplete={'off'} className={'home-modal-input'} placeholder={"Verification Code"}
                     id={'register-verifycode'} type={'text'} style={{width:295}}/>
            </div>
            <div className={'home-modal-button'} style={{marginTop:16, marginLeft:15, float:'left', width:120}}
                 onClick={this.handleVerifyCodeRequest.bind(this, 'register-email')}
                 >
              <p className={'home-modal-button-text'} style={{fontSize:14, lineHeight:"20px", paddingTop:10}}>
                {this.state.cooldown > 0 ? <>{this.state.cooldown}&nbsp;&nbsp;S</>:<>Get Code</>}
              </p>
              </div>
            <div id={'register-button'} className={'home-modal-button'} style={{marginTop:76}}
                 onClick={this.handleRegister.bind(this, 'register-username', 'register-email', 'register-password-1',
                        'register-password-2', 'register-verifycode')}
            >
              <p className={'home-modal-button-text'}>
                {this.state.processing ? <><LoadingOutlined/>&nbsp;&nbsp;</>:<></>}Sign&nbsp;On
              </p>
            </div>
          </div>
        </Modal>
    );
  }
}
