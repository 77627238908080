import React from 'react';
import {message, Table, Modal, Button, Input, Radio, DatePicker, InputNumber, Row, Col, Select } from 'antd';
import axios from "axios";
import moment from 'moment';
import { EyeTwoTone, CloseOutlined, CheckOutlined} from '@ant-design/icons';
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../../config/url";
import {BooleanType} from "../../../config/constant";

const {Option, OptGroup} = Select;

class PasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {visible: false}
  }
  render() {
    const visible = this.state.visible;
    return (
      <span style={{cursor: 'pointer'}} onClick={() => {this.setState({visible: !visible})}}>
        {this.state.visible ? this.props.pwd : <EyeTwoTone/>}
        </span>
    )
  }
}

class TeacherQuizzesConfigComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      quizzes: null,
      createOrUpdateQuiz: null,
      problemOptions: [],
      responseTime: new Date().getTime()
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.teacher.quiz.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({quizzes: response.data.result, responseTime: response.data.responseTime})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
    axios.get(
      getBackendUrl(backendPath.problem.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        const quizProblemOptions = [], generalProblemOptions = [];
        response.data.result.sort(function (p1, p2) {
          return (p1.shortTitle < p2.shortTitle) ? -1: 1;
        });
        response.data.result.forEach(function (problem) {
          if(problem.isQuizProblem === BooleanType.true) {
            quizProblemOptions.push(<Option key={problem.id} title={problem.title}>{problem.title}</Option>);
          } else {
            generalProblemOptions.push(<Option key={problem.id} title={problem.title}>{problem.title}</Option>)
          }
        });
        current.setState({problemOptions: [
            <OptGroup label="Exam Problems">{quizProblemOptions}</OptGroup>,
            <OptGroup label="General Problems">{generalProblemOptions}</OptGroup>
        ]})
      } else {
        message.warning(response.data.message)
      }
    })
  }

  handleSave() {
    const quiz = this.state.createOrUpdateQuiz, current = this;
    axios.post(
      getBackendUrl(backendPath.teacher.quiz.createOrUpdate.path),
      {
        id: quiz.id, title: quiz.title, isDeleted: quiz.isDeleted,
        isRunResultShown: quiz.isRunResultShown, isRunStandardOutputShown: quiz.isRunStandardOutputShown,
        isSubmitResultShown: quiz.isSubmitResultShown, isSubmitErrorCaseShown: quiz.isSubmitErrorCaseShown,
        isSubmitStandardOutputShown: quiz.isSubmitStandardOutputShown,
        startTime: quiz.startTime, duration: quiz.duration, problems: quiz.problems,
        isStartNow: quiz.isStartNow, isEndNow: quiz.isEndNow,
        canRunCode: quiz.canRunCode, canSubmitMultiTimes: quiz.canSubmitMultiTimes
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({createOrUpdateQuiz: null});
        message.success("new exam successfully...");
        window.setTimeout(function () {
          window.location.reload()
        }, 2000);
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  handleChange(key, value) {
    let createOrUpdateQuiz = this.state.createOrUpdateQuiz;
    if(!createOrUpdateQuiz) return;
    createOrUpdateQuiz[key] = value;
    this.setState({createOrUpdateQuiz: createOrUpdateQuiz})
  }

  render() {
    const quizzes = this.state.quizzes, data = [], createOrUpdateQuiz = this.state.createOrUpdateQuiz;
    const responseTime = this.state.responseTime;
    function display(value) {return <span style={{fontWeight: 500, color: value === '0' ? 'green' : 'red'}}>
      {value === "1" ? "SHOW" : "HIDDEN"}
    </span>}
    if(quizzes === null) {
      return(
        <div className={'loading'} style={{marginBottom:"10em"}}></div>
      )
    }
    for(let i = 0; i < quizzes.length; i ++) {
      let quiz = quizzes[i];
      quiz.isStartNow = false;
      quiz.isEndNow = false;
      quiz.hasStarted = responseTime >= quiz.startTime;
      quiz.hasEnded = responseTime >= quiz.startTime + quiz.duration * 60000;
      let copiedQuiz = JSON.parse(JSON.stringify(quiz));
      if(!copiedQuiz.hasStarted) {
          copiedQuiz.status = "未开始";
          copiedQuiz.op = <a onClick={() => {this.setState({createOrUpdateQuiz: JSON.parse(JSON.stringify(quiz))})}}>配置</a>;
      } else {
          copiedQuiz.status = copiedQuiz.hasEnded ? "已结束" : "进行中";
          copiedQuiz.op =
            <>
              <a onClick={() => {this.setState({createOrUpdateQuiz: JSON.parse(JSON.stringify(quiz))})}}>配置</a>&nbsp;&nbsp;&nbsp;
              <a onClick={() => {
                window.open(getFrontendUrl(frontendPath.teacher.quizScore + copiedQuiz.pwd), "_self")
              }}>评阅</a>
            </>;
      }
      copiedQuiz.password = <PasswordComponent pwd={copiedQuiz.pwd}/>;
      copiedQuiz.startTime = new Date(copiedQuiz.startTime).toLocaleString();
      copiedQuiz.isRunResultShown = display(copiedQuiz.isRunResultShown);
      copiedQuiz.isRunStandardOutputShown = display(copiedQuiz.isRunStandardOutputShown);
      copiedQuiz.isSubmitResultShown = display(copiedQuiz.isSubmitResultShown);
      copiedQuiz.isSubmitErrorCaseShown = display(copiedQuiz.isSubmitErrorCaseShown);
      copiedQuiz.isSubmitStandardOutputShown = display(copiedQuiz.isSubmitStandardOutputShown);
      copiedQuiz.isDeleted = copiedQuiz.isDeleted === "1" ? <CloseOutlined/> : <CheckOutlined/>;
      data.push(copiedQuiz);
    }
    const columns = [
      {title: 'Title', dataIndex: 'title'},
      {title: '开始时间', dataIndex: 'startTime'},
      {title: '时长(min)', dataIndex: 'duration'},
      {title: '状态', dataIndex: 'status'},
      {title: '操作', dataIndex: 'op'},
      {title: '是否开放', dataIndex: 'isDeleted'},
      {title: '六位准入口令', dataIndex: 'password'},
      // {title: 'Run结果', dataIndex: 'isRunResultShown'},
      // {title: 'Run正确输出', dataIndex: 'isRunStandardOutputShown'},
      // {title: 'Submit结果', dataIndex: 'isSubmitResultShown'},
      // {title: 'Submit错误样例', dataIndex: 'isSubmitErrorCaseShown'},
      // {title: 'Submit正确输出', dataIndex: 'isSubmitStandardOutputShown'},
    ];
    const initQuiz = {
      id: null, title: "", isRunResultShown: '0', isRunStandardOutputShown: '0', isDeleted: '0',
      isSubmitResultShown: '0', isSubmitErrorCaseShown: '0', isSubmitStandardOutputShown: '0',
      startTime: (new Date().valueOf() + 5 * 60 * 1000), duration: 90, isStartNow: false, isEndNow: false,
      hasStarted: false, hasEnded: false, canRunCode: '0', canSubmitMultiTimes: '0'
    };
    let modalTitle = "New Exam";
    if(createOrUpdateQuiz && createOrUpdateQuiz.id) {
      modalTitle = "Exam Update - " + createOrUpdateQuiz.title + " ";
      if(createOrUpdateQuiz.hasEnded) {
        modalTitle += "（已结束）";
      } else if(createOrUpdateQuiz.hasStarted) {
        modalTitle += "（进行中）";
      } else {
        modalTitle += "（尚未开始）";
      }
    }
    return (
     <>
       <Table columns={columns} dataSource={data} size={'small'} pagination={{size:"small", pageSize: 10}}/>
       <Button style={{float:'right', marginTop: 20}} onClick={() => this.setState({createOrUpdateQuiz: initQuiz})}>New Exam</Button>
       {
         !createOrUpdateQuiz ? <></> :
           <Modal title={modalTitle}
                  width={800} visible={createOrUpdateQuiz}
                  onCancel={() => {this.setState({createOrUpdateQuiz: null})}}
                  onOk={() => {this.handleSave()}}
           >
             <Row gutter={[20, 0]}>
               <Col span={12}>
                   <h5><b>Title</b></h5>
                   <Input value={createOrUpdateQuiz.title} onChange={(e) => {this.handleChange("title", e.target.value)}}/><br/><br/>
                   <h5><b>Problems</b></h5>
                   <Select mode="multiple" allowClear style={{ width: '100%' }} placeholder="Please select some problems"
                           value={createOrUpdateQuiz.problems} onChange={(v) => {this.handleChange('problems', v)}}
                           optionFilterProp={'title'} disabled={createOrUpdateQuiz.hasStarted}
                   >
                     {this.state.problemOptions}
                  </Select><br/><br/>
                   <h5><b>Start Time</b></h5>
                 {
                   !createOrUpdateQuiz.hasStarted || createOrUpdateQuiz.hasEnded ?
                     <>
                      <Radio.Group value={createOrUpdateQuiz.isStartNow} size="small" style={{marginBottom:5}}
                                   onChange={(e) => {this.handleChange("isStartNow", e.target.value)}}>
                        <Radio.Button value={false}>定时开始</Radio.Button>
                        <Radio.Button value={true}>立即开始</Radio.Button>
                      </Radio.Group><br/>
                     </>: <></>
                 }
                 {
                   !createOrUpdateQuiz.isStartNow ?
                     <DatePicker showTime value={moment(createOrUpdateQuiz.startTime)}
                                 disabled={createOrUpdateQuiz.hasStarted}
                               onChange={(e) => {this.handleChange("startTime", e.toDate().valueOf())}}/>: <></>
                 }
                   <h5><b>Duration (minutes)</b></h5>
                   <InputNumber value={createOrUpdateQuiz.duration}
                                onChange={(e) => {this.handleChange("duration", e)}}/>
                 {
                   (createOrUpdateQuiz.hasStarted && !createOrUpdateQuiz.hasEnded) ?
                     <><br/>
                      <Radio.Group value={createOrUpdateQuiz.isEndNow} size="small" style={{marginTop:5}}
                                   onChange={(e) => {this.handleChange("isEndNow", e.target.value)}}>
                        <Radio.Button value={false}>按预定时间结束</Radio.Button>
                        <Radio.Button value={true}>立即结束</Radio.Button>
                      </Radio.Group>
                     </>: <></>
                 }
                 <br/><br/><h5><b>是否可以Run Code</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("canRunCode", e.target.value)}} value={createOrUpdateQuiz.canRunCode}>
                      <Radio value={"0"}>不可以</Radio>
                      <Radio value={"1"}>可以</Radio>
                   </Radio.Group><br/><br/>
                   <h5><b>是否可以多次Submit</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("canSubmitMultiTimes", e.target.value)}} value={createOrUpdateQuiz.canSubmitMultiTimes}>
                      <Radio value={"0"}>不可以</Radio>
                      <Radio value={"1"}>可以</Radio>
                   </Radio.Group>
               </Col>
               <Col span={12}>
                   <h5><b>run code的评测结果是否展示给用户</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("isRunResultShown", e.target.value)}} value={createOrUpdateQuiz.isRunResultShown}>
                      <Radio value={"0"}>不展示</Radio>
                      <Radio value={"1"}>展示</Radio>
                   </Radio.Group><br/><br/>
                   <h5><b>run code的标准输出是否展示给用户</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("isRunStandardOutputShown", e.target.value)}} value={createOrUpdateQuiz.isRunStandardOutputShown}>
                      <Radio value={"0"}>不展示</Radio>
                      <Radio value={"1"}>展示</Radio>
                   </Radio.Group><br/><br/>
                   <h5><b>submit的评测结果是否展示给用户</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("isSubmitResultShown", e.target.value)}} value={createOrUpdateQuiz.isSubmitResultShown}>
                      <Radio value={"0"}>不展示</Radio>
                      <Radio value={"1"}>展示</Radio>
                   </Radio.Group><br/><br/>
                   <h5><b>submit的错误样例是否展示给用户</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("isSubmitErrorCaseShown", e.target.value)}} value={createOrUpdateQuiz.isSubmitErrorCaseShown}>
                      <Radio value={"0"}>不展示</Radio>
                      <Radio value={"1"}>展示</Radio>
                   </Radio.Group><br/><br/>
                   <h5><b>submit的标准输出是否展示给用户</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("isSubmitStandardOutputShown", e.target.value)}} value={createOrUpdateQuiz.isSubmitStandardOutputShown}>
                      <Radio value={"0"}>不展示</Radio>
                      <Radio value={"1"}>展示</Radio>
                   </Radio.Group><br/><br/>
                   <h5><b>考试是否隐藏</b></h5>
                   <Radio.Group onChange={(e) => {this.handleChange("isDeleted", e.target.value)}} value={createOrUpdateQuiz.isDeleted}>
                      <Radio value={"0"}>不隐藏</Radio>
                      <Radio value={"1"}>隐藏</Radio>
                   </Radio.Group>
               </Col>
             </Row>

           </Modal>
       }
     </>
    )
  }
}

export default TeacherQuizzesConfigComponent;
