import React from 'react';

import {Input, Button, message} from 'antd'
import axios from "axios";
import { LoadingOutlined } from '@ant-design/icons';
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../config/url";
import {clearCookie, setCookie} from "../../config/cookie";
import {getQueryVariable} from "../../config/url";
import JSEncrypt from 'jsencrypt';


function encrypt(txt)
{
  const PUBLIC_KEY = "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2zUKMVg975Y1y/ADQm0Q8/P8OejCvIzyFQtvQoIZlFgXM9Kd2h0RI/HattyOBy1dbuesGOqHr7axmyaElozaNGHMh977RGs1jJRUueAeY9Hi/1FydW8vcaj4UoLfpzkf3NwL58V1x9yfysr2lhDWqn1LVZKjFLw/aBaQft4l5Atcp1jXdgxnsChD9CWz5VqD7xKKkNNAZA60gQrbOajNBpGtw+11lvruE2x06Omw6j/SygOWmjRaaBqk35tIvb3RH3LQ9LcDJ+Sm8USsmNeKmJ3+QZgXpAtK5tU6d85+EYFBo5WlOu6VhksljaBRhYJh9t7DdeRyl6yZJJd1ehcbkwIDAQAB\n-----END PUBLIC KEY-----";
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  return encodeURI(encrypt.encrypt(txt));
}

class ForgetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cooldown: 0,
      processing: false,
    };
    clearCookie();
  }
  handleRegister(emailElementId, passwordElementId, verifycodeElementId) {
    if(this.state.processing) {
      message.warning("Processing, wait a moment.");
      return ;
    }
    const email = document.getElementById(emailElementId).value;
    const password1 = document.getElementById(passwordElementId).value;
    const verifycode = document.getElementById(verifycodeElementId).value;

    let cur = this;
    this.setState({processing: true});
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(
      "POST", "https://learn.shanganonline.com/api/user/", true
    );
    let fd = new FormData();
    fd.append("email", email);
    fd.append("password", encrypt(password1));
    fd.append('code', verifycode);
    httpRequest.send(fd);
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          if(httpRequest.status === 200) {
            const obj = JSON.parse(httpRequest.responseText);
            setCookie('token', obj['token']);
            message.success("Sign on successfully.");
            setInterval(function () {
              window.open(getFrontendUrl(frontendPath.problems), "_self")
            }, 1000);
          } else {
            const obj = JSON.parse(httpRequest.responseText);
            if(obj.hasOwnProperty('email')) {
              message.warning(obj['email']);
            }else if(obj.hasOwnProperty('username')) {
              message.warning(obj['username']);
            }else if(obj.hasOwnProperty('password')) {
              message.warning(obj['password']);
            }else if(obj.hasOwnProperty('code')) {
              message.warning(obj['code']);
            }
          }
          cur.setState({processing: false});
        }
    };
  }
  handleVerifyCodeRequest(emailElementId) {
    if(this.state.cooldown > 0) {
      message.warning("Processing, wait a moment.");
      return ;
    }
    const email = document.getElementById(emailElementId).value;
    let cur = this, timer = null;
    let httpRequest = new XMLHttpRequest();
    httpRequest.open("POST", "https://learn.shanganonline.com/api/register-verifycode/", true);
    let fd = new FormData();
    fd.append("email", email);
    fd.append('username', email);
    httpRequest.send(fd);
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          const obj = JSON.parse(httpRequest.responseText);
          if(httpRequest.status === 200) {
            message.success(obj['msg']);
          } else {
            if(obj.hasOwnProperty('msg')) {
               message.warning(obj['msg']);
            } else {
              if(obj.hasOwnProperty('email')) {
                message.warning(obj['email']);
              } else if(obj.hasOwnProperty('username')) {
                message.warning(obj['username']);
              } else {
                message.warning('Fail to send your verify code. [Error]');
              }
            }
            cur.setState({cooldown: 0});
            window.clearInterval(timer);
          }
        }
    };
    this.setState({cooldown: 60});
    timer = window.setInterval(function () {
      if(cur.state.cooldown === 0) {
        window.clearInterval(timer);
      } else if(cur.state.cooldown > 0) {
        cur.setState({cooldown: cur.state.cooldown - 1});
      }
    }, 1000);
  }

  render() {
    const width = this.props.width, height = this.props.height;
    return(
      <div style={{
        width:width, height:height, borderRadius:8, padding:"60px 26px 0px 26px",
        border:'1px solid #eaeaea', boxShadow:'0 0 2px #cac6c6', background:'white',
        textAlign:'center'
      }}>
        <p style={{margin:"0px 0px 33px", color:'#5168E7', fontSize:26, fontFamily:'ArialRoundedMTBold',
          fontWeight:600}}>SCode</p>
        <Input placeholder="E-mail" id={"register-email"}  style={{marginBottom:20, height:40}}/>
        <Input.Password placeholder="Reset Password" style={{marginBottom:20, height:40}}
              iconRender={visible => (visible ? <span/> : <span/>)} id={"register-password"}
        />
        <Input placeholder="Verify Code" id={"register-verify-code"}
               style={{marginBottom:20, height:40, width: 220, marginRight:6}}/>
        <Button type="primary" style={{width:120, background:'#5168E7', borderColor:'#5168E7', color:'white',
                  borderRadius:4, fontFamily:'PingFangSC-Medium', height: 40}}
                onClick={() => this.handleVerifyCodeRequest('register-email')}
        >
          {this.state.cooldown > 0 ? <span>{this.state.cooldown}&nbsp;S</span> : <span>Get Code</span>}
        </Button>
        <Button type="primary"
                style={{width:"100%", background:'#5168E7', borderColor:'#5168E7', color:'white',
                  borderRadius:4, fontFamily:'PingFangSC-Medium', height: 40}}
                onClick={() => this.handleRegister(
                 "register-email",
                  "register-password",
                  "register-verify-code") }>
          {!this.state.processing ? <span>Reset Password</span> : <LoadingOutlined />}
        </Button>
        <div style={{fontWeight:500, fontFamily:'PingFangSC-Medium', marginTop:20}}>
          <a style={{color:"#7A7A7A"}} href={getFrontendUrl(frontendPath.login)} target={"_self"}>
            Sign In
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a style={{color:"#7A7A7A"}} href={getFrontendUrl(frontendPath.register)} target={"_self"}>
            Sign Up
          </a>
        </div>
        <div style={{marginTop:70, fontSize:13, fontFamily:'PingFangSC-Medium', color:'#B6B6B6', lineHeight:'18px'}}>
          This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
        </div>
      </div>
    )
  }
}
export default ForgetPasswordComponent;

