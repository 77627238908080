import React from 'react';
import { Menu } from 'antd';
import {problemsComponent} from "../components/user/problems/problems";
const {SubMenu} = Menu;

export function buildLadder(ladderInfo) {
  let defaultSelectedKeys = [], defaultOpenKeys = [], showProblemIds = [];
  function helper(info, d, firstShow) {
    const key = Math.random().toString();
    try {
      if(info.hasOwnProperty("problems")) {
        if(firstShow) {
          defaultSelectedKeys.push(key);
          showProblemIds = info['problems'];
        }
        return <Menu.Item key={key}
                          onClick={() => problemsComponent.setState({showProblemIds: info['problems']})}
        >
          {info.title}
        </Menu.Item>;
      }
      if(d !== 0 && firstShow) defaultOpenKeys.push(key);
      const subMenus = [];
      for(let i = 0; i < info.children.length; i ++) {
        subMenus.push(helper(info.children[i], d + 1, firstShow && i === 0));
      }
      if(d === 0) {
        return <Menu mode={'inline'} key={key} title={info.title}
                     style={{width: '100%', height: "100%"}}
                     defaultSelectedKeys={defaultSelectedKeys}
                     defaultOpenKeys={defaultOpenKeys}
        >
          {subMenus}
        </Menu>;
      } else {
        return <SubMenu key={key} title={info.title}>{subMenus}</SubMenu>;
      }
    } catch (e) {
      return <div style={{width: '100%', height: "100%", background: 'white'}}></div>;
    }
  }
  const result = helper(ladderInfo, 0, true);
  let timer = null;
  function set() {
    if(showProblemIds !== null) problemsComponent.setState({showProblemIds: showProblemIds});
    window.clearInterval(timer);
  }
  timer = window.setInterval(set, 100);
  return result;
}

export const ladderInfoStringPattern = "{\n" +
  "    \"title\": \"Algorithm Ladder\",\n" +
  "    \"isDeleted\": \"0\",\n" +
  "    \"problemsCount\": 0,\n" +
  "    \"coverUrl\": \"https://shangan-static.oss-cn-beijing.aliyuncs.com/company-logo/google.png\",\n" +
  "    \"children\": [\n" +
  "        {\n" +
  "            \"title\": \"Class 1 Binary Search\",\n" +
  "            \"children\": [\n" +
  "                {\n" +
  "                    \"title\": \"精选题\",\n" +
  "                    \"problems\": [1, 4, 10, 5, 14]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"title\": \"课后作业\",\n" +
  "                    \"problems\": [6, 7, 8]\n" +
  "                }\n" +
  "            ]\n" +
  "        },\n" +
  "        {\n" +
  "            \"title\": \"Class 2 Two Pointers\",\n" +
  "            \"children\": [\n" +
  "                {\n" +
  "                    \"title\": \"精选题\",\n" +
  "                    \"problems\": [1, 4, 5]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"title\": \"课后作业\",\n" +
  "                    \"problems\": [6, 7, 8]\n" +
  "                }\n" +
  "            ]\n" +
  "        }\n" +
  "    ]\n" +
  "}";

