import React from 'react';

import message from 'antd/lib/message'
import 'antd/lib/message/style'
import 'antd/lib/icon/style'
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import { LoadingOutlined } from "@ant-design/icons"
import {setCookie} from "../../config/cookie";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl, getQueryVariable} from "../../config/url";
import axios from "axios";


export class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleRetrievePwd = this.handleRetrievePwd.bind(this);
  }
  handleCancel() {
    this.props.parent.setState({showModal: null});
  }
  handleRetrievePwd() {
    this.props.parent.setState({showModal: null});
  }
  handleLogin(emailElementId, passwordElementId) {
    if(this.state.processing) {
      message.warning("Processing, wait a moment.");
      return ;
    }

    const email = document.getElementById(emailElementId).value;
    const password = document.getElementById(passwordElementId).value;

    this.setState({processing: true});
    const current = this;
    axios.post(
      getBackendUrl(backendPath.user.login.path),
      {
          "email": email,
          "password": password
        }
      ).then(function (response) {
        if(response.data.status === 1) {
          const obj = JSON.parse(response.data.result);
          setCookie('token', obj['token']);
          current.props.parent.setState({showModal: null});
          window.open(getQueryVariable("next") || getFrontendUrl(frontendPath.problems), "_self");
        } else {
         message.warning("Invalid account or password.");
        }
        current.setState({processing: false});
      }).catch(function (error) {
        console.log(error);
        current.setState({processing: false});
      });
    // let httpRequest = new XMLHttpRequest();
    // httpRequest.open("POST", getBackendUrl(backendPath.user.login), true);
    // // let fd = new FormData();
    // // fd.append("username", email);
    // // fd.append("password", password);
    // // httpRequest.send(fd);
    // let cur = this;
    // httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // httpRequest.send(JSON.stringify(
    //   {
    //     "username": email,
    //     "password": password
    //   }
    // ));
    // httpRequest.onreadystatechange = function () {
    //     if (httpRequest.readyState === 4) {
    //       if (httpRequest.status === 200) {
    //         const obj = JSON.parse(httpRequest.responseText);
    //         console.log(obj)
    //         setCookie('token', obj['token']);
    //         cur.props.parent.setState({showModal: null});
    //         window.open(getFrontendUrl(frontendPath.problems), "_self");
    //       } else if (httpRequest.status === 400) {
    //         message.warning("Invalid account or password.");
    //       } else {
    //         message.warning("Unknown Error.");
    //       }
    //       cur.setState({processing: false});
    //     }
    // };
  }

  render() {
    const modalWidth = 520;

    let visible = false, parentComponent = this.props.parent;
    if(parentComponent.state.showModal === 'login') {
      visible = true;
    }

    return(
      <Modal visible={visible} footer={null} width={modalWidth}
               onCancel={this.handleCancel}>
          <div style={{textAlign:'center', color:'black', width:472, maxWidth:'100%', height:312, padding:"26px 21px 26px 21px"}}>
            {/*title*/}
            <p className={'home-modal-title'}><b>Sign In</b></p>
            <p className={'home-modal-slogan'}><b>Welcome to SCode!</b></p>
            {/*Email*/}
            <div className={'home-modal-input-container'}>
              <input autoComplete={'off'} className={'home-modal-input'} placeholder={"Email"}
                     id={'login-email'} type={'text'} style={{width:430, maxWidth:'100%'}}/>
            </div>
            {/*Password*/}
            <div className={'home-modal-input-container'}>
              <input autoComplete={'off'} className={'home-modal-input'} placeholder={"Password"}
                     id={'login-password'} type={'password'} style={{width:430, maxWidth:'100%'}}/>
            </div>
            <div id={'login-button'} className={'home-modal-button'} style={{marginTop:30}} onClick={this.handleLogin.bind(this, 'login-email', 'login-password')}>
              <p className={'home-modal-button-text'}>
                {this.state.processing ? <><LoadingOutlined/>&nbsp;&nbsp;</>:<></>}Sign&nbsp;In
              </p>
            </div>
            <div style={{float:'right', marginTop:-20}}>
              <a onClick={() => {parentComponent.setState({showModal: "register"})}}>Go to Sign Up</a>
            </div>
          </div>
        </Modal>
    );
  }
}
