import {settings, env} from "../config";

export const backendUrlBase = settings[env].backendUrlBase;
export const frontendUrlBase = settings[env].frontendUrlBase;

export const frontendPath = {
  login: "/login",
  register: "/register",
  forgetPassword: "/forget-password",
  home: "/home",
  ban: "/ban",
  problems: "/problems",
  submissions: "/submissions",
  problem: "/problem/",
  ladders: "/ladders",
  quizzes: "/exams",
  quiz: "/exam/",
  quizProblem: "/exam-problem/",
  ladder: "/ladder/",
  admin: {
    problems: '/admin/problems',
    problem: '/admin/problem/',
    users: '/admin/users',
    ladder: '/admin/ladder/',
    ladders: '/admin/ladders',
    permission: '/admin/permission'
  },
  teacher: {
    check: '/teacher/check',
    students: '/teacher/students',
    quizzesConfig: '/teacher/exams-config',
    quizScore: '/teacher/exam-score/',
    quizCheck: '/teacher/exam-check/'
  },
  account: '/account'
};

export const backendPath = {
  user: {
    info: {path: "/user/retrieve-user-info", method: 'GET'},
    register: {path: "/user/register", method: 'POST'},
    retrieveVerifyCode: {path: "/user/retrieve-verify-code/", method: 'GET'},
    login: {path: "/user/login", method: 'POST'}
  },
  problem: {
    list: {path: "/problem/list", method: "GET"},
    retrieve: {path: "/problem/retrieve/", method: "GET"},
    retrieveTestCaseInput: {path: "/problem/retrieve-test-case-input/", method: "GET"},
    collect: {path: '/problem/collect/', method: "GET"}
  },
  language: {
    ideLanguages: {path: "/language/list/", method: "GET"},
    retrieveSolution: {path: "/language/retrieve-solution/", method: "GET"}
  },
  submission: {
    submit: {path: "/submission/submit", method: "POST"},
    runCode: {path: "/submission/run-code", method: "POST"},
    retrieveResult: {path: "/submission/retrieve-result/", method: "GET"},
    retrieveCode: {path: "/submission/retrieve-code/", method: "GET"},
    listOneProblem: {path: "/submission/list-one-problem/", method: "GET"},
    list: {path: "/submission/list", method: "GET"}
  },
  quiz: {
    list: {path: "/quiz/list", method: "GET"},
    participate: {path: "/quiz/participate", method: 'POST'},
    retrieve: {path: "/quiz/retrieve/", method: 'GET'},
    retrieveProblem: {path: '/quiz/retrieve-problem/', method: "GET"},
    submission: {
      submit: {path: "/quiz/submission/submit", method: "POST"},
      runCode: {path: "/quiz/submission/run-code", method: "POST"},
      retrieveResult: {path: "/quiz/submission/retrieve-result/", method: "GET"},
      retrieve: {path: "/quiz/submission/retrieve/", method: "GET"},
      listQuizSubmissions: {path: "/quiz/submission/list-quiz-submissions/", method: "GET"},
      listQuizProblemSubmissions: {path: "/quiz/submission/list-quiz-problem-submissions/", method: "GET"},
    }
  },
  tag: {
    list: {path: "/tag/list", method: "GET"}
  },
  company: {
    retrieve: {path: "/company/retrieve/", method: "GET"},
    list: {path: "/company/list", method: "GET"}
  },
  ladder: {
    retrieve: {path: "/ladder/retrieve/", method: "GET"},
    list: {path: "/ladder/list", method: "GET"}
  },
  admin: {
    permission: {
      list: {path: "/admin/permission/list", method: "GET"},
      create: {path: "/admin/permission/create", method: "POST"}
    },
    resourcePermission: {
      retrieve: {path: "/admin/resource-permissions/retrieve/", method: "GET"},
      createOrUpdate: {path: "/admin/resource-permissions/create-or-update", method: "POST"}
    },
    ladder: {
      list: {path: "/admin/ladder/list", method: "GET"},
      retrieve: {path: "/admin/ladder/retrieve/", method: "GET"},
      createOrUpdate: {path: "/admin/ladder/create-or-update", method: "POST"}
    },
    problem: {
      list: {path: "/admin/problem/list", method: "GET"},
      retrieve: {path: "/admin/problem/retrieve/", method: "GET"},
      create: {path: "/admin/problem/create", method: "POST"},
      update: {path: "/admin/problem/update", method: "POST"}
    },
    tag: {
      list: {path: "/admin/tag/list", method: "GET"},
      tagProblem: {path: "/admin/tag/tag-problem", method: "POST"}
    },
    language: {
      list: {path: "/admin/language/list/", method: "GET"},
      createOrUpdate: {path: "/admin/language/create", method: "GET"}
    },
    testCase: {
      list: {path: "/admin/test-case/list/", method: "GET"},
      create: {path: "/admin/test-case/create", method: "POST"},
      update: {path: "/admin/test-case/update/", method: "POST"},
      retrieve: {path: "/admin/test-case/retrieve/", method: "GET"},
    },
    user: {
      list: {path: "/admin/user/list", method: "GET"},
      add: {path: "/admin/user/add", method: "POST"},
      update: {path: "/admin/user/update", method: "POST"},
      retrievePermission: {path: "/admin/user/retrieve-permissions/", method: "GET"},
      addPermission: {path: "/admin/user/add-permission", method: "POST"},
      updatePermission: {path: "/admin/user/update-permission", method: "POST"}
    }
  },
  teacher: {
    follow: {
      follow: {path: "/teacher/follow-user/follow", method: "POST"},
      listAllUsers: {path: "/teacher/follow-user/list-all-users", method: "GET"},
      listFollowers: {path: "/teacher/follow-user/list-followers", method: "GET"},
      checkSubmissions: {path: "/teacher/follow-user/list-submissions/", method: 'GET'},
      retrieveSubmissionCode: {path: "/teacher/follow-user/retrieve-submission-code/", method: 'GET'},
      checkQuizSubmissions: {path: "/teacher/follow-user/list-quiz-submissions/", method: 'GET'},
      retrieveQuizSubmissionCode: {path: "/teacher/follow-user/retrieve-quiz-submission-code/", method: 'GET'},
    },
    quiz: {
      list: {path: '/teacher/quiz/list', method: 'GET'},
      score: {path: '/teacher/quiz/score-quiz-submission', method: 'POST'},
      createOrUpdate: {path : '/teacher/quiz/create-or-update', method: 'POST'},
      listParticipantSubmissionsForScore: {path: '/teacher/quiz/list-participant-submissions-for-score/', method: 'GET'},
      listParticipantSubmissionsForCheck: {path: '/teacher/quiz/list-participant-submissions-for-check/', method: 'GET'},
      retrieveSubmission: {path: '/teacher/quiz/retrieve-quiz-submission/', method: 'GET'},
      listParticipants: {path: '/teacher/quiz/list-participants/', method: 'GET'},
      listProblems: {path: '/teacher/quiz/list-problems/', method: 'GET'}
    }
  }
};

export function getBackendUrl(path) {
  return backendUrlBase + path;
}

export function getFrontendUrl(path) {
  return frontendUrlBase + path;
}

export function getQueryVariable(variable)
{
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (let i=0;i<vars.length;i++) {
               let pair = vars[i].split("=");
               if(pair[0] === variable){return decodeURI(pair[1]);}
       }
       return null;
}
