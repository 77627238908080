import React from 'react';
import HeaderComponent from "../../components/header/header";
import TeacherCheckComponent from "../../components/teacher/check/teacherCheck";
import {headerHeight} from "../../config";


class TeacherCheckPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    document.title = "Teacher Check - SCode";
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30, height:document.body.offsetHeight - headerHeight}}>
          <TeacherCheckComponent/>
        </div>
      </div>
    )
  }
}
export default TeacherCheckPage;
