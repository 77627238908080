import React from 'react';
import {message, Table, Tag, Modal, Input} from 'antd';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {judgeResultContext} from "../../../config/judger";
import {languagesConfig} from "../../../config/language";
import AceEditor from "react-ace";

const {TextArea} = Input;


class QuizSubmissionComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submissionDetail: null
    }
  }

  handleLoadCode(submission) {
    const submissionId = submission.id, current = this;
    if(this.state.submissionDetail !== null) {
      message.warn("loading ...");
      return ;
    }
    current.setState({submissionDetail: {}});
    axios.get(
      getBackendUrl(backendPath.quiz.submission.retrieve.path + submissionId)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({submissionDetail: {
            code: response.data.result.code,
            annotate: response.data.result.annotate,
            lines: response.data.result.code.split('\n').length,
            problemTitle: submission.problemTitle,
            language: submission.language
        }});
      } else {
        current.setState({submissionDetail: null});
        message.warning(response.data.message)
      }
    })
  }

  render() {
    let submissions = this.props.submissions, submissionTableData = [], submissionsTableColumns = [];
    if(!this.props.hiddenTitle) {
      submissionsTableColumns.push({title: 'Problem', dataIndex: 'problemTitle', width:'30%'})
    }
    submissionsTableColumns = submissionsTableColumns.concat([
      {title: "Language", dataIndex: 'language', width:'15%'},
      {title: 'Result [点击查看批注]', dataIndex: 'result', width:'20%'},
      {title: 'Score', dataIndex: 'score', width:'15%'},
      {title: 'Time', dataIndex: 'createdTime', width:'20%'},
    ]);
    for (let i = 0; i < submissions.length; i ++) {
      let submission = submissions[i];
      // if(submission.result === -2) continue ;
      const date = new Date(submission.createdTime);
      let time = date.toLocaleTimeString() + " ";
      if(date.toLocaleDateString() !== new Date().toLocaleDateString()) {
        time += date.toLocaleDateString();
      }
      const resultContext = judgeResultContext(submission.result);
      submissionTableData.push({
        createdTime: time,
        result:
        <>
          <h3 style={{color:resultContext.color, margin:0, cursor:'pointer', display:'inline'}}
              onClick={() => this.handleLoadCode(submission)}>
            {resultContext.text}
          </h3>
        </>,
        score: (submission.score >= 0) ? submission.score: "-",
        language: languagesConfig[submission.language].label,
        problemTitle:
          <>
            <span style={{fontWeight:500}}>{submission.problemTitle}&nbsp;&nbsp;</span>
            {submission.isLastSubmission ? <Tag color="cyan">The Last Submission</Tag> : <></>}
          </>
      })
    }
    const submissionDetail = this.state.submissionDetail;
    let AceEditorHeight = 300;
    if(submissionDetail && submissionDetail.lines) {
      AceEditorHeight = Math.min(400, Math.max(200, submissionDetail.lines * 16));
    }
    return (
     <>
       <Table columns={submissionsTableColumns} dataSource={submissionTableData}
              size={'small'} pagination={{size: 'small', pageSize: 100, hideOnSinglePage: true}}/>
       {
         submissionDetail === null ? <></>:
           <Modal title={"Submission Code - " + (!submissionDetail ? "" : submissionDetail.problemTitle)}
                width={900} visible={submissionDetail !== null} footer={false}
                onCancel={() => this.setState({submissionDetail: null})}
          >
           {
             submissionDetail.hasOwnProperty('code') ?
               <>
                <AceEditor
                  mode={
                    languagesConfig[submissionDetail.language].value === 'python3' ?
                    'python' : languagesConfig[submissionDetail.language].value
                  }
                  theme={"solarized_light"} width={"100%"} height={AceEditorHeight.toString() + "px"} fontSize={14}
                  showPrintMargin={false} enableBasicAutocompletion={true}
                  value={submissionDetail.code} name="quiz-submission-code" editorProps={{ $blockScrolling: true }}
                /><br/>
                 <TextArea rows={4} value={submissionDetail.annotate}/><br/><br/>
               </> : <div className={'loading'} style={{marginBottom:"10em"}}></div>
           }
          </Modal>
       }
     </>
    )
  }
}

export default QuizSubmissionComponent;
