import React from 'react';
import {Progress, Skeleton, Row, Col, message} from 'antd';
import {problemsComponent} from "./problems";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";


class AchievementStatisticComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      problems: null
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.problem.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({problems: response.data.result, loading: false})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const problems = this.state.problems;
    if(!problems) {
      return <div style={{marginLeft:20}}><Skeleton active paragraph={{ rows: 6 }} /></div>;
    }
    let solvedCount = [0, 0, 0], solvedTotalCount = 0, difficultyCount = [0, 0, 0];
    problems.forEach(function (problem) {
      difficultyCount[problem.difficulty] += 1;
      if(problem.finished === 1) {
        solvedCount[problem.difficulty] += 1;
        solvedTotalCount += 1
      }
    });
    return (
      <div style={{padding:20, marginLeft:20, background:'#F8FAFD', borderRadius:5}}>
            <Row>
              <Col span={10}>
                <Progress type="circle" width={100}
                      percent={Math.ceil(solvedTotalCount * 100 / problems.length)}
                      format={() => <>
                        <span style={{fontSize:18, color:'#c4c4c6'}}>Solved</span><br/>
                        <p style={{fontSize:24, fontWeight:600, margin:'3px 0 6px 0'}}>{solvedTotalCount}</p>
                        <span style={{fontSize:18, color:'#c4c4c6', borderTop: '1px solid'}}>{problems.length}</span>
                      </>} />
              </Col>
              <Col span={14} style={{paddingLeft:10, fontSize:16}}>
                <div style={{margin:'6px 0'}}>
                  <span style={{color:'#4593f9', fontWeight:600}}>Easy</span>
                  <span style={{float:'right', fontWeight:500}}>
                    <span style={{color:'#262626bf'}}>{solvedCount[0]}</span>
                    <span style={{color:'#3c3c434d'}}>/{difficultyCount[0]}</span>
                  </span>
                </div>
                <div style={{margin:'6px 0'}}>
                  <span style={{color:'#e19350', fontWeight:600}}>Medium</span>
                  <span style={{float:'right', fontWeight:500}}>
                    <span style={{color:'#262626bf'}}>{solvedCount[1]}</span>
                    <span style={{color:'#3c3c434d'}}>/{difficultyCount[1]}</span>
                  </span>
                </div>
                <div style={{margin:'6px 0'}}>
                  <span style={{color:'#df4f81', fontWeight:600}}>Hard</span>
                  <span style={{float:'right', fontWeight:500}}>
                    <span style={{color:'#262626bf'}}>{solvedCount[2]}</span>
                    <span style={{color:'#3c3c434d'}}>/{difficultyCount[2]}</span>
                  </span>
                </div>
              </Col>
            </Row>
      </div>

    )
  }
}

export default AchievementStatisticComponent;

