import React from 'react';
import HeaderComponent from "../../components/header/header";
import AdminPermissionCreateComponent from "../../components/admin/permission/adminPermissionCreate";
import AdminPermissionListComponent from "../../components/admin/permission/adminPermissionList";


class AdminPermissionPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    document.title = "Permission - SCode Admin";
    return(
      <div style={{width:'100%', background:'#eee'}}>
        <HeaderComponent/>
        <div style={{padding:30}}>
          <AdminPermissionCreateComponent/>
        </div>
        <div style={{padding:30, minHeight:900}}>
          <AdminPermissionListComponent/>
        </div>
      </div>
    )
  }
}
export default AdminPermissionPage;
