import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl} from "../../../config/url";
import {message, Button, Select} from "antd";
const { Option } = Select;



class AdminResourcePermissionComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      permissionList: [],
      resourcePermissionList: []
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.admin.permission.list.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({permissionList: response.data.result});
      }
    });
    axios.get(
      getBackendUrl(backendPath.admin.resourcePermission.retrieve.path + current.props.id)
    ).then(function (response) {
      const res = response.data.result, permissions = [];
      if(res) {
        for(let i = 0; i < res.permissions.length; i ++) {
          if(res.permissions[i] === '1') permissions.push(i);
        }
        current.setState({resourcePermissionList: permissions})
      }
    })
  }

  handleSave() {
    const resourcePermissionList = this.state.resourcePermissionList;
    const bits = [];
    for(let i = 0; i <= Math.max.apply(Math, resourcePermissionList); i ++) {bits.push("0");}
    resourcePermissionList.forEach(i => bits[i] = '1');
    axios.post(
      getBackendUrl(backendPath.admin.resourcePermission.createOrUpdate.path),
      {
        'resourceId': this.props.id,
        'permissions': bits.join("")
      }
    ).then(function (response) {
      if(response.data.status === 1) {
        message.success("Permission saved successfully.");
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    })
  }

  render() {
    const permissionOptions = [], permissionList = this.state.permissionList;
    for(let i = 0; i < permissionList.length; i ++) {
      permissionOptions.push(
        <Option key={permissionList[i].id} value={permissionList[i].id}>
          {permissionList[i].title} -- {permissionList[i].detail}
        </Option>
      )
    }
    return(
      <div style={{width: '100%', padding:"15px 15px 25px 15px", background:'#fff', borderRadius:5, height:160}}>
        <h3 style={{marginBottom:15}}>Resource Permission</h3>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={this.state.resourcePermissionList}
          onChange={(values) => this.setState({resourcePermissionList: values})}
        >
          {permissionOptions}
        </Select>
        <Button style={{float:'right', marginTop:20}} onClick={this.handleSave.bind(this)}>Save</Button>
      </div>
    )
  }
}
export default AdminResourcePermissionComponent;
