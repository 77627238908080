import React from 'react';
import {message, Skeleton} from 'antd';
import axios from "axios";
import { Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {backendPath, getBackendUrl} from "../../../config/url";
import "../../css/teacherCheck.css";
import SubmissionsComponent from "../../user/problem/submissions";


const { SubMenu } = Menu;


class TeacherCheckComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      followers: [], submissions: [], code: null
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.teacher.follow.listFollowers.path)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({followers: response.data.result})
      } else {
        message.warning(response.data.message)
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  handleClick(e) {
    const id = e.key, current = this;
    current.setState({submissions: null});
    axios
      .get(getBackendUrl(backendPath.teacher.follow.checkSubmissions.path + id))
      .then(function (response) {
        if(response.data.status === 1) {
          current.setState({submissions: response.data.result});
        } else {
          current.setState({submissions: []});
          message.warning(response.data.message)
        }
      }).catch(function (error) {
        console.log(error);
      })
  }

  handleLoadCode(submissionId) {
    const current = this;
    if(current.state.code !== null) {
      message.warn("loading ...");
      return ;
    }
    current.setState({code: ""});
    axios.get(
      getBackendUrl(backendPath.teacher.follow.retrieveSubmissionCode.path + submissionId)
    ).then(function (response) {
      if(response.data.status === 1) {
        current.setState({code: response.data.result});
      } else {
        current.setState({code: null});
        message.warning(response.data.message)
      }
    })
  }

  render() {
    const followers = [], submissions = this.state.submissions;
    for(let i = 0; i < this.state.followers.length; i ++) {
      const follower = this.state.followers[i];
      followers.push(
        <Menu.Item key={follower.id}>
          <p style={{textAlign:'left', paddingLeft:20, margin:0}}>
            {follower.nickName + " " + follower.email}
          </p>
        </Menu.Item>
      )
    }
    return (
     <div style={{height:"100%", position:'relative'}}>
       <Menu mode="inline" openKeys={["followers"]} onClick={(e) => this.handleClick(e)} style={{ width: "25%", height: "100%" }}>
          <SubMenu key="followers" icon={<UserOutlined />} title="My Followers">
            {followers}
          </SubMenu>
        </Menu>
       <div className={'submission-container'}>
         {
           submissions === null ? <Skeleton active paragraph={{ rows: 6 }} />:
             <SubmissionsComponent submissions={submissions}/>
         }
       </div>
     </div>
    )
  }
}

export default TeacherCheckComponent;

